import {
  DefaultPortModel,
  DefaultPortModelOptions,
} from '@projectstorm/react-diagrams'

export interface OrderedPortModelOptions extends DefaultPortModelOptions {
  order: number
}

export class OrderedPortModel extends DefaultPortModel {
  order: number

  constructor({ order, ...options }: OrderedPortModelOptions) {
    super(options)

    this.order = order
  }
}
