import React from 'react'

import { Variant, VariantIcons } from '../Icons/VariantIcons'
import {
  InfoMessageText,
  InfoMessageWrapper,
} from './InfoMessageContainer.styles'

export interface InfoMessageContainerProps {
  message: React.ReactNode
  variant: Variant
  style?: React.CSSProperties
}

export const InfoMessageContainer: React.FC<InfoMessageContainerProps> = ({
  message,
  variant,
  style,
}) => {
  const Icon = VariantIcons.getIconFilled(variant)

  return (
    <InfoMessageWrapper style={style}>
      <Icon />
      <InfoMessageText>{message}</InfoMessageText>
    </InfoMessageWrapper>
  )
}
