import { Breadcrumb, RouteBreadcrumbs } from '@dspace-internal/ui-kit'
import { useProjectInfo } from '@simphera/shared/state'

export const Breadcrumbs = () => {
  const projectInfo = useProjectInfo()
  const prefixBreadcrumbs = [<Breadcrumb name="Home" to="/projects" />]

  if (projectInfo?.gitWorkspace) {
    // inject breadcrumb for workspace
    prefixBreadcrumbs.push(
      <Breadcrumb
        name={projectInfo.gitWorkspace.name}
        to={`/gitWorkspaces/${projectInfo.gitWorkspace.id}`}
      />
    )
  }

  if (projectInfo) {
    // inject breadcrumb for project
    prefixBreadcrumbs.push(
      <Breadcrumb name={projectInfo.name} to={`/projects/${projectInfo.id}`} />
    )
  }

  return (
    <RouteBreadcrumbs prefix={prefixBreadcrumbs} preventLinkOnLastBreadcrumb />
  )
}
