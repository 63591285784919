import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { createUploadLink } from 'apollo-upload-client'
import { getHeaders, handleError } from '../apolloClientUtils'

const link = createUploadLink({
  uri: GlobalConfig.appConfig.API_URL,
})

const authLink = setContext(getHeaders)

const errorLink = onError(handleError)

export const apolloAuthenticationClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authLink.concat(link)]),
})
