import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  SidebarItemInterpolate,
  SystemUnderTestIcon,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import createBreadcrumbsFor from './createBreadcrumbsFor'
import { PATHS } from './paths'

const SUTOverview = React.lazy(() => import('./components/SUT/SUTListPage'))

const SUTDetailsPage = React.lazy(
  () => import('./components/SUT/SUTDetailsPage/SUTDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SYSTEMUNDERTEST_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="SUTs"
      icon={<SystemUnderTestIcon />}
      to={PATHS.LIST_PAGE}
    />,
  ],
  routes: [
    {
      path: PATHS.LIST_PAGE,
      element: wrapComponentInProviders(SUTOverview),
      breadcrumbs: createBreadcrumbsFor.LIST_PAGE,
    },
    {
      path: PATHS.DETAILS_PAGE,
      element: wrapComponentInProviders(SUTDetailsPage),
    },
  ],
}
