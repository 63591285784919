import { REDIRECT_TO_AFTER_LOG_OUT_LOCAL_STORAGE_KEY } from './constants'
import { userManager } from './userManager'

/** call this function to log out i.e. in case of a network error, when you have the intention to log in again and redirect the user back to the same page */
export const signOutRedirectWithState = () => {
  localStorage.setItem(
    REDIRECT_TO_AFTER_LOG_OUT_LOCAL_STORAGE_KEY,
    document.location.pathname
  )

  return userManager.signoutRedirect()
}
