import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../utils/messages.res'
import { NAME_CHAR_LIMIT } from './constants'
import { ApiKeyFormFieldProps } from './types'

export const NameInput: React.FC<ApiKeyFormFieldProps> = ({
  isReadOnly = false,
}) => {
  const { control, formState } = useFormContext<{ name: string }>()
  const error = formState.errors.name

  return (
    <Controller
      control={control}
      defaultValue={formState.defaultValues?.name}
      name="name"
      rules={{
        required: messages.INPUT_CANNOT_BE_EMPTY('name'),
        validate: {
          validLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(NAME_CHAR_LIMIT),
            NAME_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={Boolean(error)}
          size="small"
          label="Name"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          required
          autoComplete="off"
        />
      )}
    />
  )
}
