import { Box } from '@mui/material'
import React from 'react'

export interface DataTableToolbarProps {}

const DataTableToolbar: React.FC<
  React.PropsWithChildren<DataTableToolbarProps>
> = ({ children }) => {
  return (
    <Box component="div" display="flex" justifyContent="flex-end" width="100%">
      {children}
    </Box>
  )
}

export default DataTableToolbar
