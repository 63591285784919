import { useParams } from 'react-router-dom'

export type ProjectIdParams = {
  projectId: string
}

export const usePageParams = <
  ParamsOrKey extends string | Record<string, string | undefined> = string
>() => {
  return useParams<ParamsOrKey>() as unknown as Readonly<ParamsOrKey>
}
