const APPLICATION_NAME = 'SIMPHERA'

export const setPageTitle = (title: string = '', subtitle: string = '') => {
  document.title = generatePageTitle(title, subtitle)
}

function generatePageTitle(title: string = '', subtitle: string = '') {
  const parts: Array<string> = []

  if (title && typeof title === 'string') {
    parts.push(title)
  }

  if (subtitle && typeof subtitle === 'string') {
    parts.push(subtitle)
  }

  return parts.length > 0
    ? `${parts.join(' - ')} | ${APPLICATION_NAME}`
    : APPLICATION_NAME
}
