import { mdiCog } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'

export interface ProcessingIconProps {
  animated?: boolean
}

const ProcessingIcon: React.FC<ProcessingIconProps> = ({
  animated = true,
  ...props
}) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g transform="translate(0,6) scale(0.75)">
        <path d={mdiCog}>
          {animated && (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 12 12"
              to="360 12 12"
              dur="3s"
              repeatCount="indefinite"
            />
          )}
        </path>
      </g>
      <g transform="translate(12,0) scale(0.5)">
        <path d={mdiCog}>
          {animated && (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="360 12 12"
              to="0 12 12"
              dur="2s"
              repeatCount="indefinite"
            />
          )}
        </path>
      </g>
    </SvgIcon>
  )
}

export default ProcessingIcon
