import { fmt } from './messageUtils'

/*
  When one of these string arrays is displayed a line break will be placed after each item.
  Use empty strings to create empty lines between two paragraphs.
*/
export const descriptions_res = {
  ERROR_OCCURED: () => fmt('Oops, something went wrong.'),
  ERROR_DATA_NOT_LOADED: () => fmt('Could not load data. Refresh the page.'),
  SETTINGS: () => fmt('Settings'),
  PIN_HEADER: () => fmt('Pin Header'),
  PERMISSION_DENIED: () => fmt(`Permission denied`),
  SELECT_KPI: () => fmt('Select a KPI'),
  SELECT_TEST_ENVIRONMENT: () => fmt('Select a Test Environment'),
  SELECT_SYSTEM_UNDER_TEST: () => fmt('Select a SUT'),
  SELECT_EXPERIMENT_AS_TEMPLATE: () => fmt('Select Experiment as template'),
  SELECT_SCEANRIO: () => fmt('Select a scenario'),
  SELECT_VEHICLE: () => fmt('Select a vehicle'),
  NO_RESULT_FOR_SEARCH: () => fmt('No results match your search criteria.'),
  NO_PROJECT_WRITE_PERMISSION: () =>
    fmt(`You do not have permission to edit items in this project`),
  NO_PROJECT_WRITE_PERMISSION_CREATE_PAGE: () =>
    fmt(`You do not have permission to create items in this project.`),
  CURRENTLY_SELECTED: () => fmt('(Currently selected)'),
}
