/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Relevant parts are copied from scbt utils!
// Origin: apps\scbt\src\utils\createUrlFor.ts
const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      experiment: {
        collection: `/projects/${projectId}/experiments`,
        withId: (experimentId: string) => ({
          detailPage: `/projects/${projectId}/experiments/${experimentId}`,
        }),
      },
      scenario: {
        collection: `/projects/${projectId}/scenarios`,
        import: `/projects/${projectId}/scenarios/import`,
        withId: (scenarioId: string) => ({
          detailPage: `/projects/${projectId}/scenarios/${scenarioId}`,
        }),
      },
      suite: {
        collection: `/projects/${projectId}/scenario-based/suites`,
        new: `/projects/${projectId}/scenario-based/suites/new`,
        withId: (suiteId: string) => ({
          detailPage: `/projects/${projectId}/scenario-based/suites/${suiteId}`,
        }),
      },
      testCase: {
        collection: `/projects/${projectId}/scenario-based/testcases`,
        new: `/projects/${projectId}/scenario-based/testcases/new`,
        withId: (testCaseId: string) => ({
          detailPage: `/projects/${projectId}/scenario-based/testcases/${testCaseId}`,
        }),
      },
    }),
  },
}

export default createUrlFor
