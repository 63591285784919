import IntlMessageFormat from 'intl-messageformat'

const fmt = (message: string, ...rest: any[]): string => {
  const formatter = new IntlMessageFormat(message, 'en-US')
  const formatted = formatter.format(...rest) as string
  return formatted
}
export const messages_res = {
  EMPTY_PAGE_KPIS: () => fmt(`Add your own KPIs via REST API.`),
  NAME_EMPTY: () => fmt(`Please enter a name.`),
  NO_KPI_GRAPH: () =>
    fmt(`The KPI's graph is empty. You can add a graph via REST API.`),
  KPI_UPDATED: () => fmt(`KPI updated.`),
  KPI_UPDATE_FAILED: (errorMessage: string) =>
    fmt(`Failed to update the KPI. {errorMessage}`, {
      errorMessage,
    }),
}
