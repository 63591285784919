/**
 * auto generated by graphql-codegen
 * DO NOT EDIT THIS FILE BY HAND
 */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type Commit = {
  __typename?: 'Commit';
  authorEmail?: Maybe<Scalars['String']['output']>;
  authorName?: Maybe<Scalars['String']['output']>;
  commitId?: Maybe<Scalars['String']['output']>;
  date: Scalars['DateTime']['output'];
  message?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type CommitsOfWorkspaceConnection = {
  __typename?: 'CommitsOfWorkspaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<CommitsOfWorkspaceEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommitsOfWorkspaceEdge = {
  __typename?: 'CommitsOfWorkspaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Project;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type GetProjectsOfWorkspaceInput = {
  gitWorkspaceId: Scalars['ID']['input'];
  querySettings: QuerySettingsInput;
};

export type GitConfiguration = {
  __typename?: 'GitConfiguration';
  checkoutFolders?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pathToProjectYaml?: Maybe<Scalars['String']['output']>;
  repositoryUrl?: Maybe<Scalars['ID']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type GitWorkspace = {
  __typename?: 'GitWorkspace';
  createdBy?: Maybe<User>;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  gitConfiguration: GitConfiguration;
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type GitWorkspacesConnection = {
  __typename?: 'GitWorkspacesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<GitWorkspacesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GitWorkspace>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GitWorkspacesEdge = {
  __typename?: 'GitWorkspacesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GitWorkspace;
};

export type KeyNotFoundError = Error & {
  __typename?: 'KeyNotFoundError';
  message: Scalars['String']['output'];
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  patchGitWorkspace: PatchGitWorkspacePayload;
  patchProject: PatchProjectPayload;
};


export type MutationPatchGitWorkspaceArgs = {
  input?: InputMaybe<PatchGitWorkspaceInput>;
};


export type MutationPatchProjectArgs = {
  input: PatchProjectInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PatchGitWorkspaceError = KeyNotFoundError;

export type PatchGitWorkspaceInput = {
  description: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PatchGitWorkspacePayload = {
  __typename?: 'PatchGitWorkspacePayload';
  errors?: Maybe<Array<PatchGitWorkspaceError>>;
  gitWorkspace?: Maybe<GitWorkspace>;
};

export type PatchProjectError = KeyNotFoundError;

export type PatchProjectInput = {
  description: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type PatchProjectPayload = {
  __typename?: 'PatchProjectPayload';
  errors?: Maybe<Array<PatchProjectError>>;
  project?: Maybe<Project>;
};

export type Project = {
  __typename?: 'Project';
  branch?: Maybe<Scalars['String']['output']>;
  commit?: Maybe<Commit>;
  createdBy?: Maybe<User>;
  createdOn: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  details?: Maybe<Scalars['String']['output']>;
  gitWorkspace?: Maybe<GitWorkspace>;
  gitWorkspaceId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modifiedBy?: Maybe<User>;
  modifiedOn: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ProjectsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Project>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Project;
};

export type Query = {
  __typename?: 'Query';
  availableTags: Array<Scalars['String']['output']>;
  commitsOfWorkspace?: Maybe<CommitsOfWorkspaceConnection>;
  gitWorkspace: GitWorkspace;
  gitWorkspaces?: Maybe<GitWorkspacesConnection>;
  globalProjectPermissions: Array<Scalars['String']['output']>;
  project: Project;
  projectPermissions: Array<Scalars['String']['output']>;
  projects?: Maybe<ProjectsConnection>;
};


export type QueryAvailableTagsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryCommitsOfWorkspaceArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  input: GetProjectsOfWorkspaceInput;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGitWorkspaceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGitWorkspacesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectPermissionsArgs = {
  projectId: Scalars['ID']['input'];
};


export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySettingsInput = {
  descending: Scalars['Boolean']['input'];
  filters?: InputMaybe<Array<KeyValuePairOfStringAndStringInput>>;
  pageSize: Scalars['Int']['input'];
  pageToken: Scalars['String']['input'];
  sortField?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProjectInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ProjectInfoQuery = { __typename?: 'Query', availableTags: Array<string>, projectPermissions: Array<string>, project: { __typename?: 'Project', name: string, gitWorkspace?: { __typename?: 'GitWorkspace', id: string, name: string } | null } };

export type GitWorkspaceInfoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GitWorkspaceInfoQuery = { __typename?: 'Query', gitWorkspace: { __typename?: 'GitWorkspace', id: string, name: string } };


export const ProjectInfoDocument = gql`
    query ProjectInfo($id: ID!) {
  project(id: $id) {
    name
    gitWorkspace {
      id
      name
    }
  }
  availableTags(projectId: $id)
  projectPermissions(projectId: $id)
}
    `;

/**
 * __useProjectInfoQuery__
 *
 * To run a query within a React component, call `useProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectInfoQuery(baseOptions: Apollo.QueryHookOptions<ProjectInfoQuery, ProjectInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectInfoQuery, ProjectInfoQueryVariables>(ProjectInfoDocument, options);
      }
export function useProjectInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectInfoQuery, ProjectInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectInfoQuery, ProjectInfoQueryVariables>(ProjectInfoDocument, options);
        }
export type ProjectInfoQueryHookResult = ReturnType<typeof useProjectInfoQuery>;
export type ProjectInfoLazyQueryHookResult = ReturnType<typeof useProjectInfoLazyQuery>;
export type ProjectInfoQueryResult = Apollo.QueryResult<ProjectInfoQuery, ProjectInfoQueryVariables>;
export const GitWorkspaceInfoDocument = gql`
    query GitWorkspaceInfo($id: ID!) {
  gitWorkspace(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGitWorkspaceInfoQuery__
 *
 * To run a query within a React component, call `useGitWorkspaceInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGitWorkspaceInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGitWorkspaceInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGitWorkspaceInfoQuery(baseOptions: Apollo.QueryHookOptions<GitWorkspaceInfoQuery, GitWorkspaceInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GitWorkspaceInfoQuery, GitWorkspaceInfoQueryVariables>(GitWorkspaceInfoDocument, options);
      }
export function useGitWorkspaceInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GitWorkspaceInfoQuery, GitWorkspaceInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GitWorkspaceInfoQuery, GitWorkspaceInfoQueryVariables>(GitWorkspaceInfoDocument, options);
        }
export type GitWorkspaceInfoQueryHookResult = ReturnType<typeof useGitWorkspaceInfoQuery>;
export type GitWorkspaceInfoLazyQueryHookResult = ReturnType<typeof useGitWorkspaceInfoLazyQuery>;
export type GitWorkspaceInfoQueryResult = Apollo.QueryResult<GitWorkspaceInfoQuery, GitWorkspaceInfoQueryVariables>;