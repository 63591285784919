import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { GlobalConfig } from '@simphera/shared/appconfig'
import React, { useEffect, useState } from 'react'
import { StyledAlert } from './MaintenanceInfoHeader.styles'
import { MaintenanceInfo } from './types'

function getMaintenanceInfoUrlWithoutCache() {
  const timestamp = new Date().getTime()
  return `${GlobalConfig.appConfig.APP_URL}maintenance-infos.json?timestamp=${timestamp}`
}

async function getMaintenanceInfos() {
  try {
    const result = await fetch(getMaintenanceInfoUrlWithoutCache(), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })

    return await result.json()
  } catch (error) {
    console.error(error)
  }
}

export const MaintenanceInfoHeader: React.FC = () => {
  const [maintenanceInfos, setMaintenanceInfos] = useState<
    Array<MaintenanceInfo>
  >([])

  useEffect(() => {
    getMaintenanceInfos().then(setMaintenanceInfos)
  }, [])

  return <MaintenanceInfoHeaderRenderer maintenanceInfos={maintenanceInfos} />
}

interface MaintenanceInfoHeaderRendererProps {
  maintenanceInfos: Array<MaintenanceInfo>
}

export const MaintenanceInfoHeaderRenderer: React.FC<
  MaintenanceInfoHeaderRendererProps
> = ({ maintenanceInfos }) => {
  const [alertsToHide, setAlertsToHide] = useState<Array<number>>([])

  const alertsToShow = [] as MaintenanceInfo[]
  const now = new Date().toISOString()

  maintenanceInfos.forEach((info) => {
    // skip this alert if it's not in the designed timeframe or hidden
    if (info.startDate && info.startDate > now) return
    if (info.endDate && info.endDate < now) return
    if (alertsToHide.includes(info.id)) return

    alertsToShow.push(info)
  })

  if (alertsToShow.length === 0) {
    return <></>
  }

  return (
    <>
      {alertsToShow.map((alert) => (
        <StyledAlert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertsToHide([...alertsToHide, alert.id])
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={alert.severity}
          variant="filled"
        >
          {alert.text}
        </StyledAlert>
      ))}
    </>
  )
}
