import { ApolloError } from '@apollo/client/errors'
import { RestApiError } from '@simphera/shared/rest-clients'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'
import { NetworkErrorPage } from '../common'
import { Loader } from '../Loader'

export const NETWORK_ERROR_MESSAGE = 'A network error occurred.'
export const ITEM_NOT_FOUND_MESSAGE = 'The item does not exist.'
export const UNKNOWN_ERROR = 'An unknown error occured.'

export interface PageWithQueryLayoutProps {
  /** The page content must be passed as a function so it can be rendered conditionally */
  children: () => ReactNode
  loading: boolean
  error?: ApolloError | RestApiError
  primaryErrorText?: string
  secondaryErrorText?: string
  supportErrorText?: string
  refreshButtonText?: string
  notFoundType?: string
  notFoundId?: string
  customErrorAction?: ReactElement
}

interface PageErrorContextData {
  /** Displays an error that renders the current site unusable. */
  setPageError: (error: ApolloError | RestApiError) => void
  handleNetworkErrorDefault: (error: ApolloError | RestApiError) => void
}

const PageErrorContext = createContext<PageErrorContextData | undefined>(
  undefined
)

/* Shows the basic layout depending on loading and error input.*/
export const PageWithQueryLayout: React.FC<PageWithQueryLayoutProps> = ({
  loading,
  error,
  children,
  notFoundType,
  notFoundId,
  ...restProps
}) => {
  const [errorOverwrite, setErrorOverwrite] = useState<
    ApolloError | RestApiError | undefined
  >(undefined)

  const overwriteError = useCallback((error: ApolloError | RestApiError) => {
    setErrorOverwrite(error)
    console.error(error)
  }, [])

  const renderApolloError = (error: ApolloError) => {
    const isNotFoundError =
      error?.graphQLErrors?.at(0)?.extensions?.code === 'NOT_FOUND'

    if (error.networkError) {
      return (
        <NetworkErrorPage errorMessage={NETWORK_ERROR_MESSAGE} {...restProps} />
      )
    } else if (isNotFoundError && notFoundType && notFoundId) {
      return (
        <NetworkErrorPage
          errorMessage={`${notFoundType} with id "${notFoundId}" not found.`}
          primaryErrorText=""
          secondaryErrorText={ITEM_NOT_FOUND_MESSAGE}
          refreshButtonText=""
          supportErrorText={restProps.supportErrorText}
          customErrorAction={restProps.customErrorAction}
        />
      )
    }

    return (
      <NetworkErrorPage
        errorMessage={error.graphQLErrors?.at(0)?.message || error.message}
        {...restProps}
      />
    )
  }

  const renderRestApiError = (error: RestApiError) => (
    <NetworkErrorPage
      errorMessage={error.message || UNKNOWN_ERROR}
      {...restProps}
    />
  )

  const renderErrorOrContents = () => {
    const errorToRender = errorOverwrite || error

    if (!errorToRender) {
      return children()
    } else if (errorToRender instanceof ApolloError) {
      return renderApolloError(errorToRender)
    } else {
      return renderRestApiError(errorToRender)
    }
  }

  return (
    <PageErrorContext.Provider
      value={{
        setPageError: overwriteError,
        handleNetworkErrorDefault: overwriteError,
      }}
    >
      {loading ? <Loader /> : renderErrorOrContents()}
    </PageErrorContext.Provider>
  )
}

export const usePageErrorContext = (): PageErrorContextData => {
  const context = useContext(PageErrorContext)

  if (context === undefined) {
    throw new Error(
      'The usePageErrorContext() hook must be called within a PageWithQueryLayout.'
    )
  }

  return context
}
