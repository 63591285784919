import { fmt } from './messageUtils'

export const captions_res = {
  DESCRIPTION: () => fmt('Description'),
  SETTINGS: () => fmt('Settings'),
  PARAMETERS: () => fmt('Parameters'),
  SORT_BY: () => fmt('Sort by'),
  SENSORS: () => fmt('Sensors'),
  CATEGORY: () => fmt('Category'),
  VALUE: () => fmt('Value'),
  VEH_MASS_WHEELS: () => fmt('Vehicle mass including wheels'),
  FRONT_TRACK_WIDTH: () => fmt('Front track width'),
  REAR_TRACK_WIDTH: () => fmt('Rear track width'),
  FRONT_TIRE: () => fmt('Front tire'),
  REAR_TIRE: () => fmt('Rear tire'),
  WHEELBASE: () => fmt('Wheelbase'),
  HEIGHT_DIFF_FRONT_REAR_AXLE: () =>
    fmt('Height difference of the front-rear axle'),
  ENTER_NAME: () => fmt('Enter a name.'),
  ENTER_DESCRIPTION: () => fmt(`'<Enter description here>`),
  IMPORT_VEHICLE: () => fmt('Import Vehicle'),
  PARAMETER_SET: () => fmt('Parameter Set'),
  TRAFFIC_OBJECT: () => fmt('Traffic Object'),
  MODEL_3D: () => fmt('3D Model'),
  IMAGE: () => fmt('Image'),
  SELECT_VEHICLE_IMAGE: () =>
    fmt('Select a vehicle image (*.png, *.jpg, etc.)'),
  VEHICLES: () => fmt('Vehicles'),
  DELETE_VEHICLE: () => fmt('Delete vehicle.'),
  SELECT_BASE_VEHICLE: () => fmt('Select Vehicle Type'),
  SELECT_BASE_VEHICLE_DESCRIPTION: () =>
    fmt(
      'Please select a vehicle type from the list, for your new vehicle. The vehicle will only be created after saving.'
    ),
  SELECT_SENSOR_CONFIG_JSON: () => fmt(`Select a configuration file.`),
  SELECT_SENSOR_CONFIG_ZIP: () => fmt(`Select additional files archive.`),
  SELECT_SENSOR_TYPE: () => fmt('Select a sensor type.'),
  CANCEL_SENSOR_CREATION: () => fmt('Cancel sensor creation.'),
  DELETE_SENSOR: () => fmt('Delete sensor'),
  SENSOR_CONFIGURATION_JSON_LABEL: () => fmt('Configuration file (*.json)'),
  SENSOR_CONFIGURATION_ZIP_LABEL: () => fmt('Additional files archive (*.zip)'),
  SENSOR_CONFIGURATION_HEADER: () => fmt('Sensor Configuration'),
  PARAMETER: () => fmt('Parameter'),
  CLONE_VEHICLE: () => fmt('Clone vehicle'),
  ASM_CONFIGURATION: () => fmt('ASM Configuration'),
  AURELION_CONFIGURATION: () => fmt('AURELION Configuration'),
  GO_TO_SENSORS_COLLECTION: () => fmt(`Go to Sensors collection`),
  GO_TO_VEHICLES_COLLECTION: () => fmt(`Go to Vehicles collection`),
}
