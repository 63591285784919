const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      experiments: {
        collection: `/projects/${projectId}/experiments`,
        create: `/projects/${projectId}/experiments/create`,
        withId: (experimentId: string) => ({
          detailPage: `/projects/${projectId}/experiments/${experimentId}`,
          edit: `/projects/${projectId}/experiments/${experimentId}/edit`,
        }),
      },
      scenario: {
        collection: `/projects/${projectId}/scenarios`,
        import: `/projects/${projectId}/scenarios/import`,
        withId: (scenarioId: string) => ({
          detailPage: `/projects/${projectId}/scenarios/${scenarioId}`,
          and: {
            linkDate: (linkDate: number) => ({
              detailPage: `/projects/${projectId}/scenarios/${scenarioId}?linkDate=${String(
                linkDate
              )}`,
            }),
          },
        }),
      },
      suite: {
        withId: (suiteId: string) => ({
          detailPage: `/projects/${projectId}/scenario-based/suites/${suiteId}`,
        }),
      },
      suts: {
        collection: `/projects/${projectId}/suts`,
        withId: (sutId: string) => ({
          detailPage: `/projects/${projectId}/suts/${sutId}`,
          and: {
            linkDate: (linkDate: number) => ({
              detailPage: `/projects/${projectId}/suts/${sutId}?linkDate=${String(
                linkDate
              )}`,
            }),
          },
        }),
      },
      testEnvironment: {
        collection: `/projects/${projectId}/testenvironments`,
        withId: (teId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${teId}`,
          and: {
            linkDate: (linkDate: number) => ({
              detailPage: `/projects/${projectId}/testenvironments/${teId}?linkDate=${String(
                linkDate
              )}`,
            }),
          },
        }),
      },
      vehicles: {
        collection: `/projects/${projectId}/vehicles`,
        withId: (vehicleId: string) => ({
          detailPage: `/projects/${projectId}/vehicles/${vehicleId}`,
          and: {
            linkDate: (linkDate: number) => ({
              detailPage: `/projects/${projectId}/vehicles/${vehicleId}?linkDate=${String(
                linkDate
              )}`,
            }),
          },
        }),
      },
    }),
  },
}
export default createUrlFor
