import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  ScenarioIcon,
  SidebarItemInterpolate,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import createBreadcrumbsFor from './shared/utils/createBreadcrumbsFor'
import { PATHS } from './shared/utils/paths'

const ScenarioListPage = React.lazy(
  () => import('./components/ScenarioListPage/ScenarioListPage')
)
const ScenarioDetailsPage = React.lazy(
  () => import('./components/ScenarioDetailsPage/ScenarioDetailsPage')
)
const ScenarioImportPage = React.lazy(
  () => import('./components/ScenarioImportPage/ScenarioImportPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SCENARIOS_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="Scenarios"
      icon={<ScenarioIcon />}
      to={PATHS.SCENARIO_LIST}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) => `/projects/${projectId}/scenarios`,
    icon: <ScenarioIcon />,
    label: 'Scenarios',
  },
  routes: [
    {
      path: PATHS.SCENARIO_LIST,
      element: wrapComponentInProviders(ScenarioListPage),
      breadcrumbs: createBreadcrumbsFor.SCENARIO_LIST,
    },
    {
      path: PATHS.SCENARIO_IMPORT,
      element: wrapComponentInProviders(ScenarioImportPage),
      breadcrumbs: createBreadcrumbsFor.SCENARIO_IMPORT,
    },
    {
      path: PATHS.SCENARIO_DETAIL,
      element: wrapComponentInProviders(ScenarioDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SCENARIO_LIST,
    },
  ],
}
