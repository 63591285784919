import { ApolloProvider } from '@apollo/client'
import { useLogout, useUserInfo } from '@simphera/shared/state'
import React from 'react'

import { apolloClient, apolloClientUpdate } from './client'

export const wrapComponentInProviders = (
  Component: React.FC | React.ComponentType<any>
): React.ReactElement => {
  return (
    <ApolloWrapper>
      <Component />
    </ApolloWrapper>
  )
}

const ApolloWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = useUserInfo()
  const logout = useLogout()
  apolloClientUpdate(token, logout)
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
