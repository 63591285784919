import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { ScbtIcon } from '@simphera/shared/ui-simphera'
import { lazy } from 'react'
import { useParams } from 'react-router-dom'
import createBreadcrumbsFor from './utils/createBreadcrumbsFor'
import createUrlFor, { areaScenarioBased } from './utils/createUrlFor'
import { PATHS } from './utils/paths'
import { wrapComponentInProviders } from './utils/utils'

const SuiteOverview = lazy(() => import('./components/Suite/SuiteListPage'))
const TestCaseResultDetailPage = lazy(
  () => import('./components/Run/TestCaseResultDetailPage')
)
const SuiteDetails = lazy(() => import('./components/Suite/SuiteDetailPage'))
const SuiteNew = lazy(() => import('./components/Suite/NewSuitePage'))
const RunsOverview = lazy(() => import('./components/Run/RunListPage'))
const RunDetailPage = lazy(() => import('./components/Run/RunDetailPage'))
const ResultDetailPage = lazy(() => import('./components/Run/ResultDetailPage'))
const TestCaseDetails = lazy(
  () => import('./components/LogicalTestCase/LogicalTestCaseDetailPage')
)
const TestCaseNew = lazy(
  () => import('./components/LogicalTestCase/NewLogicalTestCasePage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Scenario-based" icon={<ScbtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={createUrlFor.project.withId(projectId!).suite.collection}
        customActivateFn={({ location }) =>
          location.pathname.includes(areaScenarioBased) &&
          !location.pathname.includes('runs') &&
          !location.pathname.includes('results')
        }
      />
      <SidebarSubGroupItem
        name="Runs"
        to={createUrlFor.project.withId(projectId!).run.collection}
        customActivateFn={({ location }) =>
          location.pathname.includes(areaScenarioBased) &&
          (location.pathname.includes('runs') ||
            location.pathname.includes('results'))
        }
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.SCBT_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  projectItemEntry: {
    url: (projectId: string): string =>
      createUrlFor.project.withId(projectId).suite.collection,
    icon: <ScbtIcon />,
    label: 'Scenario-based',
  },
  routes: [
    {
      path: PATHS.SUITE_COLLECTION,
      element: wrapComponentInProviders(SuiteOverview),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    // important: 'new suite' route must be defined before 'suite detail' route
    {
      path: PATHS.SUITE_NEW,
      element: wrapComponentInProviders(SuiteNew),
      breadcrumbs: createBreadcrumbsFor.SUITE_NEW,
    },
    {
      path: PATHS.SUITE_DETAIL_PAGE,
      element: wrapComponentInProviders(SuiteDetails),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    {
      path: PATHS.TEST_CASE_NEW,
      element: wrapComponentInProviders(TestCaseNew),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    {
      path: PATHS.TEST_CASE_DETAILS_PAGE,
      element: wrapComponentInProviders(TestCaseDetails),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    {
      path: PATHS.RUN_COLLECTION,
      element: wrapComponentInProviders(RunsOverview),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    {
      path: PATHS.RUN_DETAIL_PAGE,
      element: wrapComponentInProviders(RunDetailPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    {
      path: PATHS.RUN_TESTCASE_DETAIL_PAGE,
      element: wrapComponentInProviders(TestCaseResultDetailPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    {
      path: PATHS.RESULT_DETAIL_PAGE,
      element: wrapComponentInProviders(ResultDetailPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
  ],
}
