import { messages_res } from '../shared/resources'

const parseHeaders = (rawHeaders: string) => {
  const headers = new Headers()
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ')
  preProcessedHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':')
    const key = parts.shift()?.trim()
    if (key) {
      const value = parts.join(':').trim()
      headers.append(key, value)
    }
  })
  return headers
}

export const uploadFetch = (input: RequestInfo, options: Options) => {
  return new Promise<Response>((resolve, reject) => {
    if (!options.method || !options.body) {
      return reject(messages_res.INVALID_OPTIONS())
    }

    const url = typeof input === 'string' ? input : input.url

    const xhr = new XMLHttpRequest()
    if (options?.onProgress) {
      xhr.upload.onprogress = options.onProgress
    }
    xhr.onerror = () => {
      reject(messages_res.NETWORK_REQUEST_FAILED())
    }
    xhr.ontimeout = () => {
      reject(messages_res.NETWORK_REQUEST_TIMEOUT())
    }
    xhr.onload = () => {
      const response = xhr.response as BodyInit | null | undefined
      const body = response ?? xhr.responseText
      const init: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders()),
      }

      resolve(new Response(body, init))
    }

    xhr.open(options.method, url, true)

    if (options.headers) {
      const headers = options.headers as Record<string, string>
      for (const key in headers) {
        xhr.setRequestHeader(key, headers[key])
      }
    }

    if (options.onProgress) {
      xhr.upload.onprogress = options.onProgress
    }

    if (options.setAbortHandler) {
      options.setAbortHandler(() => {
        xhr.abort()
      })
    }

    xhr.send(options.body as XMLHttpRequestBodyInit)
  })
}

type Options = RequestInit & {
  onProgress?: (ev: ProgressEvent) => void
  setAbortHandler?: (handler: () => void) => void
}

export const customFetch = (input: RequestInfo, options?: Options) => {
  if (options?.onProgress) {
    return uploadFetch(input, options)
  }
  return fetch(input, options)
}
