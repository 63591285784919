import { colors } from '@dspace-internal/ui-kit'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Badge, Box, ListItemButton, Typography } from '@mui/material'
import { Operation, OperationStatus } from '@simphera/shared/rest-clients'
import { formatDistance } from 'date-fns'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { NOTIFICATION_ID_SEARCH_PARAM } from './constants'
import { statusIconStyles } from './styles'

interface NotificationListItemProps {
  notification: Operation
  lastTimeNotificationsWereRead: number
}

export const COLOR_BY_STATUS_MAP: Record<OperationStatus, string> = {
  Succeeded: colors.success_70,
  Running: colors.warning_70,
  Failed: colors.error_70,
  NotStarted: colors.gray_70,
}

export const ICON_BY_STATUS_MAP: Record<OperationStatus, React.ReactElement> = {
  Succeeded: <CheckCircleOutlineIcon sx={statusIconStyles} />,
  Running: <PlayCircleOutlineIcon sx={statusIconStyles} />,
  Failed: <ErrorOutlineIcon sx={statusIconStyles} />,
  NotStarted: <PauseCircleOutlineIcon sx={statusIconStyles} />,
}

function isUnreadNotification(
  lastTimeNotificationsWereRead: number,
  notification: Operation
) {
  return (
    lastTimeNotificationsWereRead < Date.parse(notification.createdOn) ||
    (notification.finishedOn &&
      lastTimeNotificationsWereRead < Date.parse(notification.finishedOn))
  )
}

export const NotificationListItem: React.FC<NotificationListItemProps> = ({
  notification,
  lastTimeNotificationsWereRead,
}) => {
  const [, setSearchParams] = useSearchParams()
  const isUnread = isUnreadNotification(
    lastTimeNotificationsWereRead,
    notification
  )

  const openNotificationDialog = () => {
    setSearchParams({ [NOTIFICATION_ID_SEARCH_PARAM]: notification.id })
  }

  return (
    <>
      <ListItemButton
        alignItems="flex-start"
        onClick={openNotificationDialog}
        sx={{
          bgcolor: isUnread ? colors.blue_10 : colors.white,
          gap: 4,
          alignItems: 'center',
        }}
      >
        <Badge
          invisible={!isUnread}
          color="error"
          variant="dot"
          overlap="circular"
        >
          {ICON_BY_STATUS_MAP[notification.status]}
        </Badge>
        <Box component="div" overflow="hidden" width="100%">
          <Typography
            variant="body2"
            fontWeight={isUnread ? '500' : undefined}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {notification.title}
          </Typography>
          <Box component="div" display="flex" justifyContent="space-between">
            <Typography
              variant="body2"
              fontWeight="500"
              color={COLOR_BY_STATUS_MAP[notification.status]}
            >
              {notification.status}
            </Typography>
            <Typography
              variant="body2"
              color={colors.gray_70}
              whiteSpace="nowrap"
            >
              {formatDistance(new Date(notification.createdOn), new Date())} ago
            </Typography>
          </Box>
        </Box>
      </ListItemButton>
    </>
  )
}
