export type JsonPatchOperation =
  | {
      op: 'remove'
      path: string
    }
  | {
      op: 'add' | 'replace' | 'test'
      path: string
      value: any
    }

/**
 * utility function to collect json patch operations with less code
 * @example
const { add, remove } = jsonPatch()

remove('isAdmin')
add('role', 'User')
makePatchApiCall(getOperations()).then(() => {})
 */
export const jsonPatch = () => {
  const operations: Array<JsonPatchOperation> = []

  return {
    add: (path: string, value: any) =>
      operations.push({ op: 'add', path, value }),
    replace: (path: string, value: any) =>
      operations.push({ op: 'replace', path, value }),
    test: (path: string, value: any) =>
      operations.push({ op: 'test', path, value }),
    remove: (path: string) => operations.push({ op: 'remove', path }),
    getOperations: () => operations,
  }
}
