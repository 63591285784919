import { useCallback, useEffect, useRef, useState } from 'react'
// This type is generated by openapi-typescript and is identitcal accross all domains, we just needed to pick one here, so we took the one from KPI
import { ApiError, CancelablePromise, RestApiError } from '../generated/kpi'

export interface UseOpenApiQueryOptions<TQueryParams> {
  params: TQueryParams
  skip?: boolean
}

interface UseOpenApiQueryResult<TData> {
  data: TData | undefined
  /** Is true whenever the query is fetching data. Use if you want to display the loading state whenever the query is refetching. */
  isLoading: boolean
  /** Only is true while data is being queried for the first time. Use if you don't want to display the loading state while refetching and display old data instead. */
  isLoadingInitialData: boolean
  error: RestApiError | undefined
  refetch: () => void
}

type ApiFunction<TData, TQueryParams> = (
  queryParams: TQueryParams
) => CancelablePromise<TData>

export const useOpenApiQuery = <TData, TQueryParams extends object>(
  apiFunction: ApiFunction<TData, TQueryParams>,
  options: UseOpenApiQueryOptions<TQueryParams>
): UseOpenApiQueryResult<TData> => {
  const { params, skip = false } = options

  const [data, setData] = useState<TData | undefined>()
  const [isLoadingQuery, setIsLoadingQuery] = useState(!skip)
  const [error, setError] = useState<RestApiError | undefined>(undefined)

  const firstFetch = useRef(true)

  const refetch = useCallback(() => {
    setIsLoadingQuery(true)

    apiFunction(params)
      .then(setData)
      .catch((error: ApiError) => {
        console.error(error.body)
        setError(error.body)
      })
      .finally(() => {
        setIsLoadingQuery(false)
        firstFetch.current = false
      })

    // Dependency array must only contain query params
  }, Object.values(params))

  useEffect(() => {
    if (skip) {
      return
    }

    refetch()

    // refetch function instance changes when query params change, thereby this query refetches, when query params change
  }, [refetch, skip])

  // Loading also needs to be true in the first rerender after skip flips to true
  const isLoading =
    isLoadingQuery || (!skip && data === undefined && error === undefined)

  return {
    data,
    isLoading,
    isLoadingInitialData: isLoading && firstFetch.current,
    error,
    refetch,
  }
}
