import createUrlFor from './createUrlFor'

const project = createUrlFor.project.withId(':projectId')

export const PATHS = {
  EXPERIMENT_LIST: project.experiments.collection,
  EXPERIMENT_CREATE: project.experiments.create,
  EXPERIMENT_DETAIL: project.experiments.withId(':experimentId').detailPage,
  EXPERIMENT_EDIT: project.experiments.withId(':experimentId').edit,
}
