import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { DrtIcon } from '@simphera/shared/ui-simphera'
import React from 'react'
import { useParams } from 'react-router-dom'
import { wrapComponentInProviders } from './client/wrapper'
import createBreadcrumbsFor from './shared/utils/createBreadcrumbsFor'
import createUrlFor from './shared/utils/createUrlFor'
import { PATHS } from './shared/utils/paths'

const SuitesPage = React.lazy(
  () => import('./components/SuitesPage/SuitesPage')
)
const NewSuitePage = React.lazy(
  () => import('./components/SuiteDetailsPage/NewSuitePage')
)
const SuiteDetailsPage = React.lazy(
  () => import('./components/SuiteDetailsPage/SuiteDetailsPage')
)
const NewTestCasePage = React.lazy(
  () => import('./components/TestCaseDetailsPage/NewTestCasePage')
)
const TestCaseDetailsPage = React.lazy(
  () => import('./components/TestCaseDetailsPage/TestCaseDetailsPage')
)
const RunsPage = React.lazy(() => import('./components/RunsPage/RunsPage'))
const RunDetailsPage = React.lazy(() => import('./components/RunDetailsPage'))
const TestCaseResultDetailsPage = React.lazy(
  () => import('./components/TestCaseResultDetailsPage')
)
const RecordingResultDetailsPage = React.lazy(
  () => import('./components/RecordingResultDetailsPage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Data Replay" icon={<DrtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={createUrlFor.project.withId(projectId!).suite.collection}
        customActivateFn={({ location }) =>
          location.pathname.includes('datareplay') &&
          !location.pathname.includes('runs') &&
          !location.pathname.includes('results')
        }
      />
      <SidebarSubGroupItem
        name="Runs"
        to={createUrlFor.project.withId(projectId!).run.collection}
        customActivateFn={({ location }) =>
          location.pathname.includes('datareplay') &&
          (location.pathname.includes('runs') ||
            location.pathname.includes('results'))
        }
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.DATAREPLAY_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  // Auf der Projects Seite die kleinen buttons an einem project
  projectItemEntry: {
    url: (projectId: string) =>
      createUrlFor.project.withId(projectId).suite.collection,
    icon: <DrtIcon />,
    label: 'Data Replay',
  },
  // Routes with menu paths and breadcrumbs
  routes: [
    // Suites Overview
    {
      path: PATHS.SUITE_COLLECTION,
      element: wrapComponentInProviders(SuitesPage),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    // New Suite
    {
      path: PATHS.SUITE_NEW,
      element: wrapComponentInProviders(NewSuitePage),
      breadcrumbs: createBreadcrumbsFor.SUITE_NEW,
    },
    // Suite Details
    {
      path: PATHS.SUITE_DETAIL_PAGE,
      element: wrapComponentInProviders(SuiteDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    // New TestCase
    {
      path: PATHS.TEST_CASE_NEW,
      element: wrapComponentInProviders(NewTestCasePage),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    // TestCase Details
    {
      path: PATHS.TEST_CASE_DETAILS_PAGE,
      element: wrapComponentInProviders(TestCaseDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SUITE_COLLECTION,
    },
    {
      path: PATHS.RUN_DETAIL_PAGE,
      element: wrapComponentInProviders(RunDetailsPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    // Runs
    {
      path: PATHS.RUN_COLLECTION,
      element: wrapComponentInProviders(RunsPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    // Test Case Results
    {
      path: PATHS.RUN_TESTCASE_DETAIL_PAGE,
      element: wrapComponentInProviders(TestCaseResultDetailsPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
    // Recording Results
    {
      path: PATHS.RESULT_DETAIL_PAGE,
      element: wrapComponentInProviders(RecordingResultDetailsPage),
      breadcrumbs: createBreadcrumbsFor.RUN_COLLECTION,
    },
  ],
}
