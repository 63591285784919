import { BreadcrumbInterpolate } from '@simphera/shared/ui-simphera'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  LIST_PAGE: [<BreadcrumbInterpolate name="Test Environments" />],
  DETAILS_PAGE: (testEnvironmentName: string) => [
    <BreadcrumbInterpolate name="Test Environments" to={PATHS.LIST_PAGE} />,
    <BreadcrumbInterpolate name={testEnvironmentName} />,
  ],
}

export default createBreadcrumbsFor
