import { getUserInfo } from '@simphera/shared/state'
import { OpenAPIConfig } from '../generated/kpi'

interface ApplyClientConfigOptions {
  baseUrl: string
}

export const applyClientConfig = (
  openAPIConfig: OpenAPIConfig,
  { baseUrl }: ApplyClientConfigOptions
) => {
  openAPIConfig.BASE = baseUrl
  openAPIConfig.TOKEN = async () => getUserInfo()?.token as string
}
