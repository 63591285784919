import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { LoaderText, SpinnerRoot } from './Loader.styles'

export interface LoaderProps {
  delay?: number
  local?: boolean
  text?: string
}

export const Loader: React.FC<LoaderProps> = ({
  delay = 500,
  local = false,
  text = '',
  ...rest
}) => {
  const [display, setDisplay] = useState(delay === 0)

  useEffect(() => {
    if (delay > 0) {
      const timer = setTimeout(() => setDisplay(true), delay)
      return () => clearTimeout(timer)
    }
  }, [delay])

  return delay === 0 || display ? (
    <SpinnerRoot
      {...rest}
      $marginTop={local ? '0' : '40vh'}
      data-testid="loader"
    >
      <CircularProgress disableShrink />
      <LoaderText>{text}</LoaderText>
    </SpinnerRoot>
  ) : null
}
