import { mdiOrbitVariant } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'

export interface EvaluatingIconProps {
  animated?: boolean
}

const EvaluatingIcon: React.FC<EvaluatingIconProps> = ({
  animated = true,
  ...props
}) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g>
        <path
          d={mdiOrbitVariant}
          transform="scale(-1 1) translate(-24,0)"
        ></path>
        {animated && (
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            from="0 12 12"
            to="360 12 12"
            dur="3s"
            repeatCount="indefinite"
          />
        )}
      </g>
    </SvgIcon>
  )
}

export default EvaluatingIcon
