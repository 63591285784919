import { DiagramEngine } from '@projectstorm/react-diagrams-core'

import { DefaultInputKpiNode } from './ProcessingGraphNodes/KpiNodes/DefaultInputKpiNode'
import {
  DefaultKpiEditNode,
  DefaultKpiResultNode,
} from './ProcessingGraphNodes/KpiNodes/DefaultKpiNode'
import { DefaultOutputKpiNode } from './ProcessingGraphNodes/KpiNodes/DefaultOutputKpiNode'
import { FloatComparisonNode } from './ProcessingGraphNodes/KpiNodes/FloatComparisonNode'
import { FloatInputEditNode } from './ProcessingGraphNodes/KpiNodes/FloatInputNode'
import { KpiNodeModel } from './ProcessingGraphNodes/KpiNodes/KpiNodeModel'

export enum KpiGraphNodeType {
  RESULT_INPUT = 'outputResult',
  PYTHON = 'python',
  FLOAT_INPUT = 'floatInput',
  FLOAT_COMPARISON = 'comparison',
  VERDICT = 'verdict',
}

export type KpiGraphNode =
  | KpiGraphNodeBase<KpiGraphNodeType.RESULT_INPUT>
  | KpiGraphNodeBase<KpiGraphNodeType.PYTHON>
  | KpiFloatInputNode
  | KpiFloatComparisonNode
  | KpiGraphNodeBase<KpiGraphNodeType.VERDICT>

export interface KpiGraphNodeBase<TType extends KpiGraphNodeType> {
  type: TType
  inputs?: KpiGraphNodePort[]
  outputs?: KpiGraphNodePort[]
}

export interface KpiFloatInputNode
  extends KpiGraphNodeBase<KpiGraphNodeType.FLOAT_INPUT> {
  config: {
    value: number
  }
}

export interface KpiFloatComparisonNode
  extends KpiGraphNodeBase<KpiGraphNodeType.FLOAT_COMPARISON> {
  config: {
    operator: string
  }
}

export interface KpiGraphNodePort {
  name: string
}

export interface KpiGraphLink {
  source: string
  target: string
  sourceOutput: string
  targetInput: string
}

export interface KpiGraph {
  nodes: Record<string, KpiGraphNode>
  links: KpiGraphLink[]
}

export const parseKpiGraphJson = (json: string) => {
  return JSON.parse(json) as KpiGraph
}

export const kpiGraphNodeTypeLabelMapping: Record<KpiGraphNodeType, string> = {
  [KpiGraphNodeType.RESULT_INPUT]: 'Result Input',
  [KpiGraphNodeType.PYTHON]: 'Python',
  [KpiGraphNodeType.FLOAT_INPUT]: 'Float Input',
  [KpiGraphNodeType.FLOAT_COMPARISON]: 'Comparison',
  [KpiGraphNodeType.VERDICT]: 'Verdict',
}

export interface KpiGraphNodeComponentProps<TGraphNode extends KpiGraphNode> {
  engine: DiagramEngine
  node: TGraphNode
  model: KpiNodeModel
}

export const kpiGraphNodeTypeNodeComponentMapping: Record<
  KpiGraphNodeType,
  {
    EditComponent: React.FC<KpiGraphNodeComponentProps<any>>
    ResultComponent: React.FC<KpiGraphNodeComponentProps<any>>
  }
> = {
  [KpiGraphNodeType.RESULT_INPUT]: {
    EditComponent: DefaultInputKpiNode,
    ResultComponent: DefaultInputKpiNode,
  },
  [KpiGraphNodeType.PYTHON]: {
    EditComponent: DefaultKpiEditNode,
    ResultComponent: DefaultKpiResultNode,
  },
  [KpiGraphNodeType.FLOAT_INPUT]: {
    EditComponent: FloatInputEditNode,
    ResultComponent: DefaultKpiResultNode,
  },
  [KpiGraphNodeType.FLOAT_COMPARISON]: {
    EditComponent: FloatComparisonNode,
    ResultComponent: FloatComparisonNode,
  },
  [KpiGraphNodeType.VERDICT]: {
    EditComponent: DefaultOutputKpiNode,
    ResultComponent: DefaultOutputKpiNode,
  },
}

export const getNodeInputsOutputs = (node: KpiGraphNode) => {
  return {
    inputs: node.inputs ?? [],
    outputs: node.outputs ?? [],
  }
}
