import { colors } from '@dspace-internal/ui-kit'
import { LinearProgress, styled } from '@mui/material'

export const ButtonProgress = styled(LinearProgress)(() => ({
  color: `${colors.gray_20}`,
  backgroundColor: `${colors.gray_20}`,
  borderColor: `${colors.gray_20}`,
  marginTop: `-4px`,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderRadius: '8px',
}))

export const ButtonWrapper = styled('div')(() => ({
  position: 'relative',
  width: 'fit-content',
}))
