import { Button, PageHeader, Tooltip } from '@dspace-internal/ui-kit'
import LinkIcon from '@mui/icons-material/Link'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Divider, ListItemIcon, MenuItem, Typography } from '@mui/material'
import React, { ReactElement, useState } from 'react'
import { SimpheraPageHeader } from '../SimpheraPageHeader'
import { StyledMenu } from './CustomPageHeader.style'
import { getAdditionalLinks } from './getAdditionalLinks'

export type SecondaryActionProps = {
  title: string
  icon?: React.ReactElement
  disabled?: boolean
  tooltip?: string
  testid?: string
  onClick: () => void
}

type KeyValuePair = { key: string; value: string }

type CustomPageHeaderProps = Omit<
  React.ComponentProps<typeof PageHeader>,
  'secondaryAction'
> & {
  secondaryActions?: Array<SecondaryActionProps | 'divider'>
  titleEndAdornment?: React.ReactNode
  placeholder?: Array<KeyValuePair>
  id?: string

  /** Forces secondary actions and additional links to always be displayed as options in a dropdown menu, rather than an independent button, even when there is only one action. */
  forceSecondaryActionsOverflowMenu?: boolean
}

const replacePlaceholder = (
  placeholder: Array<KeyValuePair> | undefined,
  url: string
) => {
  let replacedUrl = url

  if (placeholder) {
    placeholder.forEach((p) => {
      const placeholderWithDelimiter = `%${p.key}%`
      replacedUrl = replacedUrl.replace(placeholderWithDelimiter, p.value)
    })
  }

  return replacedUrl
}

const SecondaryAction = (props: CustomPageHeaderProps): React.ReactElement => {
  const {
    id,
    placeholder,
    secondaryActions = [],
    forceSecondaryActionsOverflowMenu = false,
  } = props

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const closeOverflowMenu = () => setAnchorEl(null)
  const openOverflowMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget)
  const toggleOverflowMenu = (event: React.MouseEvent<HTMLElement>) => {
    anchorEl ? closeOverflowMenu() : openOverflowMenu(event)
  }

  const additionalLinks = getAdditionalLinks(id)

  // case A: no secondary action
  if (secondaryActions.length === 0 && additionalLinks.length === 0) {
    return <></>
  }

  // case B: button as only secondary action
  if (
    !forceSecondaryActionsOverflowMenu &&
    secondaryActions.length === 1 &&
    additionalLinks.length === 0
  ) {
    const action = secondaryActions[0] as SecondaryActionProps | 'divider'

    return action === 'divider' ? (
      <></>
    ) : (
      <Button label={action.title} onClick={action.onClick} />
    )
  }

  // case C: additional link as only secondary action
  if (
    !forceSecondaryActionsOverflowMenu &&
    secondaryActions.length === 0 &&
    additionalLinks.length === 1
  ) {
    const additionalLink = additionalLinks[0]

    return (
      <Button
        component="a"
        href={replacePlaceholder(placeholder, additionalLink.url)}
        icon={<LinkIcon />}
        label={additionalLink.title}
        title={additionalLink.title}
      />
    )
  }

  // case D: multiple buttons or additional links -> return button with context menu
  const menuEntries: Array<ReactElement> = []

  secondaryActions?.forEach((action, index) => {
    if (action === 'divider') {
      menuEntries.push(<Divider key={`divider-${index}`} sx={{ margin: 0 }} />)
    } else {
      menuEntries.push(
        <MenuItemPredefinedSecondaryAction
          key={action.title}
          action={action}
          closeOverflowMenu={closeOverflowMenu}
        />
      )
    }
  })

  additionalLinks.forEach((additionalLink, index, arr) => {
    // add divider just before first additional link
    if (arr.length > 1 && index === 0) {
      menuEntries.push(<Divider key={`divider-${index}`} sx={{ margin: 0 }} />)
    }

    menuEntries.push(
      <MenuItemForAdditionalLink
        key={additionalLink.url}
        onClick={closeOverflowMenu}
        title={additionalLink.title}
        url={replacePlaceholder(placeholder, additionalLink.url)}
      />
    )
  })

  return (
    <>
      <Button
        data-testid="page_header_menu_button"
        variant="outlined"
        color="primary"
        icon={<MoreVertIcon />}
        label="More"
        iconOnly={true}
        onClick={toggleOverflowMenu}
      />
      <StyledMenu
        data-testid="page_header_menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeOverflowMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {menuEntries}
      </StyledMenu>
    </>
  )
}

/**
 * wrapper around UI-Kit PageHeader
 * sets the page title based on the passed in title and subtitle props
 * displays either a single secondary button or a menu with all secondary actions, depending on secondaryActions prop
 * */
export const CustomPageHeader: React.FC<CustomPageHeaderProps> = (props) => {
  return (
    <SimpheraPageHeader
      title={props.title}
      subtitle={props.subtitle}
      primaryAction={props.primaryAction}
      secondaryAction={<SecondaryAction {...props} />}
      titleEndAdornment={props.titleEndAdornment}
    />
  )
}

type MenuItemPredefinedSecondaryActionProps = {
  action: SecondaryActionProps
  closeOverflowMenu: () => void
}

const MenuItemPredefinedSecondaryAction = React.forwardRef<
  HTMLLIElement,
  MenuItemPredefinedSecondaryActionProps
>(({ action, closeOverflowMenu }, ref) => {
  const { title, onClick, icon, disabled, tooltip, testid } = action

  const menuItem = (
    <MenuItem
      ref={ref}
      data-testid={testid}
      disabled={disabled ?? false}
      onClick={() => {
        onClick()
        closeOverflowMenu()
      }}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <Typography title={title} variant="inherit" noWrap>
        {title}
      </Typography>
    </MenuItem>
  )

  return tooltip ? <Tooltip message={tooltip}>{menuItem}</Tooltip> : menuItem
})

type MenuItemForAdditionalLinkProps = {
  onClick: () => void
  title: string
  url: string
  testid?: string
}

const MenuItemForAdditionalLink = React.forwardRef<
  HTMLAnchorElement,
  MenuItemForAdditionalLinkProps
>(({ onClick, title, url, testid }, ref) => {
  return (
    <MenuItem
      ref={ref}
      data-testid={testid}
      href={url}
      component="a"
      onClick={onClick}
    >
      <ListItemIcon>
        <LinkIcon fontSize="small" />
      </ListItemIcon>
      <Typography title={title} variant="inherit" noWrap>
        {title}
      </Typography>
    </MenuItem>
  )
})
