import { Spinner, colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import { Box, SxProps } from '@mui/material'
import React, { PropsWithChildren } from 'react'

export const NotificationList = styled.div`
  padding: 0;
  flex: 1;
  overflow-y: auto;
`

export const PopoverStyles: SxProps = {
  '.MuiPaper-root': {
    maxWidth: '50vw',
    minWidth: '420px',
    maxHeight: '50vh',
    border: `1px solid ${colors.gray_50}`,
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
  },
}

export const SmallSpinner = () => (
  <Spinner
    height="80px"
    sx={{
      '.MuiCircularProgress-root': {
        width: '24px !important',
        height: '24px !important',
      },
    }}
  />
)

export const NotificationHeader: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box
      component="div"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingInline="16px"
      minHeight="56px"
      gap={4}
      borderBottom="2px solid rgba(0, 0, 0, 0.12)"
    >
      {children}
    </Box>
  )
}

export const statusIconStyles: SxProps = {
  color: colors.gray_50,
  width: '28px',
  height: '28px',
}
