import { fmt } from './messageUtils'

export const captions_res = {
  CALC_PARAMETERS: () => fmt('Calculated Parameters'),
  ROAD: () => fmt('Road'),
  MANEUVER: () => fmt('Maneuver'),
  DESCRIPTION: () => fmt('Description'),
  ORIGIN: () => fmt('Origin'),
  VALUE: () => fmt('Value'),
  VALUE_EXPRESSION: () => fmt('Value Expression'),
  MIN: () => fmt('Min'),
  MAX: () => fmt('Max'),
  SCENARIOS: () => fmt('Scenarios'),
  SORT_BY: () => fmt('Sort by'),
  ASCENDING: () => fmt('Ascending'),
  DESCENDING: () => fmt('Descending'),
  NEWEST_TO_OLDEST: () => fmt('Newest to oldest'),
  OLDEST_TO_NEWEST: () => fmt('Oldest to newest'),
  VALIDATION_PARAMETERS: () => fmt('Scenario Parameters'),
  DELETE_SCENARIO: () => fmt('Delete scenario'),
  SCENARIO_REFERENCES: () => fmt('Scenario References'),
  IMPORT_SCENARIOS: () => fmt('Import Scenarios'),
  DETAILS_SUBTITLE_MODIFIED: () => fmt('Edit Scenario'),
  DETAILS_SUBTITLE: () => fmt('Scenario'),
  IMPORT: () => fmt('Import'),
  ADD_TAGS: () => fmt('Add tags'),
  ENTER_ADD_NEW_TAG: () => fmt('Press Enter to add the new tag.'),
  MANEUVERS: () => fmt('Maneuvers'),
  ROADS: () => fmt('Roads'),
  TRAFFIC_OBJECTS: () => fmt('Traffic Objects'),
  TRAFFIC_DRIVERS: () => fmt('Traffic Drivers'),
  ENVIRONMENT: () => fmt('3D Environment'),
  ROAD_PREVIEW: () => fmt('Road preview'),
  FILE_CONTENT: (filename: string) =>
    fmt('Content of "{filename}"', { filename }),
  MODEL_FILE: () => fmt('Model file'),
  MATERIAL_MAPPING_FILE: () => fmt('Material mapping file'),
  OVERWRITE_SCENERY: () => fmt('Overwrite 3D environment'),
  SELECT_ENVIRONMENT: () => fmt('Select 3D environment'),
  TRAFFIC_OBJECT: () => fmt('Traffic Object'),
  SIMULATION_ID: () => fmt('ID'),
  ITEMS_PER_PAGE: () => fmt('Items per page:'),
  SHOW_ROAD_PREVIEW: () => fmt('Show Road Preview'),
  TAGS: () => fmt('Tags'),
  CUSTOM_ENVIRONMENT: () => fmt('Use custom environment'),
  PREDEFINED_LEVEL: () => fmt('Use predefined level from PAK-file'),
  GO_TO_SCENARIOS_COLLECTION: () => fmt(`Go to scenarios collection`),
}
