import { make } from 'tctx'
import { userManager } from '../userManager/userManager'

const getAuthorizationHeader = async () => {
  // get the authentication token from local storage if it exists
  const user = await userManager.getUser()
  return user?.access_token ? `Bearer ${user?.access_token}` : ''
}

export const getHeaders = async () => {
  const traceId = make().toString()

  return {
    headers: {
      authorization: await getAuthorizationHeader(),
      'x-request-id': traceId,
      traceparent: traceId,
    },
  }
}
