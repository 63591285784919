import { useEffect, useRef } from 'react'

type CallbackFunction = () => void

// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(
  callback: CallbackFunction,
  delayMs: number | null,
  runInitially: boolean = false
) {
  const savedCallback = useRef<CallbackFunction>()

  useEffect(() => {
    if (runInitially) {
      callback()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      savedCallback.current()
    }

    if (delayMs !== null) {
      const id = setInterval(tick, delayMs)
      return () => clearInterval(id)
    }
  }, [delayMs])
}
