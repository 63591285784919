import { useCallback } from 'react'
import { ApiError, CancelablePromise, RestApiError } from '../generated/kpi'

export interface UseOpenApiMutationOptions {
  onSuccess?: () => void
  onError: (errorMessage: string) => void
}

type OpenApiMutation<TQueryParams> = (params: TQueryParams) => void

interface UseOpenApiMutationResult<TQueryParams> {
  mutate: OpenApiMutation<TQueryParams>
}

type ApiFunction<TData, TQueryParams> = (
  queryParams: TQueryParams
) => CancelablePromise<TData>

export const useOpenApiMutation = <TData, TQueryParams extends object>(
  apiFunction: ApiFunction<TData, TQueryParams>,
  options: UseOpenApiMutationOptions
): UseOpenApiMutationResult<TQueryParams> => {
  const mutate = useCallback<OpenApiMutation<TQueryParams>>((params) => {
    apiFunction(params)
      .then(() => {
        options.onSuccess && options.onSuccess()
      })
      .catch((error: ApiError) => {
        const restApiError = error.body as RestApiError

        console.error(restApiError)
        options.onError(restApiError.message ?? '')
      })
  }, [])

  return {
    mutate,
  }
}
