/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const urlParams = {
  suiteId: (testEnvironmentId: string) =>
    `?testEnvironmentId=${testEnvironmentId}`,
  linkDate: (linkDate: number) => `?linkDate=${String(linkDate)}`,
}

const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      testenvironment: {
        collection: `/projects/${projectId}/testenvironments`,
        withId: (testEnvironmentId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${testEnvironmentId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}

export default createUrlFor
