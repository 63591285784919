import {
  ProcessingObject,
  ProcessingObjectLink,
  SelectedProcessingObjectInput,
} from './processingGraphTypes'

/**
 * Returns all (recursive) source nodes of the given nodes. In reverse mode all (recursive) target nodes are returned.
 */
export const getSubGraph = (
  topNodes: Pick<SelectedProcessingObjectInput, 'nodeId'>[],
  links: ProcessingObjectLink[],
  reverse?: boolean
): string[] => {
  if (reverse) {
    links = links.map(
      (link): ProcessingObjectLink => ({
        source: link.target,
        target: link.source,
      })
    )
  }

  const subGraph: string[] = []

  const open: string[] = topNodes.map((node) => node.nodeId)

  while (open.length > 0) {
    const current = open.pop()!
    subGraph.push(current)

    links
      .filter(
        (link) =>
          link.target === current &&
          !open.includes(link.source) &&
          !subGraph.includes(link.source)
      )
      .forEach((link) => open.push(link.source))
  }

  return subGraph
}

/**
 * Returns a flag that indicates if the given processing object is a stream node.
 * @param processingObject The processing object instance.
 * @returns The flag that indicates if the given processing object is a stream node.
 */
export const isStreamNode = (processingObject: ProcessingObject): boolean => {
  return processingObject.type.endsWith('StreamNode')
}

export const getImplicitlyDisabledCachingNodes = (
  selectedNodes: SelectedProcessingObjectInput[],
  links: ProcessingObjectLink[]
) => {
  const disabledCachingNodes = selectedNodes.filter((node) => !node.useCache)

  return getSubGraph(disabledCachingNodes, links, true)
}
