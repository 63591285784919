import { Box } from '@mui/material'
import * as React from 'react'

import { KpiGraphNode, KpiGraphNodeComponentProps } from '../../kpiGraphTypes'
import { KpiGraphMode } from './KpiNodeFactory'
import KpiNodeFrame, { KpiNodeTitle } from './KpiNodeFrame'
import { ShowKpiResultsButton } from './ShowKpiResultsButton'

interface DefaultKpiNodeProps extends KpiGraphNodeComponentProps<KpiGraphNode> {
  mode: KpiGraphMode
}

const DefaultKpiNode: React.FC<DefaultKpiNodeProps> = ({
  engine,
  node,
  model,
  mode,
}) => {
  return (
    <KpiNodeFrame model={model} engine={engine}>
      <KpiNodeTitle
        nodeName={model.nodeName}
        node={node}
        action={
          mode === 'result' ? (
            <Box component="div" flexShrink="1">
              <ShowKpiResultsButton nodeId={model.nodeName} />
            </Box>
          ) : undefined
        }
      />
    </KpiNodeFrame>
  )
}

export const DefaultKpiEditNode: React.FC<
  KpiGraphNodeComponentProps<KpiGraphNode>
> = (props) => {
  return <DefaultKpiNode {...props} mode="edit" />
}

export const DefaultKpiResultNode: React.FC<
  KpiGraphNodeComponentProps<KpiGraphNode>
> = (props) => {
  return <DefaultKpiNode {...props} mode="result" />
}
