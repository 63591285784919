import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  SidebarItemInterpolate,
  TestEnvironmentIcon,
} from '@simphera/shared/ui-simphera'
import { lazy } from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import createBreadcrumbsFor from './utils/createBreadcrumbsFor'
import { PATHS } from './utils/paths'

const TestEnvironmentOverview = lazy(
  () => import('./components/TestEnvironmentListPage')
)
const TestEnvironmentDetailsPage = lazy(
  () => import('./components/TestEnvironmentDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.TESTENVIRONMENT_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="Test Environments"
      to={PATHS.LIST_PAGE}
      icon={<TestEnvironmentIcon />}
    />,
  ],
  routes: [
    {
      path: PATHS.LIST_PAGE,
      element: wrapComponentInProviders(TestEnvironmentOverview),
      breadcrumbs: createBreadcrumbsFor.LIST_PAGE,
    },
    {
      path: PATHS.DETAILS_PAGE,
      element: wrapComponentInProviders(TestEnvironmentDetailsPage),
    },
  ],
}
