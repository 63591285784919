import { getUserInfo } from '@simphera/shared/state'

// note: it's important to scope the state to the user id
function getLocalStorageKey(): string {
  const userInfo = getUserInfo()
  return `${userInfo?.id}.notifications`
}

export function getLastTimeNotificationsWereRead(): number {
  const str = localStorage.getItem(getLocalStorageKey())
  const data = JSON.parse(str || '{}') || {}
  return data.lastReadTimestamp || 0
}

export function persistLastTimeNotificationsWereRead(
  lastReadTimestamp: number
): void {
  const data = {
    lastReadTimestamp,
  }

  localStorage.setItem(getLocalStorageKey(), JSON.stringify(data))
}
