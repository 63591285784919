import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const DrtIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M22 4v10h-2V4H4v10H2V4c0-1.1.9-2 2-2h16a2 2 0 0 1 2 2ZM10 14l5-4-5-4v8Zm-6 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm15 1H7v2h12v2l3-3-3-3v2Z" />
  </SvgIcon>
)

const EXPERIMENT_ICON_PATH =
  'M20.8 20.4 15 12.67V8.5l1.35-1.69a.5.5 0 0 0-.39-.81H8.04a.5.5 0 0 0-.39.81L9 8.5v4.17L3.2 20.4A1 1 0 0 0 4 22h16a1 1 0 0 0 .8-1.6ZM6 20l5-6.67V8h2v5.33L18 20H6Zm4-16c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1Zm2-2c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2Z'
export const ExperimentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d={EXPERIMENT_ICON_PATH} />
  </SvgIcon>
)

export const ExperimentPlusIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <clipPath id="clip">
      <path d="M 18, 18 m -6, 0 a 6,6 0 1,0 12,0 a 6,6 0 1,0 -12,0 M 0 0 L 24 0 L 24 24 L 0 24 Z" />
    </clipPath>

    <path id="experiment" clip-path="url(#clip)" d={EXPERIMENT_ICON_PATH} />
    <path
      id="plus"
      d="M 18 14 H 20 V 17 H 23 V 19 H 20 V 22 H 18 V 19 H 15 V 17 H 18 V 14 Z"
    />
  </SvgIcon>
)

export const ProjectIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M20.08 6.25H11.9l-1.4-1.41a1.98 1.98 0 0 0-1.41-.6H4.08a2 2 0 0 0-2 2v2h20a2 2 0 0 0-2-2zm2 12v-8h-2v8h-16v-8h-2v8c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2z" />
  </SvgIcon>
)

export const ProjectListIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M23 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2h16v-7h2Zm-6-7h-6.17L9.42 3.59A1.98 1.98 0 0 0 8.01 3H3a2 2 0 0 0-2 2v2h18a2 2 0 0 0-2-2Zm2 10V9h-2v6H3V9H1v6c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2Z" />
  </SvgIcon>
)

export const ScbtIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M11 13H7v-2h4v2ZM4 3c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm0 7c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Zm15-9 3-3-3-3v2H7v2h12v2Zm0 3h-3.41l-7 7H7v2h2.41l7-7H19v2l3-3-3-3v2Zm0 7h-4v2h4v2l3-3-3-3v2Z" />
  </SvgIcon>
)

export const ScenarioIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M6 10h5v3H6v-3ZM2 6h5V3H2v3Zm8 0h5V3h-5v3Zm8 0h5V3h-5v3Zm0 14h5v-3h-5v3ZM2 20h5v-3H2v3Zm21-9-3-3v2h-3a.99.99 0 0 0-.89.55L12.39 18h-2.38v2h3c.38 0 .72-.21.89-.55L17.62 12H20v2l3-3Z" />
  </SvgIcon>
)

export const SensorIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="m20.1 18.68-.71-.71a9.52 9.52 0 0 0 0-11.95l.71-.71a10.52 10.52 0 0 1 0 13.36ZM6.03 4.61a9.52 9.52 0 0 1 11.95 0l.71-.71a10.54 10.54 0 0 0-13.37 0l.71.71ZM4.62 17.97a9.52 9.52 0 0 1 0-11.95l-.71-.71a10.52 10.52 0 0 0 0 13.36l.71-.71Zm13.36 1.41a9.52 9.52 0 0 1-11.95 0l-.71.71a10.46 10.46 0 0 0 13.36 0l-.71-.71ZM6.04 7.46l-.71-.71a8.53 8.53 0 0 0 0 10.52l.71-.71a7.52 7.52 0 0 1 0-9.09Zm11.92 9.09.71.71a8.53 8.53 0 0 0 0-10.52l-.71.71a7.52 7.52 0 0 1 0 9.09ZM7.46 6.04a7.52 7.52 0 0 1 9.09 0l.71-.71a8.53 8.53 0 0 0-10.52 0l.71.71Zm9.09 11.92a7.52 7.52 0 0 1-9.09 0l-.71.71a8.5 8.5 0 0 0 10.52 0l-.71-.71ZM7.01 10v4c0 .55.37 1 .83 1h8.33c.46 0 .83-.45.83-1v-4c0-.55-.37-1-.83-1H7.83C7.37 9 7 9.45 7 10Z" />
  </SvgIcon>
)

export const StepbtIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M4 7c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2Zm5 5c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2Zm4 7c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2ZM12 8l3-3-3-3v2H7v2h5v2Zm3 7 3-3-3-3v2h-5v2h5v2Zm7 4-3-3v2h-5v2h5v2l3-3Z" />
  </SvgIcon>
)

export const SystemUnderTestIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M9 20h2v2H9v-2Zm4 2h2v-2h-2v2Zm6-11v2h2v2h-2v2a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-2H3v-2h2v-2H3V9h2V7c0-1.1.9-2 2-2h2V3h2v2h2V3h2v2h2a2 2 0 0 1 2 2v2h2v2h-2Zm-2-4H7v10h10V7Z" />
  </SvgIcon>
)

export const TestEnvironmentIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M23 5v12a2 2 0 0 1-2 2h-7v2h2v2H8v-2h2v-2H3a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3v2H3v12h18V5h-3V3h3a2 2 0 0 1 2 2ZM9 7H8V6h1V4H8V3h1v-.25c0-.41.34-.75.75-.75H10V1h1v1h2V1h1v1h.25c.41 0 .75.34.75.75V3h1v1h-1v2h1v1h-1v.25c0 .41-.34.75-.75.75h-4.5A.76.76 0 0 1 9 7.25V7Zm1 0h4V3h-4v4Zm6 4h-2V9h-4v2H8l4 5 4-5Z" />
  </SvgIcon>
)

export const VehicleIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M5.33 9H3.69l1.39-3.99A1.5 1.5 0 0 1 6.5 4h11c.66 0 1.22.42 1.42 1.01L20.31 9h-1.64L17.5 5.5h-11L5.33 9ZM23 11a1 1 0 0 1-1 1h-1v7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1v-1H6v1a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-7H2c-.55 0-1-.45-1-1s.45-1 1-1h20a1 1 0 0 1 1 1ZM9 13a1 1 0 0 0-1-1H5c-.55 0-1 .45-1 1s.45 1 1 1h3a1 1 0 0 0 1-1Zm11 0a1 1 0 0 0-1-1h-3c-.55 0-1 .45-1 1s.45 1 1 1h3a1 1 0 0 0 1-1Z" />
  </SvgIcon>
)
