import { Breadcrumb } from '@dspace-internal/ui-kit'
import React from 'react'
import { useParams } from 'react-router-dom'
import { interpolatePath } from './interpolatePath'

export interface BreadcrumbInterpolateProps {
  to?: string
  name: string
}

export const BreadcrumbInterpolate: React.FC<BreadcrumbInterpolateProps> = ({
  to = '',
  name,
}) => {
  const params = useParams()
  const interpolatedPath = to ? interpolatePath(to, params) : undefined
  return <Breadcrumb name={name} to={interpolatedPath} />
}
