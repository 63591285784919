// Relevant parts are copied from scbt utils!
export const pathCreate = 'create'

// Origin: apps\scbt\src\utils\createUrlFor.ts
const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      vehicles: {
        collection: `/projects/${projectId}/vehicles`,
        withId: (vehicleId: string) => ({
          detailPage: `/projects/${projectId}/vehicles/${vehicleId}`,
        }),
        createPage: `/projects/${projectId}/vehicles/${pathCreate}`,
      },
      sensors: {
        collection: `/projects/${projectId}/sensors`,
        withId: (sensorId: string) => ({
          detailPage: `/projects/${projectId}/sensors/${sensorId}`,
        }),
        createPage: (sensorType: string) =>
          `/projects/${projectId}/sensors/create/${sensorType}`,
      },
      experiment: {
        collection: `/projects/${projectId}/experiments`,
        withId: (experimentId: string) => ({
          detailPage: `/projects/${projectId}/experiments/${experimentId}`,
        }),
      },
    }),
  },
}
export default createUrlFor
