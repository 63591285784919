import { Tooltip } from '@dspace-internal/ui-kit'
import ShowChartsIcon from '@mui/icons-material/BarChart'
import { IconButton } from '@mui/material'
import React from 'react'

import messages_res from '../../../../resources/messages.res'
import { useProcessingObjectTabs } from '../../ProcessingObjectSelectionProvider'

export interface ShowKpiResultsButtonProps {
  nodeId: string
}

export const ShowKpiResultsButton: React.FC<ShowKpiResultsButtonProps> = ({
  nodeId,
}) => {
  const { openTab } = useProcessingObjectTabs()

  return (
    <IconButton
      style={{
        margin: '0px 4px 0px 0px',
        width: '24px',
        height: '24px',
        padding: 4,
      }}
      onClick={() => openTab(nodeId)}
    >
      <Tooltip
        message={messages_res.PROCESSINGGRAPH_SHOW_RESULTS()}
        placement="left"
        arrow
      >
        <div>
          <ShowChartsIcon />
        </div>
      </Tooltip>
    </IconButton>
  )
}
