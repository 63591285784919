import { BreadcrumbInterpolate } from '@simphera/shared/ui-simphera'
import { captions_res } from '../resources'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  VEHICLE_LIST: [<BreadcrumbInterpolate name={captions_res.VEHICLES()} />],
  VEHICLE_DETAIL: (vehicleName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.VEHICLES()}
      to={PATHS.VEHICLE_LIST}
    />,
    <BreadcrumbInterpolate name={vehicleName} />,
  ],
  SENSOR_LIST: [<BreadcrumbInterpolate name={captions_res.SENSORS()} />],
  SENSOR_DETAILS: (sensorName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.SENSORS()}
      to={PATHS.SENSOR_LIST}
    />,
    <BreadcrumbInterpolate name={sensorName} />,
  ],
  SENSOR_CREATE: (sensorName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.SENSORS()}
      to={PATHS.SENSOR_LIST}
    />,
    <BreadcrumbInterpolate name={sensorName} />,
  ],
}

export default createBreadcrumbsFor
