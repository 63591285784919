import createUrlFor from './createUrlFor'

const project = createUrlFor.project.withId(':projectId')

export const PATHS = {
  VEHICLE_LIST: project.vehicles.collection,
  VEHICLE_DETAIL: project.vehicles.withId(':vehicleId').detailPage,
  SENSOR_LIST: project.sensors.collection,
  SENSOR_DETAILS: project.sensors.withId(':sensorId').detailPage,
  SENSOR_CREATE: project.sensors.createPage(':sensorType'),
}
