import { DefaultLinkModel } from '@projectstorm/react-diagrams'

export class CurvedLinkModel extends DefaultLinkModel {
  constructor() {
    super({
      type: 'curved',
      width: 2,
    })
  }
}
