import React from 'react'

let count = 0

export const withReactKeys = (arr: Array<React.ReactElement>) => {
  return (
    <>
      {arr.map((item) => React.cloneElement(item, { key: `item-${count++}` }))}
    </>
  )
}
