import { colors, theme } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

export const RunDetailsGridLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;

  & > div:last-child {
    border-left: 1px solid ${colors.gray_40};
  }

  ${theme.breakpoints.down('xl')} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;

    & > div:last-child {
      margin-top: 12px;
      padding-top: 24px;
      border-left: 0px;
      border-top: 1px solid ${colors.gray_40};
    }
  }
`
