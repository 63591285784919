import { Tooltip } from '@dspace-internal/ui-kit'
import React, { useRef, useState } from 'react'

import { FixedWidthTextDiv } from './TextWithOverflow.styles'

export interface TextWithOverflowProps {
  children: string
  style?: React.CSSProperties
}

/** Text that cuts off any overflow with an ellipsis. When overflow is cut off the whole text is still displayed in a tooltip. */
const TextWithOverflow: React.FC<TextWithOverflowProps> = ({
  children,
  style,
  ...restProps
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const textRef = useRef<HTMLDivElement | null>(null)

  const handleOpen = () => {
    if (
      textRef.current &&
      textRef.current.clientWidth < textRef.current.scrollWidth
    ) {
      setShowTooltip(true)
    }
  }

  const handleClose = () => {
    setShowTooltip(false)
  }

  return (
    <Tooltip
      message={children}
      arrow
      open={showTooltip}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <FixedWidthTextDiv ref={textRef} {...restProps} style={style}>
        {children}
      </FixedWidthTextDiv>
    </Tooltip>
  )
}

export default TextWithOverflow
