import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../utils/messages.res'
import { DESCRIPTION_CHAR_LIMIT } from './constants'
import { ApiKeyFormFieldProps } from './types'

export const DescriptionInput: React.FC<ApiKeyFormFieldProps> = ({
  isReadOnly = false,
}) => {
  const { control, formState } = useFormContext<{ description: string }>()
  const error = formState.errors.description

  return (
    <Controller
      control={control}
      name="description"
      defaultValue={formState.defaultValues?.description}
      rules={{
        required: messages.INPUT_CANNOT_BE_EMPTY('description'),
        validate: {
          validLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(DESCRIPTION_CHAR_LIMIT),
            DESCRIPTION_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={Boolean(error)}
          label="Description"
          autoComplete="off"
          size="small"
          fullWidth
          multiline
          helperText={error?.message}
          disabled={isReadOnly}
          required
        />
      )}
    />
  )
}
