import { Breadcrumb, Route } from '@dspace-internal/ui-kit'
import { Navigate } from 'react-router-dom'
import { ApiKeysCreatePage } from '../ApiKeysPage/ApiKeysCreatePage'
import { ApiKeysPage } from '../ApiKeysPage/ApiKeysPage'
import { Authentication } from '../Authentication'
import { NotFoundPage } from '../NotFoundPage'
import { getRoutes } from './navigationConfigs'

export const routes: Array<Route> = [
  {
    path: '/',
    element: <Navigate replace to="/projects" />,
    breadcrumbs: [],
  },
  {
    path: '/authentication',
    element: <Authentication />,
    breadcrumbs: [],
  },
  {
    path: '/api-keys',
    element: <ApiKeysPage />,
    breadcrumbs: [<Breadcrumb name="API keys" to="/api-keys" />],
  },
  {
    path: '/api-keys/new',
    element: <ApiKeysCreatePage />,
    breadcrumbs: [
      <Breadcrumb name="API keys" to="/api-keys" />,
      <Breadcrumb name="New" />,
    ],
  },
  ...getRoutes(),
  {
    path: '*',
    element: <NotFoundPage />,
  },
]
