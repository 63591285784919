import { colors } from '@dspace-internal/ui-kit'
import { ResultFigures } from '../../types'

export const resultStateColors: Record<keyof ResultFigures, string> = {
  pending: colors.chart_1,
  processing: colors.chart_2,
  blocked: colors.chart_7,
  passed: colors.chart_4,
  failed: colors.chart_6,
  error: colors.chart_8,
  evaluating: colors.chart_10,
  aborting: colors.chart_14,
  aborted: colors.chart_13,
  noVerdict: colors.chart_13,
} as const
