/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const urlParams = {
  suiteId: (suiteId: string) => `?suiteId=${suiteId}`,
  linkDate: (linkDate: number) => `?linkDate=${String(linkDate)}`,
}

const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      dataReplay: `/projects/${projectId}/datareplay`,
      startPage: `/projects/${projectId}/datareplay/suites`,
      suite: {
        collection: `/projects/${projectId}/datareplay/suites`,
        new: `/projects/${projectId}/datareplay/suites/new`,
        withId: (suiteId: string) => ({
          detailPage: `/projects/${projectId}/datareplay/suites/${suiteId}`,
        }),
      },
      testCase: {
        new: `/projects/${projectId}/datareplay/testcases/new`,
        withId: (testCaseId: string) => ({
          detailPage: `/projects/${projectId}/datareplay/testcases/${testCaseId}`,
        }),
      },
      run: {
        collection: `/projects/${projectId}/datareplay/runs`,
        withId: (runId: string) => ({
          detailPage: `/projects/${projectId}/datareplay/runs/${runId}`,
          testCase: {
            withId: (testCaseId: string) => ({
              detailPage: `/projects/${projectId}/datareplay/runs/${runId}/testcases/${testCaseId}`,
              result: {
                withId: (resultId: string) => ({
                  detailPage: `/projects/${projectId}/datareplay/runs/${runId}/testcases/${testCaseId}/results/${resultId}`,
                }),
              },
            }),
          },
        }),
      },
      result: {
        withId: (resultId: string) => ({
          detailPage: `/projects/${projectId}/datareplay/results/${resultId}`,
        }),
      },
      sut: {
        withId: (sutId: string) => ({
          detailPage: `/projects/${projectId}/suts/${sutId}`,
        }),
      },
      testEnvironment: {
        withId: (testEnvironmentId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${testEnvironmentId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}

export default createUrlFor
