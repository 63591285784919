import { GlobalConfig } from '@simphera/shared/appconfig'
import { UserManager, WebStorageStateStore } from 'oidc-client-ts'

export const userManager = new UserManager({
  //https://devforum.okta.com/t/why-no-userinfo-endpoint-in-well-known-auth-server-metadata/6816
  metadataUrl: `${GlobalConfig.appConfig.OIDC_ISSUER_URL}/.well-known/openid-configuration`,
  authority: GlobalConfig.appConfig.OIDC_ISSUER_URL,
  client_id: GlobalConfig.appConfig.OIDC_CLIENT_ID,

  redirect_uri: GlobalConfig.appConfig.APP_URL + 'authentication',
  post_logout_redirect_uri:
    GlobalConfig.appConfig.OIDC_POST_LOGOUT_REDIRECT_URI,
  response_type: 'code',
  scope: GlobalConfig.appConfig.OIDC_SCOPE,

  silent_redirect_uri: GlobalConfig.appConfig.APP_URL,
  automaticSilentRenew: false, // custom silent renew implemented with addAccessTokenExpiring event
  accessTokenExpiringNotificationTimeInSeconds: 30, // 30 seconds before expiration of old token trigger an event to try to get new one
  filterProtocolClaims: true,
  loadUserInfo: false,
  // workaround for unnecessary requests after initial login resulting in Frame Window Timed Out error: https://github.com/IdentityModel/oidc-client-js/issues/860
  monitorSession: false,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
})
