import { ApolloProvider } from '@apollo/client'
import {
  UiKitProvider,
  attachUiKitFeaturesToRoutes,
} from '@dspace-internal/ui-kit'
import { apolloAuthenticationClient, useUserInfo } from '@simphera/shared/state'
import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { AppFrame } from './AppFrame'
import { DelayedSpinner } from './DelayedSpinner'
import { ErrorPage } from './ErrorPage'
import { routes } from './routes'

export const App: React.FC = () => {
  const routesWithUiKitFeatures = attachUiKitFeaturesToRoutes(routes, {
    frameElement: AppFrame,
    errorElement: (
      <AppFrame>
        <ErrorPage />
      </AppFrame>
    ),
  })
  const router = createBrowserRouter(routesWithUiKitFeatures)
  const userInfo = useUserInfo()

  return (
    <UiKitProvider>
      {userInfo ? (
        <ApolloProvider client={apolloAuthenticationClient}>
          <RouterProvider router={router} />
        </ApolloProvider>
      ) : (
        <DelayedSpinner />
      )}
    </UiKitProvider>
  )
}
