import { SidebarSubGroup, SidebarSubGroupItem } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { StepbtIcon } from '@simphera/shared/ui-simphera'
import React from 'react'
import { useParams } from 'react-router-dom'
import { wrapComponentInProviders } from './client/wrapper'
import createBreadcrumbsFor from './shared/utils/createBreadcrumbsFor'
import createUrlFor from './shared/utils/createUrlFor'
import { PATHS } from './shared/utils/paths'

const NewSuitePage = React.lazy(
  () => import('./components/SuiteDetailsPage/NewSuitePage')
)
const SuiteListPage = React.lazy(
  () => import('./components/SuiteListPage/SuiteListPage')
)
const SuiteDetailsPage = React.lazy(
  () => import('./components/SuiteDetailsPage/SuiteDetailsPage')
)
const NewLogicalTestCasePage = React.lazy(
  () => import('./components/LogicalTestCasePage/NewLogicalTestCasePage')
)
const TestCaseDetailsPage = React.lazy(
  () => import('./components/TestCaseDetailsPage/TestCaseDetailsPage')
)
const LogicalTestCasePage = React.lazy(
  () => import('./components/LogicalTestCasePage/LogicalTestCasePage')
)
const RunListPage = React.lazy(
  () => import('./components/RunListPage/RunListPage')
)
const RunDetailsPage = React.lazy(
  () => import('./components/RunDetailsPage/RunDetailsPage')
)
const LibraryListPage = React.lazy(
  () => import('./components/LibraryListPage/LibraryListPage')
)
const TestCaseResultsPage = React.lazy(
  () => import('./components/TestCaseResultsPage/TestCaseResultsPage')
)

const SidebarEntries = () => {
  const { projectId } = useParams()

  return (
    <SidebarSubGroup name="Step-based" icon={<StepbtIcon />}>
      <SidebarSubGroupItem
        name="Suites"
        to={createUrlFor.project.withId(projectId!).stepbt.suite.collection}
      />
      <SidebarSubGroupItem
        name="Runs"
        to={createUrlFor.project.withId(projectId!).stepbt.run.collection}
      />
      <SidebarSubGroupItem
        name="Libraries"
        to={createUrlFor.project.withId(projectId!).stepbt.library.collection}
      />
    </SidebarSubGroup>
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.STEPBT_APP_ENABLED,
  sidebarEntries: [<SidebarEntries />],
  projectItemEntry: {
    url: (projectId: string) =>
      createUrlFor.project.withId(projectId).stepbt.suite.collection,
    icon: <StepbtIcon />,
    label: 'Step-based',
  },
  routes: [
    {
      path: PATHS.SUITES_COLLECTION,
      element: wrapComponentInProviders(SuiteListPage),
      breadcrumbs: createBreadcrumbsFor.SUITES_LIST,
    },
    {
      // important: 'new suite' route must be defined before 'suite detail' route
      path: PATHS.SUITES_NEW,
      element: wrapComponentInProviders(NewSuitePage),
      breadcrumbs: createBreadcrumbsFor.SUITES_NEW,
    },
    {
      path: PATHS.SUITES_DETAIL,
      element: wrapComponentInProviders(SuiteDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SUITES_LIST,
    },
    {
      path: PATHS.TESTCASES_NEW,
      element: wrapComponentInProviders(NewLogicalTestCasePage),
      breadcrumbs: createBreadcrumbsFor.TESTCASES_NEW,
    },
    {
      path: PATHS.LOGICALTESTCASE_DETAIL,
      element: wrapComponentInProviders(LogicalTestCasePage),
      breadcrumbs: createBreadcrumbsFor.TESTCASES_LIST,
    },
    {
      path: PATHS.TESTCASES_DETAIL,
      element: wrapComponentInProviders(TestCaseDetailsPage),
      breadcrumbs: createBreadcrumbsFor.TESTCASES_LIST,
    },
    {
      path: PATHS.TESTCASES_RESULTS,
      element: wrapComponentInProviders(TestCaseResultsPage),
      breadcrumbs: createBreadcrumbsFor.TESTCASE_RESULTS,
    },
    {
      path: PATHS.RUNS_COLLECTION,
      element: wrapComponentInProviders(RunListPage),
      breadcrumbs: createBreadcrumbsFor.RUN_LIST,
    },
    {
      path: PATHS.RUN_DETAIL,
      element: wrapComponentInProviders(RunDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SUITES_LIST,
    },
    {
      path: PATHS.LIBRARIES_COLLECTION,
      element: wrapComponentInProviders(LibraryListPage),
      breadcrumbs: createBreadcrumbsFor.LIBRARIES_LIST,
    },
  ],
}
