import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import { CSSProperties } from 'react'

/** Displays a horizontal separator line in the layout. */
export const HorizontalSeparator = styled.div<{ $tileName: string }>`
  grid-area: ${({ $tileName }) => $tileName};

  width: calc(100% - 16px);
  height: 0px;
  margin: 0px 8px;

  border-bottom: 1px solid ${colors.gray_40};
`

/** Displays a vertical separator line in the layout. */
export const VerticalSeparator = styled.div<{ $tileName: string }>`
  grid-area: ${({ $tileName }) => $tileName};

  width: 0px;
  height: calc(100% - 16px);
  margin: 8px 0px;

  border-right: 1px solid ${colors.gray_40};
`

export interface StyledTileGridLayoutProps {
  $rows: string[]
  $gridTemplateColumns: CSSProperties['gridTemplateColumns']
  $gridTemplateRows: CSSProperties['gridTemplateRows']
}

/** A grid that layouts tiles. */
export const StyledTileGridLayout = styled.div<StyledTileGridLayoutProps>`
  display: grid;

  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
  grid-template-rows: ${({ $gridTemplateRows }) => $gridTemplateRows};

  grid-template-areas: ${({ $rows }) =>
    $rows.map((row) => "'" + row + "'").join(' ')};
`

export interface StyledGridTileProps {
  $tileName: string
  $noBorder: boolean
  $isVerticalLayout: boolean
}

/** A tile in the tile grid layout. */
export const StyledGridTile = styled.div<StyledGridTileProps>`
  grid-area: ${({ $tileName }) => $tileName};

  padding: ${({ $isVerticalLayout }) =>
    $isVerticalLayout ? '16px 16px' : '16px 16px'};
  margin: ${({ $isVerticalLayout }) =>
    $isVerticalLayout ? '0px 16px' : '16px 0px'};

  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ $noBorder, $isVerticalLayout }) => {
    const border = `1px solid ${colors.gray_40};`

    return $noBorder
      ? ''
      : $isVerticalLayout
      ? 'border-top: ' + border
      : 'border-left: ' + border
  }}
`
