import { TextField } from '@dspace-internal/ui-kit'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../../utils/messages.res'
import { NAME_CHAR_LIMIT } from './constants'
import { IReadOnly } from './types'

export const NameInput: React.FC<IReadOnly> = ({ isReadOnly = false }) => {
  const { control, formState } = useFormContext<Required<{ name: string }>>()
  const error = formState.errors.name

  return (
    <Controller
      control={control}
      name="name"
      defaultValue=""
      rules={{
        required: messages.INPUT_CANNOT_BE_EMPTY('name'),
        validate: {
          validLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(NAME_CHAR_LIMIT),
            NAME_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          name={field.name}
          error={Boolean(error)}
          size="small"
          label="Name"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          required
          autoComplete="off"
        />
      )}
    />
  )
}
