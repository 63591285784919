import { createProviderHook } from '@simphera/shared/ui-simphera'
import React from 'react'
import { KpiGraph, KpiGraphNode } from '../kpiGraphTypes'

export interface KpiGraphContextData {
  kpiGraph: KpiGraph
  getKpiNode: (nodeId: string) => KpiGraphNode
}

const KpiGraphContext = React.createContext<KpiGraphContextData | undefined>(
  undefined
)

export interface KpiGraphProviderProps {
  kpiGraph: KpiGraph
}

export const KpiGraphProvider: React.FC<
  React.PropsWithChildren<KpiGraphProviderProps>
> = ({ kpiGraph, children }) => {
  const getKpiNode = (nodeName: string) => {
    return kpiGraph.nodes[nodeName]!
  }

  return (
    <KpiGraphContext.Provider value={{ kpiGraph, getKpiNode }}>
      {children}
    </KpiGraphContext.Provider>
  )
}

export const useKpiGraph = createProviderHook(
  KpiGraphContext,
  'KpiGraphProvider',
  'useKpiGraph'
)
