import { Operation } from '@simphera/shared/rest-clients'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { NotificationDialog } from './NotificationDialog'
import { getNotification } from './api'
import { NOTIFICATION_ID_SEARCH_PARAM } from './constants'

export const NotificationDialogRenderer: React.FC = () => {
  const [searchParameters] = useSearchParams()
  const [notification, setNotification] = React.useState<Operation | null>(null)

  React.useEffect(() => {
    const notificationId = searchParameters.get(NOTIFICATION_ID_SEARCH_PARAM)

    if (notificationId) {
      getNotification(notificationId).then(setNotification)
    } else {
      setNotification(null)
    }
  }, [searchParameters])

  return notification ? (
    <NotificationDialog notification={notification} />
  ) : null
}
