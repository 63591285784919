import { mdiCloseOctagonOutline } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'

const AbortedIcon: React.FC = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d={mdiCloseOctagonOutline}></path>
    </SvgIcon>
  )
}

export default AbortedIcon
