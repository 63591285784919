import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

export const PortBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const PortColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export interface PortWidgetCircleProps {
  alignment: 'left' | 'right'
}

export const PortWidgetCircle = styled.div<PortWidgetCircleProps>`
  margin-left: ${({ alignment }) => (alignment === 'left' ? -5 : 0)}px;
  margin-right: ${({ alignment }) => (alignment === 'right' ? -5 : 0)}px;
  background-color: ${colors.gray_80};
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border: solid ${colors.white} 1px;
`
