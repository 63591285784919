import { Breadcrumb } from '@dspace-internal/ui-kit'
import {
  BreadcrumbInterpolate,
  interpolatePath,
} from '@simphera/shared/ui-simphera'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  SUITES_LIST: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" />,
  ],
  SUITES_NEW: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="New Suite" />,
  ],
  SUITES_DETAIL: (suiteName: string) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name={suiteName} />,
  ],
  TESTCASES_LIST: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Test Cases" />,
  ],
  TESTCASES_NEW: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Test Cases" to={PATHS.TESTCASES_COLLECTION} />,
    <BreadcrumbInterpolate name="New Test Case" />,
  ],

  TESTCASES_DETAIL: (libraryName: string, testCaseName: string) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Libraries" to={PATHS.LIBRARIES_COLLECTION} />,
    <BreadcrumbInterpolate
      name={libraryName}
      to={PATHS.LIBRARIES_COLLECTION}
    />,
    <BreadcrumbInterpolate name={testCaseName} />,
  ],
  LOGICALTESTCASES_DETAIL: (
    projectId: string,
    suiteId: string,
    suiteName: string,
    testCaseName: string
  ) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <Breadcrumb
      name={suiteName}
      to={interpolatePath(PATHS.SUITES_DETAIL, { projectId, suiteId })}
    />,
    <BreadcrumbInterpolate name={testCaseName} />,
  ],
  TESTCASE_RESULTS: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.TESTCASES_COLLECTION} />,
    <BreadcrumbInterpolate name="Test Case Results" />,
  ],
  LIBRARIES_LIST: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Libraries" />,
  ],
  LOGICALTESTCASES_NEW: (
    projectId: string,
    suiteId: string,
    suiteName: string
  ) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <Breadcrumb
      to={interpolatePath(PATHS.SUITES_DETAIL, {
        projectId,
        suiteId,
      })}
      name={suiteName}
    />,
    <BreadcrumbInterpolate name="New Logical Test Case" />,
  ],
  RUN_LIST: [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.RUNS_COLLECTION} />,
    <BreadcrumbInterpolate name="Runs" />,
  ],
  RUN_DETAIL: (
    projectId: string,
    suiteId: string,
    suiteName: string,
    runName: string
  ) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <Breadcrumb
      name={suiteName}
      to={interpolatePath(PATHS.SUITES_DETAIL, { projectId, suiteId })}
    />,
    <BreadcrumbInterpolate name={runName} />,
  ],
  TESTCASE_RESULT_DETAIL: (
    projectId: string,
    suiteId: string,
    suiteName: string,
    runId: string,
    runName: string,
    resultName: string
  ) => [
    <BreadcrumbInterpolate name="Step-based" to={PATHS.SUITES_COLLECTION} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITES_COLLECTION} />,
    <Breadcrumb
      name={suiteName}
      to={interpolatePath(PATHS.SUITES_DETAIL, { projectId, suiteId })}
    />,
    <Breadcrumb
      name={runName}
      to={interpolatePath(PATHS.RUN_DETAIL, { projectId, runId })}
    />,
    <BreadcrumbInterpolate name={resultName} />,
  ],
}

export default createBreadcrumbsFor
