import { Paper } from '@dspace-internal/ui-kit'
import { SimpheraPageHeader } from '@simphera/shared/ui-simphera'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
  return (
    <>
      <SimpheraPageHeader title="Page Not Found" />
      <Paper>
        Sorry! We could not find the page you were looking for.
        <br />
        Click <Link to="/">here</Link> to go back to the start.
      </Paper>
    </>
  )
}
