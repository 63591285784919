import { NodeModel, NodeModelGenerics } from '@projectstorm/react-diagrams'

import { ProcessingObject } from '../../processingGraphTypes'

export interface InputNodeModelGenerics {}

// this can be further extended for more complicated node types
export class InputNodeModel extends NodeModel<
  NodeModelGenerics & InputNodeModelGenerics
> {
  processingObject: ProcessingObject

  constructor(processingObject: ProcessingObject) {
    super({
      type: 'input',
    })

    this.processingObject = processingObject
  }
}
