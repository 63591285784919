import IntlMessageFormat from 'intl-messageformat'

export const fmt = (
  message: string,
  ...rest: Record<string, unknown>[]
): string => {
  const formatter = new IntlMessageFormat(message, 'en-US')
  const formatted = formatter.format(...rest) as string
  return formatted
}
