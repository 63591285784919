import { BreadcrumbInterpolate } from '@simphera/shared/ui-simphera'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  GIT_WORKSPACE_NEW: [<BreadcrumbInterpolate name="New Git Workspace" />],
  GIT_WORKSPACE_DETAIL: (gitWorkspaceName: string) => [
    <BreadcrumbInterpolate name={gitWorkspaceName} />,
  ],
  GIT_WORKSPACE_EDIT: (gitWorkspaceName: string) => [
    <BreadcrumbInterpolate
      name={gitWorkspaceName}
      to={PATHS.GIT_WORKSPACE_DETAIL}
    />,
    <BreadcrumbInterpolate name="Edit" />,
  ],
  PROJECT_EDIT: [<BreadcrumbInterpolate name="Edit" />],
  PROJECT_NEW: [<BreadcrumbInterpolate name="New Project" />],
}

export default createBreadcrumbsFor
