import { UserIndicator as UiKitUserIndicator } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import {
  mdiBookOpenPageVariantOutline,
  mdiInformationOutline,
  mdiLogoutVariant,
  mdiRocketLaunchOutline,
} from '@mdi/js'
import Icon from '@mdi/react'
import KeyIcon from '@mui/icons-material/Key'
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { useLogout, useUserInfo } from '@simphera/shared/state'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AboutDialog from '../AboutDialog/AboutDialog'
import { Notifications } from './Notifications/Notifications'

const MenuLink = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
`

export const UserIndicator = () => {
  const userInfo = useUserInfo()
  const handleLogout = useLogout()
  const navigate = useNavigate()

  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const openDialog = () => setIsDialogOpen(true)
  const closeDialog = () => setIsDialogOpen(false)
  const navigateToApiKeysPage = () => navigate('/api-keys')

  return (
    <Box component="div" display="flex" gap={2}>
      {/* For now notifications are only created for simphera as code features. E.g. for a project import.*/}
      {GlobalConfig.featureFlags.simpheraAsCode && <Notifications />}
      <UiKitUserIndicator name={userInfo.name}>
        <MenuItem>
          <MenuLink href="/help/start/" target="_blank" rel="noreferrer">
            <ListItemIcon>
              <Icon path={mdiRocketLaunchOutline} size={0.8} />
            </ListItemIcon>
            <ListItemText>Getting Started</ListItemText>
          </MenuLink>
        </MenuItem>
        <MenuItem>
          <MenuLink href="/help/" target="_blank" rel="noreferrer">
            <ListItemIcon>
              <Icon path={mdiBookOpenPageVariantOutline} size={0.8} />
            </ListItemIcon>
            <ListItemText>dSPACE Help</ListItemText>
          </MenuLink>
        </MenuItem>
        <MenuItem onClick={openDialog}>
          <ListItemIcon>
            <Icon path={mdiInformationOutline} size={0.8} />
          </ListItemIcon>
          <ListItemText>About</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={navigateToApiKeysPage}>
          <ListItemIcon>
            <KeyIcon fontSize="inherit" />
          </ListItemIcon>
          <ListItemText>API keys</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Icon path={mdiLogoutVariant} size={0.8} />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </UiKitUserIndicator>
      <AboutDialog isOpen={isDialogOpen} onCloseDialog={closeDialog} />
    </Box>
  )
}
