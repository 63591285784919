import { colors } from '@dspace-internal/ui-kit'
import { SvgIcon, SvgIconProps } from '@mui/material'

export const SvgWarnIconOutline = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      height="24"
      viewBox="0 0 24 24"
      width="24"
      htmlColor={colors.warning_60}
      {...props}
    >
      <path d="M0 0h24v24H0V0z" id="path2" fill="none" />
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2z" id="path4" />
      <path
        style={{ fill: colors.white, fillOpacity: 1, stroke: 'none' }}
        d="m 12,5.99 7.52999,13.010003 H 4.4700092 Z"
        id="path832"
      />
      <path d="M 11.000025,9.999975 H 13 V 14 h -1.999975 z" id="path834" />
      <path
        d="M 11.000025,15.999975 H 13 v 2.000012 h -1.999975 z"
        id="path836"
      />
    </SvgIcon>
  )
}

export const SvgWarnIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      height="24"
      viewBox="0 0 24 24"
      width="24"
      htmlColor={colors.warning_60}
      {...props}
    >
      <path d="M 12 2 L 1 21 L 23 21 L 12 2 Z" id="path4"></path>
      <path
        d="M 11.000025,9.999975 H 13 V 14 h -1.999975 z"
        id="path834"
        style={{ fill: '#ffffff', fillOpacity: 1, stroke: 'none' }}
      ></path>
      <path
        d="M 11.000025,15.999975 H 13 v 2.000012 h -1.999975 z"
        id="path836"
        style={{ fill: '#ffffff', fillOpacity: 1, stroke: 'none' }}
      ></path>
    </SvgIcon>
  )
}
