import { mdiMinusCircleOutline } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'

const NoResultIcon: React.FC = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d={mdiMinusCircleOutline} transform="rotate(45,12,12)"></path>
    </SvgIcon>
  )
}

export default NoResultIcon
