import { Button } from '@dspace-internal/ui-kit'
import { Box, styled } from '@mui/material'
import { RgbColorPicker } from 'react-colorful'

export const StyledColorPicker = styled(RgbColorPicker)({
  '.react-colorful__interactive .react-colorful__pointer': {
    width: '15px !important',
    height: '15px !important',
  },
  '.react-colorful__hue .react-colorful__pointer': {
    width: '4px !important',
    height: '26px !important',
    borderRadius: '2px !important',
  },
  width: '300px !important',
  padding: '10px 10px 3px',
  '& > div': {
    borderRadius: 0,
  },
})

export const ButtonBox = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  margin: 2px 3px;
`

export const ColorButton = styled(Button)<{
  cssColor: string
}>(({ cssColor }) => ({
  minWidth: 'auto !important',
  width: '22px',
  height: '22px',
  borderRadius: '0px',
  float: 'left',
  padding: '0 !important',
  cursor: 'pointer',
  border: '2px solid lightgray',
  boxShadow: 'none !important',
  backgroundColor: `${cssColor} !important`,
  '&:hover': {
    backgroundColor: `${cssColor} !important`,
    boxShadow: 'none !important',
  },
  '&:active': {
    backgroundColor: `${cssColor} !important`,
    boxShadow: 'none !important',
  },
  '&:focus': {
    backgroundColor: `${cssColor} !important`,
    boxShadow: 'none !important',
  },
}))
