import { colors, Tooltip } from '@dspace-internal/ui-kit'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { convertUTCToLocal } from '../../../utils'
import { RelativeTime } from '../../RelativeTime'

interface DateTimeAgoProps {
  createdOn: number | string | null | undefined
  modifiedOn: number | string | null | undefined
}

export const DateTimeAgo: React.FC<DateTimeAgoProps> = ({
  createdOn,
  modifiedOn,
}) => {
  let shownValue
  if (modifiedOn) {
    shownValue = modifiedOn
  } else if (createdOn) {
    shownValue = createdOn
  } else {
    return <>-</>
  }

  const displayedDate =
    typeof shownValue === 'number'
      ? new Date(shownValue * 1000)
      : new Date(shownValue)

  return (
    <span
      data-testid="changeInfoTooltip"
      style={{
        textDecoration: 'underline',
        textDecorationStyle: 'dotted',
        textUnderlineOffset: '2px',
        textDecorationThickness: '1px',
      }}
    >
      <RelativeTime date={displayedDate} preventTooltip />
    </span>
  )
}

interface ChangeInformationProps {
  createdOn: number | string | null | undefined
  createdById: string
  createdByName: string
  modifiedOn: number | string | null | undefined
  modifiedById: string
  modifiedByName: string
  unstyled?: boolean
}

/* Shows an error page based on input properties.*/
export const ChangeInformation: React.FC<ChangeInformationProps> = ({
  createdOn,
  createdById,
  createdByName,
  modifiedOn,
  modifiedById,
  modifiedByName,
  unstyled = false,
}) => {
  const createdBy = createdByName || createdById
  const modifiedBy = modifiedByName || modifiedById

  // showModifiedInformation indicates whether modified or created information are shown
  // in the change information component.
  // true -> show modified information, false -> show created information
  let showModifiedInformation = false
  if (modifiedOn || modifiedByName || modifiedById) {
    showModifiedInformation = true
  } else if (createdOn || createdByName || createdById) {
    showModifiedInformation = false
  } else {
    return <></>
  }

  // show modified information if it's younger than created
  // or if its triggered by different user (use case: system user)
  if (createdOn && modifiedOn) {
    showModifiedInformation = modifiedOn > createdOn || modifiedBy !== createdBy
    if (modifiedBy !== createdBy) {
      showModifiedInformation = true
    }
  }

  const createdDate = createdOn ? convertUTCToLocal(createdOn) : '-'
  const modifiedDate = modifiedOn ? convertUTCToLocal(modifiedOn) : '-'

  const tooltipMessage = () => {
    return (
      <>
        {(modifiedOn !== createdOn || modifiedBy !== createdBy) && (
          <>
            <Box component="div">Modified: {modifiedDate}</Box>
            <Box
              component="div"
              sx={{ textIndent: '10px', marginBottom: '5px' }}
            >
              {modifiedBy ? 'by ' + modifiedBy : ''}
            </Box>
          </>
        )}
        <Box component="div">Created: {createdDate} </Box>
        <Box component="div" sx={{ textIndent: '10px' }}>
          {createdBy ? 'by ' + createdBy : ''}
        </Box>
      </>
    )
  }

  return (
    <Box
      component="div"
      sx={
        unstyled
          ? {}
          : {
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0px 24px 16px 24px',
              color: colors.gray_70,
            }
      }
    >
      <Box component="div">
        <Tooltip message={tooltipMessage()} placement="bottom">
          {showModifiedInformation ? (
            <Typography>
              Modified{' '}
              {modifiedOn && (
                <DateTimeAgo modifiedOn={modifiedOn} createdOn={createdOn} />
              )}{' '}
              {modifiedBy && <>by {modifiedBy}</>}
            </Typography>
          ) : (
            <Typography>
              Created{' '}
              {createdOn && (
                <DateTimeAgo modifiedOn={modifiedOn} createdOn={createdOn} />
              )}{' '}
              {createdBy && <>by {createdBy}</>}
            </Typography>
          )}
        </Tooltip>
      </Box>
    </Box>
  )
}

export default ChangeInformation
