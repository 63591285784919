import { Button } from '@dspace-internal/ui-kit'
import SaveIcon from '@mui/icons-material/Save'
import { Grid } from '@mui/material'
import { KpiDto } from '@simphera/shared/rest-clients'
import { useProjectInfo } from '@simphera/shared/state'
import {
  ChangeInformation,
  CustomPageHeader,
  handleSubmitOnlyOfChangedFields,
  ReadonlyChipWithTooltip,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { captions_res } from '../../../resources/captions.res'
import { labels_res } from '../../../resources/labels.res'
import { KpiGraphPaper } from './KpiGraphPaper'
import { KpiPropertiesPaper } from './KpiPropertiesPaper'

export type OnSaveKpiCallback = (changedProperties: Partial<KpiDto>) => void

export interface KpiFormProps {
  initialValue: KpiDto
  onSave: OnSaveKpiCallback
}

export const KpiForm: React.FC<KpiFormProps> = ({ initialValue, onSave }) => {
  const projectInfo = useProjectInfo()
  const isReadonly = !projectInfo?.projectPermissions.canWrite

  const formMethods = useForm<KpiDto>({
    defaultValues: initialValue,
    mode: 'onChange',
  })

  const {
    formState: { isDirty, isValid, dirtyFields },
    handleSubmit,
    reset,
  } = formMethods

  React.useEffect(() => {
    reset(initialValue)
  }, [reset, initialValue])

  return (
    <FormProvider {...formMethods}>
      <form style={{ margin: 0 }}>
        <CustomPageHeader
          id={'PREPARE_KPIS_EDIT_KPI'}
          placeholder={[
            { key: 'projectId', value: initialValue.projectId },
            { key: 'kpiId', value: initialValue.id },
          ]}
          title={initialValue.name}
          titleEndAdornment={isReadonly && <ReadonlyChipWithTooltip />}
          subtitle={isDirty ? captions_res.KPI_EDITED() : captions_res.KPI()}
          primaryAction={
            isDirty ? (
              <Button
                key="saveButton"
                label={labels_res.SAVE()}
                icon={<SaveIcon />}
                onClick={handleSubmit((data) =>
                  handleSubmitOnlyOfChangedFields(data, dirtyFields, onSave)
                )}
                disabled={!isValid}
                color="primary"
              />
            ) : undefined
          }
          forceSecondaryActionsOverflowMenu
        />

        <ChangeInformation
          createdOn={initialValue.createdOn}
          createdById={initialValue.createdBy.userId}
          createdByName={initialValue.createdBy.userName}
          modifiedOn={initialValue.modifiedOn}
          modifiedById={initialValue.modifiedBy.userId}
          modifiedByName={initialValue.modifiedBy.userName}
        />

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <KpiPropertiesPaper isReadonly={isReadonly} />
          </Grid>

          <Grid item xs={12}>
            <KpiGraphPaper />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}
