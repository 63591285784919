import React from 'react'

import {
  ProcessingObjectSelectionData,
  useProcessingObjectSelection,
} from '../../ProcessingObjectSelectionContext'
import {
  NodeBaseProps,
  NodeFrame,
  NodePorts,
} from '../ProcessingGraphNodeBasics'
import { ProcessingObjectNodeModel } from './ProcessingObjectNodeModel'

export interface ProcessingObjectNodeContentProps<TNodeData>
  extends ProcessingObjectSelectionData<TNodeData> {
  node: ProcessingObjectNodeModel
}

export interface ProcessingObjectNodeWidgetProps<TNodeData>
  extends NodeBaseProps<ProcessingObjectNodeModel> {
  ContentComponent: React.FC<ProcessingObjectNodeContentProps<TNodeData>>
}

const ProcessingObjectNodeWidget = <TNodeData,>({
  node,
  engine,
  ContentComponent,
}: ProcessingObjectNodeWidgetProps<TNodeData>) => {
  const nodeData = useProcessingObjectSelection<TNodeData>(
    node.processingObject.nodeId
  )

  return (
    <NodeFrame
      data-testid={'node_' + node.processingObject.nodeId}
      $width={196}
      $isSelected={nodeData.isSelected || nodeData.isImplicitlySelected}
    >
      <ContentComponent node={node} {...nodeData} />
      <NodePorts ports={node.getPorts()} engine={engine} />
    </NodeFrame>
  )
}

export default ProcessingObjectNodeWidget
