import styled from '@emotion/styled'

/** An inline div with a 100% width that cuts off any text overflow with an ellipsis. */
export const FixedWidthTextDiv = styled.div`
  display: inline-block;
  max-width: 100%;
  height: fit-content;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
