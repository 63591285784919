import { Route } from '@dspace-internal/ui-kit'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  SensorIcon,
  SidebarItemInterpolate,
  VehicleIcon,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import { SHOW_AURELION_FEATURES } from './config'
import createBreadcrumbsFor from './shared/utils/createBreadcrumbsFor'
import { PATHS } from './shared/utils/paths'

const VehicleListPage = React.lazy(
  () => import('./components/VehicleListPage/VehicleListPage')
)
const SensorsPage = React.lazy(
  () => import('./components/SensorsPage/SensorsPage')
)
const VehicleDetailsPage = React.lazy(
  () => import('./components/VehicleDetailsPage/VehicleDetailsPage')
)
const SensorDetailsPage = React.lazy(
  () => import('./components/SensorDetailsPage/SensorDetailsPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.VEHICLES_APP_ENABLED,
  sidebarEntries: [
    ...(SHOW_AURELION_FEATURES()
      ? [
          <SidebarItemInterpolate
            name="Sensors"
            icon={<SensorIcon />}
            to={PATHS.SENSOR_LIST}
          />,
        ]
      : []),
    <SidebarItemInterpolate
      name="Vehicles"
      icon={<VehicleIcon />}
      to={PATHS.VEHICLE_LIST}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) => `/projects/${projectId}/vehicles`,
    icon: <VehicleIcon />,
    label: 'Vehicles',
  },
  routes: [
    {
      path: PATHS.VEHICLE_LIST,
      element: wrapComponentInProviders(VehicleListPage),
      breadcrumbs: createBreadcrumbsFor.VEHICLE_LIST,
    },
    SHOW_AURELION_FEATURES() && {
      path: PATHS.SENSOR_LIST,
      element: wrapComponentInProviders(SensorsPage),
      breadcrumbs: createBreadcrumbsFor.SENSOR_LIST,
    },
    {
      path: PATHS.VEHICLE_DETAIL,
      element: wrapComponentInProviders(VehicleDetailsPage),
      breadcrumbs: createBreadcrumbsFor.VEHICLE_LIST,
    },
    SHOW_AURELION_FEATURES() && {
      path: PATHS.SENSOR_DETAILS,
      element: wrapComponentInProviders(SensorDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SENSOR_LIST,
    },
    SHOW_AURELION_FEATURES() && {
      path: PATHS.SENSOR_CREATE,
      element: wrapComponentInProviders(SensorDetailsPage),
      breadcrumbs: createBreadcrumbsFor.SENSOR_LIST,
    },
  ].filter(Boolean) as Array<Route>,
}
