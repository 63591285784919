import IntlMessageFormat from 'intl-messageformat'

const fmt = (message: string, ...rest: any[]): string => {
  const formatter = new IntlMessageFormat(message, 'en-US')
  const formatted = formatter.format(...rest) as string
  return formatted
}

export const labels_res = {
  SAVE: () => fmt(`Save`),
  NAME: () => fmt(`Name`),
  DESCRIPTION: () => fmt(`Description`),
}
