import { colors } from '@dspace-internal/ui-kit'
import { Box } from '@mui/material'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { TextWithOverflow } from '@simphera/shared/ui-simphera'
import React from 'react'

import { KpiGraphNode, kpiGraphNodeTypeLabelMapping } from '../../kpiGraphTypes'
import {
  NodeFrame,
  NodeFrameType,
  NodePorts,
} from '../ProcessingGraphNodeBasics'
import { KpiNodeModel } from './KpiNodeModel'

export interface KpiNodeTitleProps {
  nodeName: string
  node: KpiGraphNode
  action?: React.ReactElement
}

export const KpiNodeTitle: React.FC<KpiNodeTitleProps> = ({
  nodeName,
  node,
  action,
}) => {
  return (
    <Box component="div" display="flex" justifyContent="flex-end">
      <Box component="div" flexGrow="1">
        <Box
          component="div"
          display="flex"
          alignItems="start"
          flexDirection="column"
          overflow="hidden"
        >
          <span
            style={{
              fontSize: '10px',
              fontWeight: 500,
              textTransform: 'uppercase',
              color: colors.gray_70,
            }}
          >
            {kpiGraphNodeTypeLabelMapping[node.type]}
          </span>
          <TextWithOverflow>{nodeName}</TextWithOverflow>
        </Box>
      </Box>

      {action && (
        <Box component="div" flexShrink="1">
          {action}
        </Box>
      )}
    </Box>
  )
}

export type KpiNodeWidth = 's' | 'm'

const kpiNodeWidths: Record<KpiNodeWidth, number> = {
  s: 128,
  m: 196,
}

export interface KpiNodeFrameProps {
  engine: DiagramEngine
  model: KpiNodeModel
  width?: KpiNodeWidth
  frameType?: NodeFrameType
}

const KpiNodeFrame: React.FC<React.PropsWithChildren<KpiNodeFrameProps>> = ({
  model,
  engine,
  width = 'm',
  children,
  frameType = 'normal',
}) => {
  return (
    <NodeFrame
      $width={kpiNodeWidths[width]}
      $isSelected={true}
      $type={frameType}
    >
      {children}
      <NodePorts ports={model.getPorts()} engine={engine} />
    </NodeFrame>
  )
}

export default KpiNodeFrame
