import { ApolloError } from '@apollo/client'
import {
  Button,
  Paper,
  useBreadcrumbs,
  useSnackbar,
} from '@dspace-internal/ui-kit'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Grid, Link, Typography } from '@mui/material'
import {
  ChangeInformation,
  CustomPageHeader,
  ReadonlyChipWithTooltip,
  Spinner,
  usePageParams,
} from '@simphera/shared/ui-simphera'
import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import createBreadcrumbsFor from '../../../createBreadcrumbsFor'
import {
  QuerySettingsInput,
  useGitWorkspaceCommitsQuery,
  useGitWorkspaceDetailsPageQuery,
} from '../../../generated/codeGenerator'
import messages from '../../../utils/messages.res'
import { COMMITS_DEFAULT_PAGE_SIZE } from '../GitWorkspaceFormFields/constants'
import { GitWorkspaceCommitsPaper } from './GitWorkspaceCommitsPaper/GitWorkspaceCommitsPaper'

interface GitWorkspaceDetailsQueryParams {
  [gitWorkspaceId: string]: string
}

const GitWorkspaceDetails: React.FC = () => {
  const { setBreadcrumbs } = useBreadcrumbs()
  const showSnackbar = useSnackbar()
  const { gitWorkspaceId } = usePageParams<GitWorkspaceDetailsQueryParams>()

  const [commitsQuerySettings, setCommitsQuerySettings] =
    useState<QuerySettingsInput>({
      descending: true,
      filters: [],
      pageSize: COMMITS_DEFAULT_PAGE_SIZE,
      pageToken: '0',
      sortField: 'projectCreatedOn',
    })

  const { loading, data, error } = useGitWorkspaceDetailsPageQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: gitWorkspaceId,
    },
    onError(error: ApolloError) {
      showSnackbar(
        messages.GIT_WORKSPACE_FAILED_LOADING(error.message),
        'error'
      )
    },
  })

  const {
    loading: commitsLoading,
    data: commitsData,
    error: commitsError,
  } = useGitWorkspaceCommitsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: gitWorkspaceId,
      querySettings: commitsQuerySettings,
    },
    onError(error: ApolloError) {
      showSnackbar(
        messages.GIT_WORKSPACE_FAILED_LOADING(error.message),
        'error'
      )
    },
  })

  useEffect(() => {
    if (data?.gitWorkspace.name) {
      setBreadcrumbs(
        createBreadcrumbsFor.GIT_WORKSPACE_DETAIL(data.gitWorkspace.name)
      )
    }
  }, [data, setBreadcrumbs])

  // TODO replace with permission handling
  const canWrite = true

  if (loading) {
    return <Spinner />
  }

  if (error || (!data && !loading)) {
    return (
      <Typography variant="body1">
        {messages.GIT_WORKSPACE_LOAD_ERROR()}
      </Typography>
    )
  }

  return (
    <>
      <CustomPageHeader
        id="GIT_WORKSPACE_DETAILS"
        primaryAction={
          canWrite && (
            <Button
              component={NavLink}
              color="primary"
              variant="contained"
              icon={<EditIcon />}
              label="Edit"
              to={`/gitWorkspaces/${gitWorkspaceId}/edit`}
            />
          )
        }
        subtitle="Git Workspace"
        title={data?.gitWorkspace.name || gitWorkspaceId}
        titleEndAdornment={!canWrite && <ReadonlyChipWithTooltip />}
      />
      <ChangeInformation
        createdById={data?.gitWorkspace.createdBy?.id || ''}
        createdByName={data?.gitWorkspace.createdBy?.name || ''}
        createdOn={new Date(data?.gitWorkspace.createdOn).getTime() / 1000}
        modifiedById={data?.gitWorkspace.modifiedBy?.id || ''}
        modifiedByName={data?.gitWorkspace.modifiedBy?.name || ''}
        modifiedOn={new Date(data?.gitWorkspace.modifiedOn).getTime() / 1000}
      />
      <Box component="div" sx={{ flexGrow: 1 }}>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ height: '100%' }}>
              <Typography variant="h4" sx={{ marginBottom: 1 }}>
                Description
              </Typography>
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {data?.gitWorkspace.description}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ height: '100%' }}>
              <Typography variant="h4" sx={{ marginBottom: 1 }}>
                Repository URL
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineBreak: 'anywhere',
                }}
              >
                <Link
                  href={
                    data?.gitWorkspace?.gitConfiguration.repositoryUrl || ''
                  }
                >
                  {data?.gitWorkspace?.gitConfiguration.repositoryUrl}
                </Link>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <GitWorkspaceCommitsPaper
        gitConfiguration={data?.gitWorkspace?.gitConfiguration}
        workspaceId={data?.gitWorkspace?.id}
        error={commitsError}
        commitsData={commitsData}
        commitsQuerySettings={commitsQuerySettings}
        loading={commitsLoading}
        setCommitsQuerySettings={setCommitsQuerySettings}
      />
    </>
  )
}

export default GitWorkspaceDetails
