import React from 'react'

export interface ReadonlyFormStateProviderProps {
  isReadonly: boolean
}

const ReadonlyFormStateContext =
  React.createContext<ReadonlyFormStateProviderProps>({
    isReadonly: false,
  })

export const ReadonlyFormStateProvider: React.FC<
  React.PropsWithChildren<ReadonlyFormStateProviderProps>
> = ({ children, ...props }) => {
  return (
    <ReadonlyFormStateContext.Provider value={props}>
      {children}
    </ReadonlyFormStateContext.Provider>
  )
}

export const useReadonlyFormState = () => {
  return React.useContext(ReadonlyFormStateContext)
}
