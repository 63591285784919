import { User } from 'oidc-client-ts'
import { setUserInfo } from '../state'
import { signInRedirectWithState } from './signInRedirect'
import { userManager } from './userManager'

export const initializeUserSession = async () => {
  registerEventListeners()
  const user = await userManager.getUser()

  if (user) {
    saveUserInAppState(user)
  } else if (hasUserJustBeenRedirectedAfterLogin()) {
    userManager //
      .signinRedirectCallback()
      .catch(handleAuthenticationError)
  } else {
    signInRedirectWithState()
  }
}

const registerEventListeners = () => {
  userManager.events.addAccessTokenExpiring(performTokenRefresh) // called shortly before access token expires
  userManager.events.addUserLoaded(handleNewUser) // called via signinRedirectCallback and signinSilent
}

const hasUserJustBeenRedirectedAfterLogin = () =>
  new URLSearchParams(document.location.search).has('code')

const handleAuthenticationError = (authenticationError: Error) => {
  console.error({ authenticationError })
  signInRedirectWithState()
}

const handleTokenRefreshError = (tokenRefreshError: Error) => {
  console.error({ tokenRefreshError })
  signInRedirectWithState()
}

const saveUserInAppState = (user: User) =>
  setUserInfo({
    id: `${user.profile.sub}`,
    name: `${user.profile.name}`,
    givenName: `${user.profile.given_name}`,
    token: `${user.access_token}`,
    url_state: user.url_state || '/', // <Authentication /> will take care of navigating the user based on url_state
  })

const handleNewUser = (user: User | null) => {
  if (!user) {
    console.log('user session could not be established')
    signInRedirectWithState()
  } else {
    userManager.storeUser(user)
    saveUserInAppState(user)
  }
}

const performTokenRefresh = () => {
  userManager //
    .signinSilent()
    .catch(handleTokenRefreshError)
}
