import createUrlFor from './createUrlFor'

const project = createUrlFor.project.withId(':projectId')

export const PATHS = {
  START_PAGE: project.startPage,
  SUITE_COLLECTION: project.suite.collection,
  SUITE_NEW: project.suite.new,
  SUITE_DETAIL_PAGE: project.suite.withId(':suiteId').detailPage,
  RUN_COLLECTION: project.run.collection,
  RUN_DETAIL_PAGE: project.run.withId(':runId').detailPage,
  RUN_TESTCASE_DETAIL_PAGE: project.run
    .withId(':runId')
    .testCase.withId(':testCaseId').detailPage,
  RESULT_DETAIL_PAGE: project.result.withId(':resultId').detailPage,
  TEST_CASE_NEW: project.testCase.new,
  TEST_CASE_DETAILS_PAGE: project.testCase.withId(':testCaseId').detailPage,
}
