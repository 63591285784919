/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types */
import {
  ApolloError,
  ApolloProvider,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client'
import { theme } from '@dspace-internal/ui-kit'
import { ThemeProvider } from '@mui/material'
import { useLogout, useUserInfo } from '@simphera/shared/state'
import isObject from 'lodash/isObject'
import keyBy from 'lodash/keyBy'
import transform from 'lodash/transform'
import React, { useRef } from 'react'
import { RUN_HOOK_URL } from '../config'
import FeatureFlagProvider from '../shared/components/FeatureFlagProvider'
import { apolloClientScbt, apolloClientUpdate } from './apolloClientScbt'

export function omitTypeNameDeep<T extends object>(value: T): any {
  const keysToOmitIndex = keyBy(['__typename'])

  function omitFromObject(obj: any) {
    return transform(obj, function (result: any, value, key) {
      if (key in keysToOmitIndex) {
        return
      }
      result[key] = isObject(value) ? omitFromObject(value) : value
    })
  }

  return omitFromObject(value)
}

/** Extracts the item type from an array type. Also works when the array type is a union with undefined or null. */
export type ItemTypeOfArray<TWhere> = TWhere extends (infer U)[]
  ? U extends object
    ? U
    : never
  : never

/** Extracts a type from an union with undefined and null.*/
export type TypeOfNullableUnion<TWhere> = TWhere extends
  | infer U
  | undefined
  | null
  ? U extends object
    ? U
    : never
  : never

/**
 * Converts a UTC DateTime String to a more readable localized String
 * @param input unix timestamp in seconds
 */
export function convertUTCToLocal(input: number): string {
  if (input) {
    const date = new Date(input * 1000)
    return Intl.DateTimeFormat(navigator.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date)
  } else {
    return input.toLocaleString()
  }
}

/**
 * Converts a unix time to a local DateTime object.
 * @param input unix timestamp in seconds
 */
export function convertUTCToLocalDateTime(input: number): Date {
  return new Date(input * 1000)
}

/**
 * Compares two elapsed time duration.
 * @param a - first time as string.
 * @param b - second time as string.
 */
export const compareDateTimeAgo = (a: string, b: string): number => {
  const dateA = new Date(a)
  const dateB = new Date(b)

  if (dateA && dateB) {
    if (dateA === dateB) {
      return 0
    }

    // Note: We reverse the compare result in the return statement below.
    // If the first date is greater than the second one, we return -1 (instead of 1).
    // Reason: The oldest dates are listed towards the bottom when sorted in
    // ascending order. The reason for this is, that we show the dates
    // as a time-ago string (e.g - 5 seconds ago, 13 min ago, 2 hr ago, 5 days ago).
    // When sorted in ascending order, we expect the smallest time ago duration
    // in other words, the newest item (default is oldest) to be listed at the top.
    return dateA > dateB ? -1 : 1
  } else {
    return 0
  }
}

/**
 * Compare two strings without case sensitivity (a = A).
 * @param a - first string to compare
 * @param b - second string to compare
 */
export const compareStringsCaseInsensitive = (a: string, b: string): number => {
  const collator = new Intl.Collator(navigator.language, {
    numeric: true,
    sensitivity: 'base',
  })
  return collator.compare(a, b)
}

/** Compares two strings and returns whether they are equal, disregarding case. */
export const equalsIgnoreCase = (left: string, right: string): boolean => {
  return compareStringsCaseInsensitive(left, right) === 0
}

/**
 * Compares the name property of two object without case sensitivity.
 * Note: The objects should contain a key named 'name'.
 * @param item1 - first object to compare.
 * @param item2 - second object to compare.
 */
export const compareObjectNames = (
  item1: { name: string },
  item2: { name: string }
): number => {
  return compareStringsCaseInsensitive(item1.name, item2.name)
}

/**
 * Gets a resource using GET method with the specified body.
 * The content-type is set as json.
 * @param url - The url to be fetched.
 */
export const getResource = (url: string): Promise<any> => {
  return fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json())
    .catch((error) => console.error(error))
}

/**
 * Function to wrap necessary Providers around a component. */
export const wrapComponentInProviders = (
  Component: React.FC | React.ComponentType<any>
): React.ReactNode => {
  return (
    <FeatureFlagProvider>
      <ThemeProvider theme={theme}>
        <ApolloWrapper>
          <Component />
        </ApolloWrapper>
      </ThemeProvider>
    </FeatureFlagProvider>
  )
}
const ApolloWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { token } = useUserInfo()
  const logout = useLogout()
  apolloClientUpdate(token, logout)

  return <ApolloProvider client={apolloClientScbt}>{children}</ApolloProvider>
}

export const QueryStringKeys = {
  projectId: 'project-id',
  runId: 'run-id',
  resultId: 'result-id',
  scenarioId: 'scenario-id',
  suiteId: 'suite-id',
  testCaseId: 'testcase-id',
}

export const generateQueryString = (
  queryStringList: Array<[string, string]>
): string => {
  const querray = queryStringList.map((tuple) => {
    return tuple[0] + '=' + tuple[1]
  })

  return '?'.concat(querray.join('&'))
}

export const callExternalRun = (
  projectId: string,
  testSuiteId: string,
  testCaseId?: string
): boolean => {
  if (RUN_HOOK_URL) {
    const queryString = testCaseId
      ? generateQueryString([
          [QueryStringKeys.projectId, projectId],
          [QueryStringKeys.suiteId, testSuiteId],
          [QueryStringKeys.testCaseId, testCaseId],
        ])
      : generateQueryString([
          [QueryStringKeys.projectId, projectId],
          [QueryStringKeys.suiteId, testSuiteId],
        ])

    const runUrl = RUN_HOOK_URL + queryString
    window.open(runUrl)
    return true
  } else return false
}

/** Calls the given query and wraps the success and error handling to add a error tolerance for polling.
 * Errors will be ignored until x consecutive errors occurred.
 */
export const usePollingErrorTolerance = <
  TQuery extends {},
  TVariables extends OperationVariables
>(
  useQuery: (
    baseOptions: QueryHookOptions<TQuery, TVariables>
  ) => QueryResult<TQuery, TVariables>,
  baseOptions: QueryHookOptions<TQuery, TVariables>,
  maxToleratedConsecutiveErrors = 1
): QueryResult<TQuery, TVariables> => {
  // Prepare error counting
  const isFirstPoll = useRef<boolean>(true)
  const errorCount = useRef<number>(0)
  const untoleratedError = useRef<ApolloError | undefined>(undefined)
  const latestData = useRef<TQuery | undefined>(undefined)

  const { onCompleted, onError } = baseOptions

  const handlePollingCompleted = (data: TQuery) => {
    errorCount.current = 0
    isFirstPoll.current = false
    latestData.current = data
    onCompleted && onCompleted(data)
  }

  const handlePollingError = (error: ApolloError) => {
    if (error.networkError) {
      errorCount.current++

      if (
        isFirstPoll.current ||
        errorCount.current > maxToleratedConsecutiveErrors
      ) {
        onError && onError(error)
        untoleratedError.current = error
      } else {
        console.error(error)
      }
    } else {
      onError && onError(error)
      untoleratedError.current = error
    }
  }

  // Manipulate query options
  baseOptions.onCompleted = handlePollingCompleted
  baseOptions.onError = handlePollingError

  // Call query
  const queryResult = useQuery(baseOptions)

  // Override result
  const returnValue = {
    ...queryResult,
    // If it is the first fetch and an error occurs, return that error,
    // otherwise only return untolerated errors
    error: isFirstPoll.current ? queryResult.error : untoleratedError.current,
    // If there is an untolerated error, return undefined
    // if there is a tolerated error, return data from last iteration
    // else return current data
    data: untoleratedError.current
      ? undefined
      : errorCount.current
      ? latestData.current
      : queryResult.data,
  }

  return returnValue
}
