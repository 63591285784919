import { GenerateWidgetEvent } from '@projectstorm/react-canvas-core'
import { DefaultLinkFactory } from '@projectstorm/react-diagrams'
import React from 'react'

import { CurvedLinkModel } from './CurvedLinkModel'
import { CurvedLinkWidget } from './CurvedLinkWidget'

export class CurvedLinkFactory extends DefaultLinkFactory {
  constructor() {
    super('curved')
  }

  generateModel(): CurvedLinkModel {
    return new CurvedLinkModel()
  }

  generateReactWidget(
    event: GenerateWidgetEvent<CurvedLinkModel>
  ): JSX.Element {
    return (
      <CurvedLinkWidget
        link={event.model}
        diagramEngine={this.engine}
        renderPoints={false}
      />
    )
  }
}
