import { mdiFunctionVariant } from '@mdi/js'
import Icon from '@mdi/react'
import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import { SidebarItemInterpolate } from '@simphera/shared/ui-simphera'
import React from 'react'
import { KpiDetailsPage } from './components/KpiDetailsPage'
import createBreadcrumbsFor from './createBreadcrumbsFor'
import { PATHS } from './paths'

const KpiListPage = React.lazy(() => import('./components/KpiListPage'))

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.KPI_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name="KPIs"
      icon={<Icon path={mdiFunctionVariant} size={1} />}
      to={PATHS.LIST_PAGE}
    />,
  ],
  routes: [
    {
      path: PATHS.LIST_PAGE,
      element: <KpiListPage />,
      breadcrumbs: createBreadcrumbsFor.LIST_PAGE,
    },
    {
      path: PATHS.DETAILS_PAGE,
      element: <KpiDetailsPage />,
      breadcrumbs: createBreadcrumbsFor.LIST_PAGE,
    },
  ],
}
