import { Breadcrumb } from '@dspace-internal/ui-kit'
import {
  BreadcrumbInterpolate,
  interpolatePath,
} from '@simphera/shared/ui-simphera'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  RUN_COLLECTION: [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Runs" />,
  ],
  SUITE_COLLECTION: [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Suites" />,
  ],
  SUITE_NEW: [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITE_COLLECTION} />,
    <BreadcrumbInterpolate name="New Suite" />,
  ],
  TEST_CASE_NEW: (projectId: string, suiteName: string, suiteId: string) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITE_COLLECTION} />,
    <Breadcrumb
      name={suiteName}
      to={interpolatePath(PATHS.SUITE_DETAIL_PAGE, { projectId, suiteId })}
    />,
    <BreadcrumbInterpolate name="New Test Case" />,
  ],
  TEST_CASE_DETAILS_PAGE: (
    projectId: string,
    suiteName: string,
    suiteId: string,
    testCaseName: string
  ) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITE_COLLECTION} />,
    <Breadcrumb
      name={suiteName}
      to={interpolatePath(PATHS.SUITE_DETAIL_PAGE, { projectId, suiteId })}
    />,
    <BreadcrumbInterpolate name={testCaseName} />,
  ],
  SUITE_DETAIL_PAGE: (suiteName: string) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Suites" to={PATHS.SUITE_COLLECTION} />,
    <BreadcrumbInterpolate name={suiteName} />,
  ],
  RESULT_DETAIL_PAGE: (
    projectId: string,
    runId: string,
    runName: string,
    testCaseId: string,
    testCaseName: string,
    resultName: string
  ) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Runs" to={PATHS.RUN_COLLECTION} />,
    <Breadcrumb
      name={runName}
      to={interpolatePath(PATHS.RUN_DETAIL_PAGE, { projectId, runId })}
    />,
    <Breadcrumb
      name={testCaseName}
      to={interpolatePath(PATHS.RUN_TESTCASE_DETAIL_PAGE, {
        projectId,
        runId,
        testCaseId,
      })}
    />,
    <Breadcrumb name={resultName} />,
  ],
  RUN_DETAIL_PAGE: (runName: string) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Runs" to={PATHS.RUN_COLLECTION} />,
    <BreadcrumbInterpolate name={runName} />,
  ],
  RUN_TEST_CASE_DETAILS_PAGE: (
    projectId: string,
    runId: string,
    runName: string,
    testCaseName: string
  ) => [
    <BreadcrumbInterpolate name="Scenario-based" to={PATHS.START_PAGE} />,
    <BreadcrumbInterpolate name="Runs" to={PATHS.RUN_COLLECTION} />,
    <BreadcrumbInterpolate
      name={runName}
      to={interpolatePath(PATHS.RUN_DETAIL_PAGE, { projectId, runId })}
    />,
    <BreadcrumbInterpolate name={testCaseName} />,
  ],
}

export default createBreadcrumbsFor
