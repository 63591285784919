import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  HorizontalSeparator,
  StyledGridTile,
  StyledTileGridLayout,
  VerticalSeparator,
} from './TileGridLayout.styles'

interface GridTileLayoutData {
  isVerticalLayout: boolean
  hasBorder: (tileName: string) => boolean
}

const TileGridLayoutContext = createContext<GridTileLayoutData | undefined>(
  undefined
)

interface LayoutProps {
  rows: string[]
  isVerticalLayout?: boolean
  gridTemplateColumns: string
  gridTemplateRows: string
}

interface ResponsiveLayout extends LayoutProps {
  breakpointWidth: number
}

interface TileGridLayoutProps {
  defaultLayout: LayoutProps
  responsiveLayouts?: ResponsiveLayout[]
}

/** A grid that layouts tiles. */
export const TileGridLayout: React.FC<
  React.PropsWithChildren<TileGridLayoutProps>
> = ({ children, defaultLayout, responsiveLayouts, ...rest }) => {
  const determineCurrentLayout = useCallback((): LayoutProps => {
    if (!responsiveLayouts) {
      return defaultLayout
    }

    const windowWidth = window.innerWidth

    let responsiveLayout: ResponsiveLayout | undefined = undefined
    responsiveLayouts
      .sort((left, right) => right.breakpointWidth - left.breakpointWidth)
      .forEach((layout) => {
        if (
          windowWidth <= layout.breakpointWidth &&
          responsiveLayout === undefined
        ) {
          responsiveLayout = layout
        }
      })

    return responsiveLayout || defaultLayout
  }, [defaultLayout, responsiveLayouts])

  const [currentLayout, setCurrentLayout] = useState<LayoutProps>(
    determineCurrentLayout
  )

  useEffect(() => {
    const handleResize = () => {
      const nextLayout = determineCurrentLayout()

      if (currentLayout !== nextLayout) {
        setCurrentLayout(nextLayout)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })

  const contextData = useMemo<GridTileLayoutData>(
    () => ({
      isVerticalLayout: currentLayout.isVerticalLayout || false,
      hasBorder: (tileName: string) => {
        const tiles = currentLayout.rows.map((row) => row.split(' '))

        if (currentLayout.isVerticalLayout) {
          return tiles[0].every((tile) => tile !== tileName)
        } else {
          return tiles.every((row) => row[0] !== tileName)
        }
      },
    }),
    [currentLayout.isVerticalLayout, currentLayout.rows]
  )

  const { rowsWithSeparators, separators, templateColumnsWithSeparators } =
    useMemo((): {
      rowsWithSeparators: string[]
      separators: React.ReactElement
      templateColumnsWithSeparators: string
    } => {
      if (currentLayout.isVerticalLayout) {
        const tiles = currentLayout.rows.map((row) => row.split(' '))

        if (tiles[0].length <= 1) {
          // Just one column => no separators needed
          return {
            rowsWithSeparators: currentLayout.rows,
            separators: <></>,
            templateColumnsWithSeparators: currentLayout.gridTemplateColumns,
          }
        } else {
          // Add a vertical separator between each column
          const templateColumns = currentLayout.gridTemplateColumns.split(' ')

          const tilesWithSeparators: string[][] = []
          const separators: React.ReactElement[] = []
          const templateColumnsWithSeparators: string[] = []

          for (let row = 0; row < tiles.length; row++) {
            tilesWithSeparators.push([])
          }

          for (let column = 0; column < tiles[0].length; column++) {
            const addSeparator = column < tiles[0].length - 1
            const seperatorName = 'sep' + column

            for (let row = 0; row < tiles.length; row++) {
              tilesWithSeparators[row].push(tiles[row][column])

              if (addSeparator) {
                tilesWithSeparators[row].push(seperatorName)
              }
            }

            templateColumnsWithSeparators.push(templateColumns[column])
            if (addSeparator) {
              templateColumnsWithSeparators.push('auto')
              separators.push(
                <VerticalSeparator
                  key={seperatorName}
                  $tileName={seperatorName}
                />
              )
            }
          }

          return {
            rowsWithSeparators: tilesWithSeparators.map((row) => row.join(' ')),
            separators: <>{separators}</>,
            templateColumnsWithSeparators:
              templateColumnsWithSeparators.join(' '),
          }
        }
      } else {
        if (currentLayout.rows.length <= 1) {
          // Just one row => no separators needed
          return {
            rowsWithSeparators: currentLayout.rows,
            separators: <></>,
            templateColumnsWithSeparators: currentLayout.gridTemplateColumns,
          }
        } else {
          // Add a horizontal separator between each row
          const tilesPerRow = currentLayout.rows[0].split(' ').length

          const rowsWithSeparators: string[] = []
          const separators: React.ReactElement[] = []

          currentLayout.rows.forEach((row, index) => {
            rowsWithSeparators.push(row)

            if (index < currentLayout.rows.length - 1) {
              const separatorName = 'sep' + index
              rowsWithSeparators.push(
                Array<string>(tilesPerRow).fill(separatorName).join(' ')
              )
              separators.push(
                <HorizontalSeparator
                  key={separatorName}
                  $tileName={separatorName}
                />
              )
            }
          })

          return {
            rowsWithSeparators,
            separators: <>{separators}</>,
            templateColumnsWithSeparators: currentLayout.gridTemplateColumns,
          }
        }
      }
    }, [
      currentLayout.isVerticalLayout,
      currentLayout.rows,
      currentLayout.gridTemplateColumns,
    ])

  return (
    <TileGridLayoutContext.Provider value={contextData}>
      <StyledTileGridLayout
        {...rest}
        $rows={rowsWithSeparators}
        $gridTemplateColumns={templateColumnsWithSeparators}
        $gridTemplateRows={currentLayout.gridTemplateRows}
      >
        {separators}

        {children}
      </StyledTileGridLayout>
    </TileGridLayoutContext.Provider>
  )
}

export interface GridTileProps {
  tileName: string
}

/** A tile in a tile grid layout. */
export const GridTile: React.FC<React.PropsWithChildren<GridTileProps>> = ({
  tileName,
  children,
  ...rest
}) => {
  const { isVerticalLayout, hasBorder } = useContext(TileGridLayoutContext)!

  return (
    <StyledGridTile
      {...rest}
      $tileName={tileName}
      $isVerticalLayout={isVerticalLayout}
      $noBorder={!hasBorder(tileName)}
      data-testid="grid_tile"
    >
      {children}
    </StyledGridTile>
  )
}
