import { SidebarItem } from '@dspace-internal/ui-kit'
import React from 'react'
import { useParams } from 'react-router-dom'
import { interpolatePath } from './interpolatePath'

export interface SidebarItemInterpolateProps {
  name: string
  to: string
  icon: React.ReactElement
  exact?: boolean
}

export const SidebarItemInterpolate: React.FC<SidebarItemInterpolateProps> = ({
  name,
  to,
  icon,
  exact = false,
}) => {
  const params = useParams()

  return (
    <SidebarItem
      name={name}
      to={interpolatePath(to, params)}
      icon={icon}
      exact={exact}
    />
  )
}
