/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Operation } from '../models/Operation';
import type { OperationResultList } from '../models/OperationResultList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OperationsService {

    /**
     * @returns OperationResultList Success
     * @throws ApiError
     */
    public static getOperations({
        filter,
        pageSize,
        pageToken,
    }: {
        /**
         * Uses the OData filter syntax e.g.
         *
         * id eq guid'3fa85f64-5717-4562-b3fc-2c963f66afa6'
         *
         * status ne 'Running'
         *
         * createdOn eq datetimeoffset'2023-09-11T06:24:44.492Z'
         *
         * Note: Filtering by log entries is not supported.
         */
        filter?: string,
        pageSize?: number,
        pageToken?: string,
    }): CancelablePromise<OperationResultList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/operations',
            query: {
                'filter': filter,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @returns Operation Success
     * @throws ApiError
     */
    public static getOperations1({
        operationId,
    }: {
        operationId: string,
    }): CancelablePromise<Operation> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/operations/{operationId}',
            path: {
                'operationId': operationId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
