import { BreadcrumbInterpolate } from '@simphera/shared/ui-simphera'
import { captions_res } from '../resources'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  EXPERIMENT_LIST: [
    <BreadcrumbInterpolate name={captions_res.EXPERIMENTS()} />,
  ],
  EXPERIMENT_CREATE: [
    <BreadcrumbInterpolate
      name={captions_res.EXPERIMENTS()}
      to={PATHS.EXPERIMENT_LIST}
    />,
    <BreadcrumbInterpolate name={captions_res.NEW_EXPERIMENT()} />,
  ],
  EXPERIMENT_DETAIL: (experimentName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.EXPERIMENTS()}
      to={PATHS.EXPERIMENT_LIST}
    />,
    <BreadcrumbInterpolate name={experimentName} />,
  ],
  EXPERIMENT_EDIT: (experimentName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.EXPERIMENTS()}
      to={PATHS.EXPERIMENT_LIST}
    />,
    <BreadcrumbInterpolate
      name={experimentName}
      to={PATHS.EXPERIMENT_DETAIL}
    />,
    <BreadcrumbInterpolate name={captions_res.EDIT_EXPERIMENT()} />,
  ],
}

export default createBreadcrumbsFor
