import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

export const SpinnerRoot = styled.div<{ $marginTop: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.$marginTop};
`

export const LoaderText = styled.div`
  position: absolute;
  top: 55%;

  width: 100%;
  color: ${colors.blue_80};
  text-align: center;
`
