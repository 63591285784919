/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiKeyDTO } from '../models/ApiKeyDTO';
import type { ApiKeyStringDTO } from '../models/ApiKeyStringDTO';
import type { CreateApiKeyDTO } from '../models/CreateApiKeyDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiKeyService {

    /**
     * List all API keys of a current user.
     *
     * List all API keys of a current user.
     * ---
     *
     * @returns ApiKeyDTO Success
     * @throws ApiError
     */
    public static getApiKeys(): CancelablePromise<Array<ApiKeyDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api-keys',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create API key.
     *
     * Create API key.
     * ---
     *
     * @returns ApiKeyStringDTO Success
     * @throws ApiError
     */
    public static postApiKeys({
        requestBody,
    }: {
        requestBody: CreateApiKeyDTO,
    }): CancelablePromise<ApiKeyStringDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api-keys',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a single API key.
     *
     * Delete a single API key.
     * ---
     *
     * @returns any Success
     * @throws ApiError
     */
    public static deleteApiKeys({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api-keys/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                500: `Server Error`,
            },
        });
    }

}
