import { BreadcrumbInterpolate } from '@simphera/shared/ui-simphera'
import { captions_res } from '../resources'
import { PATHS } from './paths'

const createBreadcrumbsFor = {
  SCENARIO_LIST: [<BreadcrumbInterpolate name="Scenarios" />],
  SCENARIO_DETAILS: (scenarioName: string) => [
    <BreadcrumbInterpolate
      name={captions_res.SCENARIOS()}
      to={PATHS.SCENARIO_LIST}
    />,
    <BreadcrumbInterpolate name={scenarioName} />,
  ],
  SCENARIO_IMPORT: [
    <BreadcrumbInterpolate
      name={captions_res.SCENARIOS()}
      to={PATHS.SCENARIO_LIST}
    />,
    <BreadcrumbInterpolate name={captions_res.IMPORT()} />,
  ],
}

export default createBreadcrumbsFor
