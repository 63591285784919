import { PageHeader, Paper } from '@dspace-internal/ui-kit'
import { Link, useRouteError } from 'react-router-dom'

export const ErrorPage: React.FC = () => {
  const error = useRouteError()
  console.error(error)

  return (
    <>
      <PageHeader title="Oops!" />
      <Paper>
        <div>An unknown error occured.</div>
        <Link to="/">Go back to the start</Link>
        <br />
        <code>
          <pre>{JSON.stringify(error)}</pre>
        </code>
      </Paper>
    </>
  )
}
