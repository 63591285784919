import React, { createContext, useContext, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FeatureFlags {}

const fallBackFlags: FeatureFlags = Object.freeze({})

const FeatureFlagContext = createContext<FeatureFlags | null>(null)

const loadFlags = (): FeatureFlags => {
  return {}
}

const FeatureFlagProvider: React.FC<
  React.PropsWithChildren<{ flagsMock?: FeatureFlags }>
> = ({ children, flagsMock }) => {
  const [featureFlagsState /*, setFeatureFlags*/] =
    useState<FeatureFlags>(loadFlags)

  return (
    <FeatureFlagContext.Provider value={flagsMock ?? featureFlagsState}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export const useFeatureFlags = (): FeatureFlags => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(FeatureFlagContext)
  if (context === null) {
    // useFeatureFlags was called outside of a FeatureFlagProvider
    // => This should only happen in unit tests, to prevent breaking those we return the fallback flags
    return fallBackFlags
  }
  return context as FeatureFlags
}

export default FeatureFlagProvider
