import {
  SidebarGroup,
  SidebarEntries as UiKitSidebarEntries,
} from '@dspace-internal/ui-kit'
import { navigationConfig as DataReplayNavigationConfig } from '@simphera/datareplay'
import { navigationConfig as ExperimentNavigationConfig } from '@simphera/experiment'
import { navigationConfig as KpiNavigationConfig } from '@simphera/kpi'
import { navigationConfig as ProjectNavigationConfig } from '@simphera/project'
import { navigationConfig as ScbtNavigationConfig } from '@simphera/scbt'
import { navigationConfig as ScenariosNavigationConfig } from '@simphera/scenarios'
import {
  NavigationConfig,
  ProjectItemEntry,
  useProjectInfo,
} from '@simphera/shared/state'
import { navigationConfig as StepbtNavigationConfig } from '@simphera/stepbt'
import { navigationConfig as SystemundertestNavigationConfig } from '@simphera/systemundertest'
import { navigationConfig as TestenvironmentNavigationConfig } from '@simphera/testenvironment'
import { navigationConfig as VehiclesNavigationConfig } from '@simphera/vehicles'
import React from 'react'
import { withReactKeys } from '../../utils/withReactKeys'

const NAVIGATION_CONFIGS = [
  ProjectNavigationConfig,
  VehiclesNavigationConfig,
  ScenariosNavigationConfig,
  ExperimentNavigationConfig,
  SystemundertestNavigationConfig,
  KpiNavigationConfig,
  TestenvironmentNavigationConfig,
  ScbtNavigationConfig,
  StepbtNavigationConfig,
  DataReplayNavigationConfig,
]

export const getNavigationConfigs = (): Array<NavigationConfig> => {
  return NAVIGATION_CONFIGS.filter((cfg) => cfg.isEnabled)
}

export const getRoutes = () =>
  getNavigationConfigs()
    .map((config) => config.routes)
    .flat()

export const getProjectLinks = (): Array<ProjectItemEntry> =>
  getNavigationConfigs()
    .filter((config) => Boolean(config.projectItemEntry))
    .map((config) => config.projectItemEntry!)

function renderSidebarEntriesIfEnabled(
  cfg: NavigationConfig
): React.ReactElement {
  return cfg.isEnabled ? withReactKeys(cfg.sidebarEntries) : <></>
}

export const SidebarEntries = () => {
  const projectInfo = useProjectInfo()

  return (
    <UiKitSidebarEntries>
      {renderSidebarEntriesIfEnabled(ProjectNavigationConfig)}
      {projectInfo && (
        <>
          <SidebarGroup name="Prepare">
            {renderSidebarEntriesIfEnabled(VehiclesNavigationConfig)}
            {renderSidebarEntriesIfEnabled(ScenariosNavigationConfig)}
            {renderSidebarEntriesIfEnabled(SystemundertestNavigationConfig)}
            {renderSidebarEntriesIfEnabled(TestenvironmentNavigationConfig)}
            {renderSidebarEntriesIfEnabled(KpiNavigationConfig)}
          </SidebarGroup>
          <SidebarGroup name="Simulate & Control">
            {renderSidebarEntriesIfEnabled(ExperimentNavigationConfig)}
          </SidebarGroup>
          <SidebarGroup name="Validate">
            {renderSidebarEntriesIfEnabled(ScbtNavigationConfig)}
            {renderSidebarEntriesIfEnabled(StepbtNavigationConfig)}
            {renderSidebarEntriesIfEnabled(DataReplayNavigationConfig)}
          </SidebarGroup>
        </>
      )}
    </UiKitSidebarEntries>
  )
}
