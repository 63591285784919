import { NodeModel, NodeModelGenerics } from '@projectstorm/react-diagrams'

import { ProcessingObject } from '../../processingGraphTypes'

// this can be further extended for more complicated node types
export class ProcessingObjectNodeModel extends NodeModel<NodeModelGenerics> {
  processingObject: ProcessingObject

  constructor(processingObject: ProcessingObject) {
    super({
      type: 'processingObject',
    })

    this.processingObject = processingObject
  }
}
