import { Chip, Tooltip } from '@dspace-internal/ui-kit'
import { mdiLock } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'
import { descriptions_res } from '../../../resources'

const NO_WRITE_PERMISSION_TEXT = 'No write permission'

export interface ReadonlyChipWithTooltipProps {
  chipLabel?: string
  tooltip?: string
}

export const ReadonlyChipWithTooltip: React.FC<
  ReadonlyChipWithTooltipProps
> = ({
  chipLabel = NO_WRITE_PERMISSION_TEXT,
  tooltip = descriptions_res.NO_PROJECT_WRITE_PERMISSION(),
}) => {
  return (
    <Tooltip message={tooltip} arrow>
      <Chip
        disableTooltip
        appearance="info"
        variant="outlined"
        style={{ marginLeft: 16 }}
        label={chipLabel}
        icon={
          <SvgIcon style={{ marginLeft: 4 }}>
            <path d={mdiLock} />
          </SvgIcon>
        }
      />
    </Tooltip>
  )
}
