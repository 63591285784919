import {
  AbstractReactFactory,
  GenerateWidgetEvent,
} from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import { InputNodeModel } from './InputNodeModel'
import InputNodeWidget from './InputNodeWidget'

export class InputNodeFactory extends AbstractReactFactory<
  InputNodeModel,
  DiagramEngine
> {
  constructor() {
    super('input')
  }

  generateReactWidget(event: GenerateWidgetEvent<InputNodeModel>): JSX.Element {
    return <InputNodeWidget engine={this.engine} node={event.model} />
  }

  generateModel(event: unknown): InputNodeModel {
    throw new Error('The generate model function has not been implemented.')
  }
}
