import { Button, Dialog, useSnackbar } from '@dspace-internal/ui-kit'
import { ExpandMore } from '@mui/icons-material'
import DownloadIcon from '@mui/icons-material/Download'
import { Box, Collapse } from '@mui/material'
import {
  ImportApiError,
  ImportService,
  convertCommaSeparatedListToArray,
} from '@simphera/shared/rest-clients'
import React, { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { GitConfiguration } from '../../../generated/codeGenerator'
import messages from '../../../utils/messages.res'
import {
  BranchInput,
  getDefaultBranchName,
} from '../GitWorkspaceFormFields/BranchInput'
import { CheckoutFoldersInput } from '../GitWorkspaceFormFields/CheckoutFoldersInput'
import { PathToProjectYamlInput } from '../GitWorkspaceFormFields/ProjectYamlFilePath'

interface ImportDialogParameters {
  branch: string
  pathToProjectYaml: string
  checkoutFolders: string
}

interface ImportDialogPropsButton {
  gitConfiguration: GitConfiguration | undefined
  workspaceId: string | undefined
}

export const ImportDialog: React.FC<ImportDialogPropsButton> = ({
  gitConfiguration,
  workspaceId,
}) => {
  const getDefaultValues = () => ({
    branch: getDefaultBranchName(),
    pathToProjectYaml: `${gitConfiguration?.pathToProjectYaml}`,
    checkoutFolders: `${gitConfiguration?.checkoutFolders}`
      .split(',')
      .join(',\n'),
  })

  const showSnackbar = useSnackbar()
  const formProps = useForm<ImportDialogParameters>({
    mode: 'all',
    defaultValues: getDefaultValues(),
  })

  const { formState, handleSubmit, reset } = formProps
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false)

  const toggleShowAdvancedOptions = () =>
    setShowAdvancedOptions(!showAdvancedOptions)

  const handleSave: SubmitHandler<ImportDialogParameters> = async (
    formData
  ) => {
    if (formState.isSubmitting || formState.isSubmitted) {
      return
    }

    ImportService.postImportProjects({
      requestBody: {
        workspaceId,
        branch: formData.branch,
        pathToProjectYaml: formData.pathToProjectYaml,
        checkoutFolders: convertCommaSeparatedListToArray(
          formData.checkoutFolders
        ),
      },
    })
      .then((data) => {
        setShowImportDialog(false)
        showSnackbar(
          messages.GIT_WORKSPACE_IMPORT_STARTED(data.operationId!),
          'info'
        )
        reset(getDefaultValues())
      })
      .catch((error: ImportApiError) => {
        showSnackbar(
          messages.GIT_WORKSPACE_IMPORT_FAILED(
            error.body?.message || 'Unknown reason'
          ),
          'error'
        )
      })
  }

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Button
          icon={<DownloadIcon />}
          color="primary"
          variant="outlined"
          label="Import"
          onClick={() => setShowImportDialog(!showImportDialog)}
        />
        <Dialog
          title="Import from Git"
          maxWidth="md"
          fullWidth
          onClose={() => setShowImportDialog(!showImportDialog)}
          open={showImportDialog}
          primaryAction={{
            label: 'Start Import',
            onClick: handleSubmit(handleSave),
            disabled: !formState.isValid,
          }}
          secondaryAction={[
            {
              label: 'Cancel',
              onClick: () => setShowImportDialog(!showImportDialog),
            },
          ]}
        >
          <Box component="div" marginBottom={4}>
            <BranchInput />
          </Box>
          <Button
            type="button"
            label={`${showAdvancedOptions ? 'Hide' : 'Show'} advanced settings`}
            variant="text"
            color="primary"
            size="small"
            onClick={toggleShowAdvancedOptions}
            endIcon={
              <ExpandMore
                sx={{
                  transition: 'transform ease-in 0.15s',
                  transform: `rotate(${showAdvancedOptions ? 180 : 0}deg)`,
                }}
              />
            }
          />
          <Collapse in={showAdvancedOptions} timeout="auto">
            <Box component="div" marginBottom={4} marginTop={4}>
              <PathToProjectYamlInput isRequired />
            </Box>
            <Box component="div" marginBottom={4}>
              <CheckoutFoldersInput isRequired />
            </Box>
          </Collapse>
        </Dialog>
      </form>
    </FormProvider>
  )
}
