import { ApolloError } from '@apollo/client'
import { Paper, SearchBar } from '@dspace-internal/ui-kit'
import { Box, Typography } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import {
  GitConfiguration,
  GitWorkspaceCommitsQuery,
  InputMaybe,
  KeyValuePairOfStringAndStringInput,
  QuerySettingsInput,
} from '../../../../generated/codeGenerator'
import { ImportDialog } from '../../ImportDialog'
import { GitWorkspaceCommitsTable } from './GitWorkspaceCommitsTable'

export interface GitWorkspaceCommitsProperties {
  error: ApolloError | undefined
  commitsData: GitWorkspaceCommitsQuery | undefined
  commitsQuerySettings: QuerySettingsInput
  gitConfiguration: GitConfiguration | undefined
  loading: boolean
  setCommitsQuerySettings: Dispatch<SetStateAction<QuerySettingsInput>>
  workspaceId: string | undefined
}

const getUpdatedFilters = (
  filterColumn: string,
  filterText: string,
  currentFilters: InputMaybe<KeyValuePairOfStringAndStringInput[]> | undefined
) => {
  if (currentFilters === undefined || currentFilters === null) {
    currentFilters = []
  }

  const filterExists =
    currentFilters.find((filter) => filter.key === filterColumn) !== undefined
  let newFilters = []

  if (filterExists) {
    newFilters = currentFilters.map((filter, i) => {
      if (filter.key === filterColumn) {
        return { key: filterColumn, value: filterText }
      } else {
        return filter
      }
    })
  } else {
    newFilters = [...currentFilters, { key: filterColumn, value: filterText }]
  }

  return newFilters
}

export const GitWorkspaceCommitsPaper: React.FC<
  GitWorkspaceCommitsProperties
> = ({
  commitsData,
  commitsQuerySettings,
  gitConfiguration,
  setCommitsQuerySettings,
  loading,
  workspaceId,
}) => {
  const setUpdatedFilters = (filterColumn: string, filterText: string) => {
    const updatedFilters = getUpdatedFilters(
      filterColumn,
      filterText,
      commitsQuerySettings.filters
    )

    setCommitsQuerySettings({
      ...commitsQuerySettings,
      filters: updatedFilters,
    })
  }

  return (
    <Paper data-testid="ca-paper">
      <Box
        component="div"
        display="flex"
        justifyContent="space-between"
        marginBottom={4}
      >
        <Typography variant="h4">Commits</Typography>
        <ImportDialog
          gitConfiguration={gitConfiguration}
          workspaceId={workspaceId}
        />
      </Box>
      <Box component="div" display="flex" gap={3} marginBottom={4}>
        <SearchBar
          sx={{ flex: 1 }}
          fullWidth
          size="small"
          label="Commit"
          autoComplete="off"
          onChange={(e) => setUpdatedFilters('commit', e.currentTarget.value)}
        />
        <SearchBar
          sx={{ flex: 1 }}
          fullWidth
          label="Branch"
          autoComplete="off"
          onChange={(e) => setUpdatedFilters('branch', e.currentTarget.value)}
        />
        <SearchBar
          sx={{ flex: 1 }}
          fullWidth
          label="Import"
          autoComplete="off"
          onChange={(e) => setUpdatedFilters('project', e.currentTarget.value)}
        />
      </Box>
      <GitWorkspaceCommitsTable
        commitsData={commitsData}
        commitsQuerySettings={commitsQuerySettings}
        setCommitsQuerySettings={setCommitsQuerySettings}
        loading={loading}
      />
    </Paper>
  )
}
