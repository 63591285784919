import { App } from '@simphera/appshell'
import { initializeUserSession } from '@simphera/shared/state'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)

initializeUserSession()
