import { colors } from '@dspace-internal/ui-kit'
import { DefaultLinkProps } from '@projectstorm/react-diagrams'
import { curveBumpX, line } from 'd3-shape'
import React from 'react'

import { useProcessingObjectSelection } from '../../ProcessingObjectSelectionContext'
import { KpiNodeModel } from '../KpiNodes/KpiNodeModel'
import { ProcessingObjectNodeModel } from '../ProcessingObjectNode/ProcessingObjectNodeModel'

export const CurvedLinkWidget: React.FC<DefaultLinkProps> = ({ link }) => {
  const points: [number, number][] = link
    .getPoints()
    .map((point) => [point.getX(), point.getY()])

  const curvedLine = line().curve(curveBumpX)
  const curvedLinePath = curvedLine(points)!

  const node = link.getTargetPort().getNode()
  const nodeId =
    node.getType() === 'processingObject'
      ? (node as ProcessingObjectNodeModel).processingObject.nodeId
      : (node as KpiNodeModel).nodeName
  const { isSelected, isImplicitlySelected } =
    useProcessingObjectSelection(nodeId)

  const targetSelected =
    isSelected || isImplicitlySelected || node.getType() === 'kpiNode'

  return (
    <g>
      <path
        d={curvedLinePath}
        stroke={targetSelected ? colors.gray_70 : colors.gray_60}
        strokeDasharray={targetSelected ? undefined : '5,5'}
        fill="none"
      />
    </g>
  )
}
