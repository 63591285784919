import { ApolloClient, ApolloQueryResult } from '@apollo/client'

import {
  ProjectInfoDocument,
  ProjectInfoQuery,
} from '../generated/codeGenerator'
import { ProjectInfo, ProjectPermissions } from './types'

const parsePermissions = (permissions: Array<string>): ProjectPermissions => {
  // we want to centralize the logic of when a user has specific permissions
  // in the future the permissions might be more complex
  // so we only have to adjust this place for permissions in the UI

  return {
    canRead: permissions.includes('read'),
    canWrite: permissions.includes('write'),
    canExecute: permissions.includes('execute'),
  }
}
// note: this is a temporary solution, sending 2 requests from the FE. In the next step we adjust the BE to combine these 2 requests into 1
export const getProjectAndWorkspaceInfo = async (
  projectId: string,
  client: ApolloClient<unknown>,
  refetchFn: () => void
): Promise<ProjectInfo> => {
  const { data }: ApolloQueryResult<ProjectInfoQuery> = await client.query({
    query: ProjectInfoDocument,
    variables: { id: projectId },
    fetchPolicy: 'no-cache',
  })

  return {
    id: projectId,
    name: data.project.name,
    refetch: refetchFn,
    availableTags: data.availableTags?.map((tag) => ({ name: tag })) ?? [],
    projectPermissions: parsePermissions(data.projectPermissions),
    gitWorkspace: data.project.gitWorkspace || null,
  }
}
