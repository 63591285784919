import { create } from 'zustand'
import { ProjectItemEntry } from './types/ProjectItemEntry'
import { UserInfo } from './types/UserInfo'

export interface AppState {
  userInfo: UserInfo | undefined
  setUserInfo: (userInfo: UserInfo) => void

  projectLinks: Array<ProjectItemEntry>
  setProjectLinks: (links: Array<ProjectItemEntry>) => void
}

export const useAppState = create<AppState>()((set) => ({
  userInfo: undefined,
  setUserInfo: (userInfo: UserInfo) => set(() => ({ userInfo })),

  projectLinks: [],
  setProjectLinks: (links: Array<ProjectItemEntry>) =>
    set(() => ({ projectLinks: links })),
}))

// getters and setters
export const setUserInfo = (userInfo: UserInfo): void =>
  useAppState.getState().setUserInfo(userInfo)
export const getUserInfo = (): UserInfo | undefined =>
  useAppState.getState().userInfo

export const setProjectLinks = (projectLinks: Array<ProjectItemEntry>): void =>
  useAppState.getState().setProjectLinks(projectLinks)
export const getProjectLinks = (): Array<ProjectItemEntry> =>
  useAppState.getState().projectLinks
