import { colors } from '@dspace-internal/ui-kit'
import { TextField } from '@mui/material'
import React from 'react'

import { useReadonlyFormState } from '@simphera/shared/ui-simphera'
import { useKpiGraphEditing } from '../../KpiGraphProvider'
import {
  KpiFloatInputNode,
  KpiGraphNodeComponentProps,
} from '../../kpiGraphTypes'
import KpiNodeFrame, { KpiNodeTitle } from './KpiNodeFrame'

export const FloatInputEditNode: React.FC<
  KpiGraphNodeComponentProps<KpiFloatInputNode>
> = ({ model, engine, node }) => {
  const { isReadonly } = useReadonlyFormState()
  const { updateGraphNode } = useKpiGraphEditing()

  const [inputString, setInputString] = React.useState<string>(
    String(node.config.value)
  )

  return (
    <KpiNodeFrame model={model} engine={engine} frameType="input">
      <KpiNodeTitle nodeName={model.nodeName} node={node} />
      <TextField
        disabled={isReadonly}
        sx={{
          marginTop: '12px',
          fieldset: {
            borderColor: colors.gray_70,
          },
        }}
        defaultValue={node.config.value}
        value={inputString}
        size="small"
        onChange={(event) => {
          const newValue = event.currentTarget.value
          setInputString(newValue)

          const value = Number(newValue)
          if (!isNaN(value)) {
            updateGraphNode<KpiFloatInputNode>(model.nodeName, {
              ...node,
              config: {
                value,
              },
            })
          }
        }}
        onBlur={(event) => {
          if (!event.currentTarget.value) {
            setInputString('0')
          }
        }}
        type="number"
      />
    </KpiNodeFrame>
  )
}

export const FloatInputResultNode: React.FC<
  KpiGraphNodeComponentProps<KpiFloatInputNode>
> = ({ model, engine, node }) => {
  return (
    <KpiNodeFrame model={model} engine={engine} frameType="input">
      <KpiNodeTitle nodeName={model.nodeName} node={node} />
      <span>{node.config.value}</span>
    </KpiNodeFrame>
  )
}
