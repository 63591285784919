import createUrlFor from './createUrlFor'

const project = createUrlFor.project.withId(':projectId')

export const PATHS: any = {
  INDEX: project.stepbt,
  SUITES_COLLECTION: project.stepbt.suite.collection,
  SUITES_NEW: project.stepbt.suite.newPage,
  SUITES_DETAIL: project.stepbt.suite.withId(':suiteId').detailPage,
  TESTCASES_COLLECTION: project.stepbt.testCase.collection,
  TESTCASES_NEW: project.stepbt.suite.withId(':suiteId').newLogicalTestCasePage,
  LOGICALTESTCASE_DETAIL: project.stepbt.suite
    .withId(':suiteId')
    .testcases.withId(':logicalTestCaseId').detailPage,
  TESTCASES_DETAIL: project.stepbt.library
    .withId(':libraryId')
    .testcases.withId(':testCaseId').detailPage,
  TESTCASES_RESULTS: project.stepbt.run
    .withId(':runId')
    .testCaseResult.withId(':testCaseResultId').detailPage,
  RUNS_COLLECTION: project.stepbt.run.collection,
  RUN_DETAIL: project.stepbt.run.withId(':runId').detailPage,
  LIBRARIES_COLLECTION: project.stepbt.library.collection,
}
