/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const urlParams = {
  suiteId: (suiteId: string) => `?suiteId=${suiteId}`,
  linkDate: (linkDate: number) => `?linkDate=${String(linkDate)}`,
}

const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      sut: {
        collection: `/projects/${projectId}/suts`,
        withId: (sutId: string) => ({
          detailPage: `/projects/${projectId}/suts/${sutId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}

export default createUrlFor
