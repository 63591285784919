import { REDIRECT_TO_AFTER_LOG_OUT_LOCAL_STORAGE_KEY } from './constants'
import { userManager } from './userManager'

// note: we only want this sort of redirect once, so we read it out and remove it from localStorage
const getRedirectToAfterLogOut = () => {
  const path = localStorage.getItem(REDIRECT_TO_AFTER_LOG_OUT_LOCAL_STORAGE_KEY)
  localStorage.removeItem(REDIRECT_TO_AFTER_LOG_OUT_LOCAL_STORAGE_KEY)
  return path
}

export const signInRedirectWithState = () => {
  return userManager.signinRedirect({
    url_state:
      getRedirectToAfterLogOut() ||
      document.location.pathname + document.location.search,
  })
}
