/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const urlParams = {
  suiteId: (suiteId: string) => `?suiteId=${suiteId}`,
  linkDate: (linkDate: number) => `?linkDate=${String(linkDate)}`,
}

export const areaScenarioBased = 'scenario-based'

const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      suite: {
        collection: `/projects/${projectId}/${areaScenarioBased}/suites`,
        new: `/projects/${projectId}/${areaScenarioBased}/suites/new`,
        withId: (suiteId: string) => ({
          detailPage: `/projects/${projectId}/${areaScenarioBased}/suites/${suiteId}`,
        }),
      },
      testCase: {
        new: `/projects/${projectId}/${areaScenarioBased}/testcases/new`,
        withId: (testCaseId: string) => ({
          detailPage: `/projects/${projectId}/${areaScenarioBased}/testcases/${testCaseId}`,
        }),
      },
      run: {
        collection: `/projects/${projectId}/${areaScenarioBased}/runs`,
        withId: (runId: string) => ({
          detailPage: `/projects/${projectId}/${areaScenarioBased}/runs/${runId}`,
          testCase: {
            withId: (testCaseId: string) => ({
              detailPage: `/projects/${projectId}/${areaScenarioBased}/runs/${runId}/testcases/${testCaseId}`,
            }),
          },
        }),
      },
      scenario: {
        collection: `/projects/${projectId}/scenarios`,
        withId: (scenarioId: string) => ({
          detailPage: `/projects/${projectId}/scenarios/${scenarioId}`,
        }),
      },
      testEnvironment: {
        collection: `/projects/${projectId}/testenvironments`,
        withId: (testEnvironmentId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${testEnvironmentId}`,
        }),
      },
      sut: {
        collection: `/projects/${projectId}/suts`,
        withId: (sutId: string) => ({
          detailPage: `/projects/${projectId}/suts/${sutId}`,
        }),
      },
      result: {
        withId: (resultId: string) => ({
          detailPage: `/projects/${projectId}/${areaScenarioBased}/results/${resultId}`,
        }),
      },
      vehicle: {
        collection: `/projects/${projectId}/vehicles`,
        withId: (vehicleId: string) => ({
          detailPage: `/projects/${projectId}/vehicles/${vehicleId}`,
        }),
      },
      experiment: {
        withId: (experimentId: string) => ({
          detailPage: `/projects/${projectId}/experiments/${experimentId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}

export default createUrlFor
