import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import { IconButton } from '@mui/material'

export const ProcessingGraphOverlayBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;
`

const padding = 8

export const OverlayLowerLeftContent = styled.div`
  position: absolute;
  left: ${padding}px;
  bottom: ${padding}px;

  width: fit-content;
  height: fit-content;

  display: flex;
  flex-direction: column;

  > * {
    margin-bottom: 4px;
  }

  > *:last-child {
    margin-bottom: 0px;
  }
`

export const GraphControlButton = styled(IconButton)`
  pointer-events: all;
  background-color: ${colors.white};
  border: solid 1px ${colors.gray_40};
  padding: 8px;
`

export const OverlayLowerRightContent = styled.div`
  position: absolute;
  right: ${padding}px;
  bottom: ${padding}px;

  width: fit-content;
  height: fit-content;
`

export const ZoomLevelBox = styled.div`
  background-color: ${colors.white};
  border: solid 1px ${colors.gray_40};

  width: fit-content;
  height: fit-content;

  padding: 4px 8px;
`
