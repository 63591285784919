import { Paper } from '@dspace-internal/ui-kit'
import { Box } from '@mui/material'
import { KpiDto } from '@simphera/shared/rest-clients'
import {
  ReadonlyFormStateProvider,
  SwaggerLink,
} from '@simphera/shared/ui-simphera'
import {
  InfoMessageContainer,
  KpiGraph,
  KpiGraphEditingProvider,
  KpiGraphProvider,
  ProcessingGraphDiagram,
} from '@simphera/shared/ui-validation'
import { useController } from 'react-hook-form'
import { messages_res } from '../../../../resources/messages.res'

export interface KpiGraphPaperProps {}

export const KpiGraphPaper: React.FC<KpiGraphPaperProps> = () => {
  const { field } = useController<KpiDto, 'graph'>({ name: 'graph' })

  const kpiGraph = field.value ? (field.value as KpiGraph) : undefined
  const isKpiGraphEmpty = !kpiGraph || Object.keys(kpiGraph.nodes).length === 0

  return (
    <Paper>
      {isKpiGraphEmpty ? (
        <InfoMessageContainer
          variant="info"
          message={
            <>
              <div>{messages_res.NO_KPI_GRAPH()}</div>
              <SwaggerLink />
            </>
          }
        />
      ) : (
        <KpiGraphProvider kpiGraph={kpiGraph}>
          <KpiGraphEditingProvider
            kpiGraph={kpiGraph}
            onKpiGraphChanged={(newKpiGraph) =>
              field.onChange(JSON.stringify(newKpiGraph))
            }
          >
            <Box component="div" width="100%" height="60vh">
              {/** Overriding the readonly state to disable editing for now. Graph configuration is not yet implemented. */}
              <ReadonlyFormStateProvider isReadonly={true}>
                <ProcessingGraphDiagram
                  processingObjects={[]}
                  links={[]}
                  selectedProcessingObjects={[]}
                  processingObjectNodeContentComponent={() => <></>}
                  getNodeData={() => {}}
                  kpiGraph={kpiGraph}
                  mode="edit"
                />
              </ReadonlyFormStateProvider>
            </Box>
          </KpiGraphEditingProvider>
        </KpiGraphProvider>
      )}
    </Paper>
  )
}
