/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GraphConfigOverrideDto } from '../models/GraphConfigOverrideDto';
import type { GraphValidationResultDto } from '../models/GraphValidationResultDto';
import type { JsonPatchDocument } from '../models/JsonPatchDocument';
import type { KpiDto } from '../models/KpiDto';
import type { KpiDtoCollectionResponse } from '../models/KpiDtoCollectionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class KpisService {

    /**
     * List KPIs
     *
     * List KPIs of a Project in a paged format.
     * ---
     * * The default number of listed items for one page equals 500. This is the maximum number of items the server returns for one request.
     * * You can control the number of items by setting the `pageSize` lower then the maximum number.
     * * The server response includes a list of items and a reference to the next page named `nextPageToken`.
     * * The first page can be retrieved by leaving out the `nextPageToken`.
     * * A `nextPageToken` is only valid for a single given `pageSize` it has been created for.
     * * If the total amount of requested items exceeds the `pageSize`, the `nextPageToken` will be returned and can be used to get the next page of items.
     * * A new `nextPageToken` is provided on the following pages until all the items are retrieved.
     * * When all items are retrieved the `nextPageToken` will be returned as an empty string.
     * @returns KpiDtoCollectionResponse Success
     * @throws ApiError
     */
    public static getKpis({
        projectId,
        pageSize,
        pageToken,
        filter,
    }: {
        projectId: string,
        pageSize?: number,
        pageToken?: string,
        /**
         * Uses the OData Version 2 filter syntax e.g.
         *
         * modifiedBy/userName eq 'John Doe'
         *
         * substringof('KPI',Name) eq true'
         *
         * createdOn eq datetime'2023-09-11T06:24:44.492Z'
         */
        filter?: string,
    }): CancelablePromise<KpiDtoCollectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kpis',
            query: {
                'projectId': projectId,
                'pageSize': pageSize,
                'pageToken': pageToken,
                'filter': filter,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get a single KPI
     *
     * Get a single KPI.
     * ---
     *
     * @returns KpiDto Success
     * @throws ApiError
     */
    public static getKpis1({
        kpiId,
        projectId,
    }: {
        kpiId: string,
        projectId: string,
    }): CancelablePromise<KpiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kpis/{kpiId}',
            path: {
                'kpiId': kpiId,
            },
            query: {
                'projectId': projectId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Patch a single KPI
     *
     * Patch a single KPI of a Project.
     * ---
     *
     * @returns KpiDto Success
     * @throws ApiError
     */
    public static patchKpis({
        kpiId,
        projectId,
        requestBody,
    }: {
        kpiId: string,
        projectId: string,
        requestBody?: JsonPatchDocument,
    }): CancelablePromise<KpiDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/kpis/{kpiId}',
            path: {
                'kpiId': kpiId,
            },
            query: {
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Delete a single KPI
     *
     * Delete a single KPI of a Project.
     * ---
     *
     * @returns any Success
     * @throws ApiError
     */
    public static deleteKpis({
        kpiId,
        projectId,
    }: {
        kpiId: string,
        projectId: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/kpis/{kpiId}',
            path: {
                'kpiId': kpiId,
            },
            query: {
                'projectId': projectId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Validate the graph of a KPI
     *
     * Validates the graph of a KPI and optionally merges the graph with an override object that contains new configuration values for selected nodes.
     * ---
     * The server response contains the validated and merged KPI graph and a list of issues that occurred.
     * * If errors occurred the `graph` property will be empty and the `issues` properties will contain a list of issues.
     * * If no errors occurred the `graph` property will contain the merged and validated graph object.
     * @returns GraphValidationResultDto Success
     * @throws ApiError
     */
    public static postKpisValidateConfig({
        kpiId,
        projectId,
        requestBody,
    }: {
        kpiId: string,
        projectId: string,
        requestBody?: GraphConfigOverrideDto,
    }): CancelablePromise<GraphValidationResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/kpis/{kpiId}/validate-config',
            path: {
                'kpiId': kpiId,
            },
            query: {
                'projectId': projectId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
