import { mdiFitToPageOutline as zoomToFitIconPath } from '@mdi/js'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { Fade, SvgIcon } from '@mui/material'
import {
  DiagramEngine,
  DiagramListener,
  DiagramModel,
} from '@projectstorm/react-diagrams'
import React from 'react'

import {
  GraphControlButton,
  OverlayLowerLeftContent,
  OverlayLowerRightContent,
  ProcessingGraphOverlayBox,
  ZoomLevelBox,
} from './ProcessingGraphOverlay.styles'

export interface ProcessingGraphOverlayProps {
  engine: DiagramEngine
  model: DiagramModel
  zoomToFit: () => void
  boxRef: React.RefObject<HTMLDivElement>
}

/** The zoom factor when scrolling is hard coded in react-diagrams and it's 1.66666 */
const zoomFactor = 5 / 3

const ProcessingGraphOverlay: React.FC<ProcessingGraphOverlayProps> = ({
  engine,
  model,
  zoomToFit,
  boxRef,
}) => {
  const [showOverlay, setShowOverlay] = React.useState(false)
  const [zoomLevel, setZoomLevel] = React.useState(model.getZoomLevel())

  React.useEffect(() => {
    const listener: DiagramListener = {
      zoomUpdated: () => {
        setZoomLevel(model.getZoomLevel())
      },
    }

    model.registerListener(listener)
  }, [model])

  React.useEffect(() => {
    if (!boxRef.current) {
      return
    }

    const box = boxRef.current
    const handleMouseEnter = () => {
      setShowOverlay(true)
    }
    const handleMouseLeave = () => {
      setShowOverlay(false)
    }

    box.addEventListener('mouseenter', handleMouseEnter)
    box.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      box.removeEventListener('mouseenter', handleMouseEnter)
      box.removeEventListener('mouseleave', handleMouseLeave)
    }
  })

  const handleZoomInClicked = () => {
    model.setZoomLevel(model.getZoomLevel() + zoomFactor)
    engine.repaintCanvas()
  }

  const handleZoomOutClicked = () => {
    model.setZoomLevel(model.getZoomLevel() - zoomFactor)
    engine.repaintCanvas()
  }

  return (
    <ProcessingGraphOverlayBox>
      <Fade in={showOverlay}>
        <OverlayLowerLeftContent>
          <GraphControlButton onClick={handleZoomInClicked}>
            <ZoomInIcon />
          </GraphControlButton>
          <GraphControlButton onClick={handleZoomOutClicked}>
            <ZoomOutIcon />
          </GraphControlButton>
          <GraphControlButton onClick={zoomToFit}>
            <SvgIcon>
              <path d={zoomToFitIconPath} />
            </SvgIcon>
          </GraphControlButton>
        </OverlayLowerLeftContent>
      </Fade>

      <Fade in={showOverlay}>
        <OverlayLowerRightContent>
          <ZoomLevelBox>{Math.round(zoomLevel) + '%'}</ZoomLevelBox>
        </OverlayLowerRightContent>
      </Fade>
    </ProcessingGraphOverlayBox>
  )
}

export default ProcessingGraphOverlay
