import React from 'react'

import { createProviderHook } from '@simphera/shared/ui-simphera'
import { KpiGraph, KpiGraphNode } from '../kpiGraphTypes'

export interface KpiGraphEditingContextData {
  updateGraphNode: <TNode extends KpiGraphNode>(
    nodeName: string,
    updatedNode: TNode
  ) => void
}

const KpiGraphEditingContext = React.createContext<
  KpiGraphEditingContextData | undefined
>(undefined)

export interface KpiGraphEditingProviderProps {
  kpiGraph: KpiGraph
  onKpiGraphChanged: (newKpiGraph: KpiGraph) => void
}

export const KpiGraphEditingProvider: React.FC<
  React.PropsWithChildren<KpiGraphEditingProviderProps>
> = ({ kpiGraph, onKpiGraphChanged, children }) => {
  const updateGraphNode = <TNode extends KpiGraphNode>(
    nodeName: string,
    updatedNode: TNode
  ) => {
    const newKpiGraph: KpiGraph = { ...kpiGraph, nodes: { ...kpiGraph.nodes } }
    newKpiGraph.nodes[nodeName] = updatedNode
    onKpiGraphChanged(newKpiGraph)
  }

  return (
    <KpiGraphEditingContext.Provider value={{ updateGraphNode }}>
      {children}
    </KpiGraphEditingContext.Provider>
  )
}

export const useKpiGraphEditing = createProviderHook(
  KpiGraphEditingContext,
  'KpiGraphEditingProvider',
  'useKpiGraphEditing'
)
