import { Paper, TextField } from '@dspace-internal/ui-kit'
import { Grid } from '@mui/material'
import { KpiDto } from '@simphera/shared/rest-clients'
import { createValidator } from '@simphera/shared/ui-simphera'
import { useFormContext } from 'react-hook-form'
import { labels_res } from '../../../resources/labels.res'
import { messages_res } from '../../../resources/messages.res'

const NameInput: React.FC<KpiPropertiesPaperProps> = ({ isReadonly }) => {
  const { register, getFieldState } = useFormContext<KpiDto>()

  const { error } = getFieldState('name')

  return (
    <TextField
      id="kpiName"
      {...register('name', {
        required: messages_res.NAME_EMPTY(),
        validate: createValidator.requiredString(messages_res.NAME_EMPTY()),
      })}
      label={labels_res.NAME()}
      fullWidth
      autoFocus
      error={Boolean(error)}
      helperText={error?.message}
      disabled={isReadonly}
    />
  )
}

const DescriptionInput: React.FC<KpiPropertiesPaperProps> = ({
  isReadonly,
}) => {
  const { register } = useFormContext<KpiDto>()

  return (
    <TextField
      {...register('description')}
      id="kpiDescription"
      label={labels_res.DESCRIPTION()}
      fullWidth
      multiline
      disabled={isReadonly}
    />
  )
}

export interface KpiPropertiesPaperProps {
  isReadonly: boolean
}

export const KpiPropertiesPaper: React.FC<KpiPropertiesPaperProps> = ({
  isReadonly,
}) => {
  return (
    <Paper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <NameInput isReadonly={isReadonly} />
        </Grid>
        <Grid item xs={12}>
          <DescriptionInput isReadonly={isReadonly} />
        </Grid>
      </Grid>
    </Paper>
  )
}
