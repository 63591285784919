/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GitWorkspaceResponseDto } from '../models/GitWorkspaceResponseDto';
import type { GitWorkspaceResponseDtoCollectionResponse } from '../models/GitWorkspaceResponseDtoCollectionResponse';
import type { JsonPatchDocument } from '../models/JsonPatchDocument';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GitWorkspacePreviewService {

    /**
     * List Git Workspaces
     *
     * List Git Workspaces.
     * ---
     * Git Workspace Ids are unique.
     * @returns GitWorkspaceResponseDtoCollectionResponse Success
     * @throws ApiError
     */
    public static getGitWorkspaces({
        pageSize,
        pageToken,
    }: {
        pageSize?: number,
        pageToken?: string,
    }): CancelablePromise<GitWorkspaceResponseDtoCollectionResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/git-workspaces',
            query: {
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get a single Git Workspace
     *
     * Get a single Git Workspace.
     * ---
     * Git Workspace Ids are unique.
     * @returns GitWorkspaceResponseDto Success
     * @throws ApiError
     */
    public static getGitWorkspaces1({
        gitWorkspaceId,
    }: {
        gitWorkspaceId: string,
    }): CancelablePromise<GitWorkspaceResponseDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/git-workspaces/{gitWorkspaceId}',
            path: {
                'gitWorkspaceId': gitWorkspaceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Create or update a single Git Workspace
     *
     * Create or update a single Git Workspace.
     * ---
     * If a Git Workspace with a specified Id already exists, it will be updated, otherwise a new Git Workspace will be created. It allows partial updates in a standards compliant way.
     *
     * More Information about JSON Patch can be found [here](http://jsonpatch.com/).
     * @returns GitWorkspaceResponseDto Success
     * @throws ApiError
     */
    public static patchGitWorkspaces({
        gitWorkspaceId,
        requestBody,
    }: {
        gitWorkspaceId: string,
        requestBody?: JsonPatchDocument,
    }): CancelablePromise<GitWorkspaceResponseDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/git-workspaces/{gitWorkspaceId}',
            path: {
                'gitWorkspaceId': gitWorkspaceId,
            },
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
