/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const urlParams = {
  suiteId: (suiteId: string) => `?suiteId=${suiteId}`,
  linkDate: (linkDate: number) => `?linkDate=${String(linkDate)}`,
}

const stepbtUrl = (projectId: string): string =>
  `/projects/${projectId}/step-based`

const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      stepbt: {
        detailPage: stepbtUrl(projectId),
        suite: {
          collection: `${stepbtUrl(projectId)}/suites`,
          newPage: `${stepbtUrl(projectId)}/suites/new`,
          withId: (suiteId: string) => ({
            detailPage: `${stepbtUrl(projectId)}/suites/${suiteId}`,
            newLogicalTestCasePage: `${stepbtUrl(
              projectId
            )}/suites/${suiteId}/testcases/new`,
            testcases: {
              withId: (logicalTestCaseId: string) => ({
                detailPage: `${stepbtUrl(
                  projectId
                )}/suites/${suiteId}/testcases/${logicalTestCaseId}`,
              }),
            },
          }),
        },
        testCase: {
          collection: `${stepbtUrl(projectId)}/testcases`,
          withId: (testCaseId: string) => ({
            detailPage: `${stepbtUrl(projectId)}/testcases/${testCaseId}`,
          }),
        },
        run: {
          collection: `${stepbtUrl(projectId)}/runs`,
          withId: (runId: string) => ({
            detailPage: `${stepbtUrl(projectId)}/runs/${runId}`,
            testCaseResult: {
              withId: (testCaseResultId: string) => ({
                detailPage: `${stepbtUrl(
                  projectId
                )}/runs/${runId}/testcases/${testCaseResultId}`,
              }),
            },
          }),
        },
        library: {
          collection: `${stepbtUrl(projectId)}/libraries`,
          withId: (libraryId: string) => ({
            testcases: {
              withId: (testCaseId: string) => ({
                detailPage: `${stepbtUrl(
                  projectId
                )}/libraries/${libraryId}/testcases/${testCaseId}`,
              }),
            },
          }),
        },
      },
      testenvironment: {
        withId: (teId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${teId}`,
        }),
      },
      experiment: {
        withId: (experimentId: string) => ({
          detailPage: `/projects/${projectId}/experiments/${experimentId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}

export default createUrlFor
