import {
  AbstractReactFactory,
  GenerateWidgetEvent,
} from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import React from 'react'
import { ProcessingObjectNodeModel } from './ProcessingObjectNodeModel'
import ProcessingObjectNodeWidget, {
  ProcessingObjectNodeContentProps,
} from './ProcessingObjectNodeWidget'

export class ProcessingObjectNodeFactory<
  TNodeData
> extends AbstractReactFactory<ProcessingObjectNodeModel, DiagramEngine> {
  ContentComponent: React.FC<ProcessingObjectNodeContentProps<TNodeData>>

  constructor(
    contentComponent: React.FC<ProcessingObjectNodeContentProps<TNodeData>>
  ) {
    super('processingObject')

    this.ContentComponent = contentComponent
  }

  generateReactWidget(
    event: GenerateWidgetEvent<ProcessingObjectNodeModel>
  ): JSX.Element {
    return (
      <ProcessingObjectNodeWidget
        engine={this.engine}
        node={event.model}
        ContentComponent={this.ContentComponent}
      />
    )
  }

  generateModel(event: unknown): ProcessingObjectNodeModel {
    throw new Error('The generate model function has not been implemented.')
  }
}
