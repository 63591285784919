import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'

/** Layout of the info message container. */
export const InfoMessageWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    vertical-align: middle;
  }
`

/** The text that is displayed in the info message container. */
export const InfoMessageText = styled.div`
  color: ${colors.gray_60};
  font-size: 16px;
  margin-left: 12px;
`
