import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const ScrollToTop: React.FC = () => {
  // Reference:   https://reactrouter.com/web/guides/scroll-restoration
  // This implementation might need adjustments when we need to
  // support named anchors (e.g: http://www.site.com/page#section)
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default ScrollToTop
