import { colors } from '@dspace-internal/ui-kit'
import { Box } from '@mui/material'
import { TextWithOverflow } from '@simphera/shared/ui-simphera'
import React from 'react'

import { useProcessingObjectSelection } from '../../ProcessingObjectSelectionContext'
import {
  NodeBaseProps,
  NodeFrame,
  NodePorts,
} from '../ProcessingGraphNodeBasics'
import { InputNodeModel } from './InputNodeModel'

export interface InputNodeWidgetProps extends NodeBaseProps<InputNodeModel> {}

const InputNodeWidget: React.FC<InputNodeWidgetProps> = ({ engine, node }) => {
  const { isImplicitlySelected } = useProcessingObjectSelection(
    node.processingObject.nodeId
  )

  return (
    <NodeFrame
      $width={140}
      $color={colors.gray_30}
      $isSelected={isImplicitlySelected}
      $type="input"
    >
      <Box
        component="div"
        display="flex"
        alignItems="start"
        flexDirection="column"
        overflow="hidden"
      >
        <span style={{ fontSize: '10px', fontWeight: 500 }}>INPUT</span>
        <TextWithOverflow>{node.processingObject.label}</TextWithOverflow>
      </Box>
      <NodePorts engine={engine} ports={node.getPorts()} />
    </NodeFrame>
  )
}

export default InputNodeWidget
