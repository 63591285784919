import { colors } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import CancelOutlined from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import OfflineBoltOutlined from '@mui/icons-material/OfflineBoltOutlined'
import ReportOutlined from '@mui/icons-material/ReportOutlined'
import Timer from '@mui/icons-material/Timer'

import { styled as muiStyled } from '@mui/material/styles'
import AbortedIcon from './AbortedIcon'
import AbortingIcon from './AbortingIcon'
import EvaluatingIcon from './EvaluatingIcon'
import NoResultIcon from './NoResultIcon'
import ProcessingIcon from './ProcessingIcon'
import { resultStateColors } from './resultStateColors'

export const Container = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
`

export const PendingIconColor = muiStyled(Timer)({
  color: resultStateColors.pending,
})

export const ProcessingIconColor = muiStyled(ProcessingIcon)({
  color: resultStateColors.processing,
})

export const BlockedIconColor = muiStyled(ReportOutlined)({
  color: resultStateColors.blocked,
})

export const FailedIconColor = muiStyled(CancelOutlined)({
  color: resultStateColors.failed,
})

export const PassedIconColor = muiStyled(CheckCircleOutlined)({
  color: resultStateColors.passed,
})

export const ErrorIconColor = muiStyled(OfflineBoltOutlined)({
  color: resultStateColors.error,
})

export const EvaluatingIconColor = muiStyled(EvaluatingIcon)({
  color: resultStateColors.evaluating,
})

export const AbortingIconColor = muiStyled(AbortingIcon)({
  color: resultStateColors.aborting,
})

export const AbortedIconColor = muiStyled(AbortedIcon)({
  color: resultStateColors.aborted,
})

export const NoResultIconColor = muiStyled(NoResultIcon)({
  color: colors.chart_13,
})
