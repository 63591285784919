import {
  AbstractReactFactory,
  GenerateWidgetEvent,
} from '@projectstorm/react-canvas-core'
import { DiagramEngine } from '@projectstorm/react-diagrams-core'
import React from 'react'

import { useKpiGraph } from '../../KpiGraphProvider'
import { kpiGraphNodeTypeNodeComponentMapping } from '../../kpiGraphTypes'
import { KpiNodeModel } from './KpiNodeModel'

export type KpiGraphMode = 'edit' | 'result'

interface KpiNodeReactWidgetProps {
  event: GenerateWidgetEvent<KpiNodeModel>
  engine: DiagramEngine
  mode: KpiGraphMode
}

const KpiNodeReactWidget: React.FC<KpiNodeReactWidgetProps> = ({
  event,
  engine,
  mode,
}) => {
  const { getKpiNode } = useKpiGraph()

  const node = getKpiNode(event.model.nodeName)

  const components = kpiGraphNodeTypeNodeComponentMapping[node.type]
  const NodeComponent =
    mode === 'edit' ? components.EditComponent : components.ResultComponent

  return <NodeComponent engine={engine} node={node} model={event.model} />
}

export class KpiNodeFactory extends AbstractReactFactory<
  KpiNodeModel,
  DiagramEngine
> {
  mode: KpiGraphMode

  constructor(mode: KpiGraphMode) {
    super('kpiNode')
    this.mode = mode
  }

  generateReactWidget(event: GenerateWidgetEvent<KpiNodeModel>): JSX.Element {
    return (
      <KpiNodeReactWidget event={event} engine={this.engine} mode={this.mode} />
    )
  }

  generateModel(event: unknown): KpiNodeModel {
    throw new Error('The generate model function has not been implemented.')
  }
}
