import { useSnackbar } from '@dspace-internal/ui-kit'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectsQuery, useProjectsQuery } from '../generated/codeGenerator'
import messages from '../utils/messages.res'

const noop = () => {}
const hasCreatePermissions = (data: ProjectsQuery | undefined) =>
  data?.globalProjectPermissions.includes('create') || false

/** only shows route when create permissions exist */
export const HasCreatePermissionsGuard: React.FC<{
  redirectTo: string
  /** pass as function to avoid rendering before check */
  elementRenderFn: () => React.ReactElement
  snackbarMessage?: string
}> = ({
  redirectTo,
  elementRenderFn,
  snackbarMessage = messages.NO_PERMISSIONS_TO_VIEW_PAGE(),
}) => {
  const showSnackbar = useSnackbar()
  const navigate = useNavigate()

  const { loading, data } = useProjectsQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!hasCreatePermissions(data)) {
        showSnackbar(snackbarMessage, 'warning', {
          label: 'Dismiss',
          callback: noop,
        })
        navigate(redirectTo)
      }
    },
  })

  if (!loading && hasCreatePermissions(data)) {
    return elementRenderFn()
  }
}
