import { Dialog } from '@dspace-internal/ui-kit'
import { Box, DialogContentText, Link } from '@mui/material'
import { GlobalConfig } from '@simphera/shared/appconfig'
import React from 'react'

interface AboutDialogProps {
  isOpen: boolean
  onCloseDialog: () => void
}

const AboutDialog: React.FC<AboutDialogProps> = ({ isOpen, onCloseDialog }) => {
  return (
    <Dialog
      primaryAction={{ label: 'Close', onClick: onCloseDialog }}
      fullWidth={true}
      maxWidth="md"
      open={isOpen}
      onClose={onCloseDialog}
      title="About dSPACE SIMPHERA"
    >
      <DialogContentText component="div">
        <Box component="div" fontWeight="fontWeightBold" my={1}>
          Version {GlobalConfig.appConfig.PRODUCT_VERSION}
          {' ('}
          <Link href="/help/releasenotes/" target="_blank" rel="noreferrer">
            What's new
          </Link>
          {')'}
        </Box>
        <Box component="div" my={1} pt={4}>
          SIMPHERA is made possible by open source software. Download{' '}
          <Link href="/SIMPHERA_LegalNotices.zip">SIMPHERA Legal Notices</Link>
        </Box>
        <Box component="div" my={1} pt={2}>
          &#169; dSPACE GmbH. All rights reserved.
        </Box>
      </DialogContentText>
    </Dialog>
  )
}

export default AboutDialog
