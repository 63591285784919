import { Table, TableContainer } from '@mui/material'
import React from 'react'

import type { DataTableCellFormatterProps } from './DataTableCellFormatter'
import type { DataTableConfig } from './DataTableConfig/useDataTableConfig'
import DataTableToolbar from './DataTableToolbar'

export interface DataTableColumn<TCellValue = any> {
  fieldName: string
  title: string
  sortable: boolean
  sortField?: string
  width?: string | number
  getCellValue?: (row: any) => TCellValue
  CellFormatter?: React.FC<DataTableCellFormatterProps<TCellValue>>
}

export interface DataTableState<TRow> {
  rows: TRow[]
  columns: DataTableColumn[]
  loading: boolean
  config: DataTableConfig
  setConfig: (config: DataTableConfig) => void
}

const DataTableStateContext = React.createContext<
  DataTableState<unknown> | undefined
>(undefined)

export interface DataTableProps<TRow> {
  rows: TRow[]
  columns: DataTableColumn[]
  loading?: boolean
  toolbarContent?: React.ReactNode
  config: DataTableConfig
  setConfig: (config: DataTableConfig) => void
}

const DataTable = <TRow,>({
  rows,
  columns,
  loading = false,
  toolbarContent,
  config,
  setConfig,
  children,
}: React.PropsWithChildren<DataTableProps<TRow>>): JSX.Element => {
  return (
    <TableContainer>
      <DataTableStateContext.Provider
        value={{ rows, columns, loading, config, setConfig }}
      >
        {toolbarContent && (
          <DataTableToolbar>{toolbarContent}</DataTableToolbar>
        )}
        <Table>{children}</Table>
      </DataTableStateContext.Provider>
    </TableContainer>
  )
}

export const useDataTableState = <TRow,>(): DataTableState<TRow> => {
  const context = React.useContext(DataTableStateContext)

  if (context === undefined) {
    // useDataTableState was called outside of a DataTable
    // => Throw an error to notify the developer
    throw new Error(
      'The useDataTableState() hook must only be called within a DataTable.'
    )
  }

  return context as DataTableState<TRow>
}

export default DataTable
