import { TextField } from '@dspace-internal/ui-kit'
import {
  convertArrayToCommaSeparatedList,
  convertCommaSeparatedListToArray,
} from '@simphera/shared/rest-clients'
import { createValidator } from '@simphera/shared/ui-simphera'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../../utils/messages.res'
import { DESCRIPTION_CHAR_LIMIT } from './constants'
import { IReadOnly, IRequired } from './types'

export const CheckoutFoldersInput: React.FC<IReadOnly & IRequired> = ({
  isReadOnly = false,
  isRequired = false,
}) => {
  const { control, formState, setValue, getValues } =
    useFormContext<Required<{ checkoutFolders: string }>>()
  const error = formState.errors.checkoutFolders

  const sanitizeValue = () => {
    const value = getValues('checkoutFolders')
    const valueAsArray = convertCommaSeparatedListToArray(value)
    const cleanValue = convertArrayToCommaSeparatedList(valueAsArray)
    setValue('checkoutFolders', cleanValue)
  }

  return (
    <Controller
      control={control}
      name="checkoutFolders"
      defaultValue=""
      rules={{
        required: isRequired
          ? messages.INPUT_CANNOT_BE_EMPTY('checkout folders')
          : false,
        validate: {
          isValidLength: createValidator.requiredStringLength(
            messages.MAXIMUM_CHARACTERS(DESCRIPTION_CHAR_LIMIT),
            DESCRIPTION_CHAR_LIMIT
          ),
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={Boolean(error)}
          fullWidth
          helperText={
            error
              ? error.message
              : 'Provide a comma-separated list of paths relative to the repository URL'
          }
          label="Checkout Folders"
          multiline
          size="small"
          name="checkoutFolders"
          disabled={isReadOnly}
          placeholder="path/to/folder1, path/to/folder2"
          autoComplete="off"
          InputLabelProps={{ shrink: true }}
          onBlur={sanitizeValue}
          required={isRequired}
        />
      )}
    />
  )
}
