import { mdiCloseOctagonOutline, mdiCog } from '@mdi/js'
import { SvgIcon } from '@mui/material'
import React from 'react'

export interface AbortingIconProps {
  animated?: boolean
}

const AbortingIcon: React.FC<AbortingIconProps> = ({
  animated = true,
  ...props
}) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <defs>
        <mask id="mask">
          <rect width="100%" height="100%" fill="white" />
          <circle cx="18" cy="18" r="6.7" fill="black" />
        </mask>
      </defs>
      <g mask="url(#mask)">
        <path d={mdiCloseOctagonOutline}></path>
      </g>
      <g transform="scale(0.49) translate(24,24)">
        <path d={mdiCog}>
          {animated && (
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 12 12"
              to="360 12 12"
              dur="3s"
              repeatCount="indefinite"
            />
          )}
        </path>
      </g>
    </SvgIcon>
  )
}

export default AbortingIcon
