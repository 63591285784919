import IntlMessageFormat from 'intl-messageformat'

export const fmt = (message: string, ...rest: any[]): string => {
  const formatter = new IntlMessageFormat(message, 'en-US')
  const formatted = formatter.format(...rest) as string
  return formatted
}

const messages = {
  API_KEY_LOAD_ERROR: () => 'Could not load API keys',
  API_KEY_WAS_REVOKED: () => 'API key was revoked',
  API_KEY_COULD_NOT_BE_REVOKED: () => 'API key could not be revoked',
  API_KEY_DESCRIPTION: () =>
    'Find all your API keys below. These can be used to authenticate to the Rest API as an alternative to username and password authentication.',
  API_KEY_REVOKE_CONFIRM: () => 'Do you really want to revoke the API key?',
  API_KEY_REVOKE_DESCRIPTION: () =>
    `The API key can not be restored once you've revoked it.`,

  API_KEY_CREATED: () => 'API key created',
  API_KEY_CREATION_ERROR: () => 'API key could not be created',
  API_KEY_COPIED_TO_CLIPBOARD: () => 'API key copied to clipboard',
  API_KEY_ADDED: () =>
    'You have successfully added a new API key. Copy it now!',
  API_KEY_COPY_HINT: () =>
    `Make sure you copy the above API key now. You won't be able to see it again.`,

  UNSAVED_CHANGES: () =>
    'There are unsaved modifications on this page. Close and lose changes?',

  MAXIMUM_CHARACTERS: (length: number) =>
    fmt(`A maximum of {length} characters is allowed.`, { length }),
  INPUT_CANNOT_BE_EMPTY: (fieldName: string) =>
    `The ${fieldName} cannot be empty.`,
}

export default messages
