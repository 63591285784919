import { Button, useBreadcrumbs, useSnackbar } from '@dspace-internal/ui-kit'
import { Save } from '@mui/icons-material'
import {
  GitWorkspaceApiError,
  GitWorkspaceResponse,
  PatchGitWorkspaceParameters,
  convertArrayToCommaSeparatedList,
  editGitWorkspace,
} from '@simphera/shared/rest-clients'
import {
  ChangeInformation,
  CustomPageHeader,
  ReadonlyChipWithTooltip,
  usePrompt,
} from '@simphera/shared/ui-simphera'
import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useLoaderData, useNavigate } from 'react-router-dom'
import createBreadcrumbsFor from '../../../createBreadcrumbsFor'
import messages from '../../../utils/messages.res'
import { GitWorkspaceFormFields } from '../GitWorkspaceFormFields/GitWorkspaceFormFields'

const convertToFormData = (
  data: GitWorkspaceResponse
): PatchGitWorkspaceParameters => ({
  name: `${data.name}`,
  description: `${data.description}`,
  repositoryUrl: `${data.gitConfiguration?.repositoryUrl}`,
  repositoryUsername: `${data.gitConfiguration?.username}`,
  repositoryPassword: '',
  pathToProjectYaml: `${data.gitConfiguration?.pathToProjectYaml}`,
  lfsUsername: `${data.gitConfiguration?.lfsConfiguration?.username}`,
  lfsPassword: '',
  checkoutFolders: convertArrayToCommaSeparatedList(
    data.gitConfiguration?.checkoutFolders || []
  ),
})

export const GitWorkspaceEditPage: React.FC = () => {
  const showSnackbar = useSnackbar()
  const { setBreadcrumbs } = useBreadcrumbs()
  const navigate = useNavigate()
  const data = useLoaderData() as GitWorkspaceResponse
  const formProps = useForm<PatchGitWorkspaceParameters>({
    defaultValues: convertToFormData(data),
    mode: 'all',
  })
  const { formState, handleSubmit, reset } = formProps
  usePrompt(`\n${messages.UNSAVED_CHANGES()}`, formState.isDirty)

  useEffect(() => {
    if (data.name) {
      setBreadcrumbs(createBreadcrumbsFor.GIT_WORKSPACE_EDIT(data.name))
    }
  }, [data, setBreadcrumbs])

  const handleSave: SubmitHandler<PatchGitWorkspaceParameters> = async (
    formData
  ) => {
    if (formState.isSubmitting || formState.isSubmitted) {
      return
    }

    editGitWorkspace(
      data.id!,
      formData,
      // only update passwords when explicitly changed
      Boolean(formState.dirtyFields.repositoryPassword),
      Boolean(formState.dirtyFields.lfsPassword)
    )
      .then((updatedData) => {
        reset(convertToFormData(updatedData))
        showSnackbar(messages.GIT_WORKSPACE_UPDATED(), 'info')

        // navigating after timeout so that usePrompt can be disabled. ugly, but prettier than the other options
        setTimeout(() => navigate(`/gitWorkspaces/${data.id}`))
      })
      .catch((error: GitWorkspaceApiError) => {
        console.error(error)
        showSnackbar(
          messages.GIT_WORKSPACE_UPDATE_ERROR(error.message),
          'error'
        )
      })
  }

  const SaveButton = () => (
    <Button
      color="primary"
      variant="contained"
      icon={<Save />}
      label="SAVE"
      type="submit"
      value="Submit"
      disabled={!formState.isValid}
    />
  )

  // TODO
  const canWrite = true

  return (
    <FormProvider {...formProps}>
      <form onSubmit={handleSubmit(handleSave)}>
        <CustomPageHeader
          id="GIT_WORKSPACE_EDIT"
          primaryAction={canWrite && <SaveButton />}
          subtitle="Edit Workspace"
          title={data.name!}
          titleEndAdornment={!canWrite && <ReadonlyChipWithTooltip />}
        />
        <ChangeInformation
          createdOn={new Date(data.createdOn!).getTime() / 1000}
          createdById={data.createdBy!.userId!}
          createdByName={data.createdBy!.userName!}
          modifiedOn={new Date(data.modifiedOn!).getTime() / 1000}
          modifiedById={data.modifiedBy!.userId!}
          modifiedByName={data.modifiedBy!.userName!}
        />
        <GitWorkspaceFormFields isReadOnly={!canWrite} isEditMode />
      </form>
    </FormProvider>
  )
}
