import { colors } from '@dspace-internal/ui-kit'
import CheckCircleFilled from '@mui/icons-material/CheckCircle'
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline'
import ErrorFilled from '@mui/icons-material/Error'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import HelpFilled from '@mui/icons-material/Help'
import HelpOutlined from '@mui/icons-material/HelpOutline'
import InfoFilled from '@mui/icons-material/Info'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { SvgIconProps } from '@mui/material'
import React from 'react'

import { SvgWarnIcon, SvgWarnIconOutline } from '../WarnIcon'
import { VariantIconColorProvider } from './VariantIcons.styles'

export type Variant = 'info' | 'help' | 'success' | 'warning' | 'error'

export interface VariantIconProps
  extends Omit<SvgIconProps, 'color' | 'htmlColor'> {
  iconColor?: string
}

const InfoIconOutline: React.FC<VariantIconProps> = ({
  iconColor = colors.blue_20,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <InfoOutlined {...rest} />
    </VariantIconColorProvider>
  )
}

const InfoIconFilled: React.FC<VariantIconProps> = ({
  iconColor = colors.blue_20,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <InfoFilled {...rest} />
    </VariantIconColorProvider>
  )
}

const HelpIconOutline: React.FC<VariantIconProps> = ({
  iconColor = colors.blue_20,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <HelpOutlined {...rest} />
    </VariantIconColorProvider>
  )
}

const HelpIconFilled: React.FC<VariantIconProps> = ({
  iconColor = colors.blue_20,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <HelpFilled {...rest} />
    </VariantIconColorProvider>
  )
}

const SuccessIconOutline: React.FC<VariantIconProps> = ({
  iconColor = colors.success_60,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <CheckCircleOutline {...rest} />
    </VariantIconColorProvider>
  )
}

const SuccessIconFilled: React.FC<VariantIconProps> = ({
  iconColor = colors.error_60,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <CheckCircleFilled {...rest} />
    </VariantIconColorProvider>
  )
}

const WarningIconOutline: React.FC<VariantIconProps> = ({
  iconColor = colors.warning_60,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <SvgWarnIconOutline {...rest} />
    </VariantIconColorProvider>
  )
}

const WarningIcon: React.FC<VariantIconProps> = ({
  iconColor = colors.warning_40,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <SvgWarnIcon {...rest} />
    </VariantIconColorProvider>
  )
}

const ErrorIconOutline: React.FC<VariantIconProps> = ({
  iconColor = colors.error_60,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <ErrorOutline {...rest} />
    </VariantIconColorProvider>
  )
}

const ErrorIconFilled: React.FC<VariantIconProps> = ({
  iconColor = colors.error_60,
  ...rest
}) => {
  return (
    <VariantIconColorProvider $color={iconColor}>
      <ErrorFilled {...rest} />
    </VariantIconColorProvider>
  )
}

const getIconOutline = (variant: Variant): React.FC<VariantIconProps> => {
  switch (variant) {
    case 'info': {
      return InfoIconOutline
    }
    case 'help': {
      return HelpIconOutline
    }
    case 'success': {
      return SuccessIconOutline
    }
    case 'warning': {
      return WarningIconOutline
    }
    case 'error': {
      return ErrorIconOutline
    }
  }
}

const getIconFilled = (variant: Variant): React.FC<VariantIconProps> => {
  switch (variant) {
    case 'info': {
      return InfoIconFilled
    }
    case 'help': {
      return HelpIconFilled
    }
    case 'success': {
      return SuccessIconFilled
    }
    case 'warning': {
      return WarningIcon
    }
    case 'error': {
      return ErrorIconFilled
    }
  }
}

export const VariantIcons = {
  InfoIconOutline,
  InfoIconFilled,
  HelpIconOutline,
  HelpIconFilled,
  SuccessIconOutline,
  SuccessIconFilled,
  WarningIconOutline,
  WarningIcon,
  ErrorIconOutline,
  ErrorIconFilled,
  getIconOutline,
  getIconFilled,
}
