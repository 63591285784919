import { MetaHeader } from '@dspace-internal/ui-kit'
import React from 'react'
import { MaintenanceInfoHeader } from '../MaintenanceInfoHeader'
import { Breadcrumbs } from './Breadcrumbs'
import { UserIndicator } from './UserIndicator'

export const Header: React.FC = () => {
  return (
    <>
      <MaintenanceInfoHeader />
      <MetaHeader
        breadcrumbs={<Breadcrumbs />}
        userIndicator={<UserIndicator />}
      />
    </>
  )
}
