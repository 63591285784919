import { Validate as RCFValidate } from 'react-hook-form'

type Validate<T1, T2 = unknown> = RCFValidate<T1, T2>

export const createValidator = {
  requiredString: (invalidMessage: string): Validate<string> => {
    return (value: string) => {
      return !value?.trim() ? invalidMessage : undefined
    }
  },
  requiredStringLength: (
    invalidMessage: string,
    length: number
  ): Validate<string | null> => {
    return (value: string | null) => {
      if (value == null) {
        return invalidMessage
      }
      return value.length > length ? invalidMessage : undefined
    }
  },
  isValidUrl: (invalidMessage: string) => {
    return (value: string) => {
      const inputEl = document.createElement('input')
      inputEl.type = 'url'
      inputEl.value = value
      return inputEl.validity.valid ? undefined : invalidMessage
    }
  },
  isValidJsonArrayOrEmpty: (invalidMessage: string) => {
    return (value: string) => {
      if (value.trim().length === 0) {
        return true
      }

      try {
        const jsonObj = JSON.parse(value)
        return jsonObj && Array.isArray(jsonObj) ? undefined : invalidMessage
      } catch {
        return invalidMessage
      }
    }
  },
}

export const handleSubmitOnlyOfChangedFields = <
  T extends Record<string, unknown>,
>(
  newValue: T,
  dirtyFields: Partial<Record<keyof T, unknown>>,
  onPartialSave: (changedFields: Partial<T>) => void
) => {
  onPartialSave(
    Object.fromEntries(
      Object.keys(dirtyFields).map((field: keyof T) => [field, newValue[field]])
    ) as Partial<T>
  )
}
