// TODO: We should move the createUrlFor objects from all apps into this file. There is no point in maintaing the URLs of all SIMPHERA pages in multplie different locations.
export const createUrlFor = {
  root: '/',
  project: {
    collection: '/projects',
    withId: (projectId: string) => ({
      detailPage: `/projects/${projectId}`,
      sut: {
        withId: (sutId: string) => ({
          detailPage: `/projects/${projectId}/suts/${sutId}`,
        }),
      },
      testEnvironment: {
        withId: (testEnvironmentId: string) => ({
          detailPage: `/projects/${projectId}/testenvironments/${testEnvironmentId}`,
        }),
      },
      kpi: {
        collection: `/projects/${projectId}/kpis`,
        withId: (kpiId: string) => ({
          detailPage: `/projects/${projectId}/kpis/${kpiId}`,
        }),
      },
    }),
  },
  swagger: '/api/swagger/index.html',
}
