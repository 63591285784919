import { colors } from '@dspace-internal/ui-kit'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  DiagramEngine,
  PortModel,
  PortModelAlignment,
  PortWidget,
} from '@projectstorm/react-diagrams'
import React from 'react'

import { OrderedPortModel } from './OrderedPortModel/OrderedPortModel'
import {
  PortBox,
  PortColumn,
  PortWidgetCircle,
} from './ProcessingGraphNodeBasics.styles'

export interface NodeBaseProps<TNodeModel> {
  engine: DiagramEngine
  node: TNodeModel
}

export type NodeFrameType = 'normal' | 'input' | 'output'

export interface NodeFrameProps {
  $width: number
  $isSelected: boolean
  $color?: string
  $type?: NodeFrameType
}

const inputBorderStyle = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 6px;
  border-left-style: solid;
`

const outputBorderStyle = css`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 6px;
  border-right-style: solid;
`

export const NodeFrame = styled.div<NodeFrameProps>`
  position: relative;
  width: ${({ $width }) => $width}px;
  height: fit-content;
  padding: 12px;

  border: ${({ $isSelected }) =>
      $isSelected ? `solid ${colors.gray_70}` : `dashed ${colors.gray_60}`}
    2px;
  border-radius: 8px;

  ${({ $type }) =>
    $type === 'input'
      ? inputBorderStyle
      : $type === 'output'
        ? outputBorderStyle
        : ''}

  background-color: ${({ $color }) => $color ?? colors.white};

  cursor: default;
`

const comparePortOrder = (portA: OrderedPortModel, portB: OrderedPortModel) =>
  portA.order - portB.order

export interface NodePortsProps {
  ports: {
    [s: string]: PortModel
  }
  engine: DiagramEngine
}

export const NodePorts: React.FC<NodePortsProps> = ({ ports, engine }) => {
  const inPorts: OrderedPortModel[] = []
  const outPorts: OrderedPortModel[] = []

  Object.keys(ports).forEach((portName) => {
    const port = ports[portName]
    if (port.getOptions().alignment === PortModelAlignment.LEFT) {
      inPorts.push(port as OrderedPortModel)
    } else {
      outPorts.push(port as OrderedPortModel)
    }
  })

  inPorts.sort(comparePortOrder)
  outPorts.sort(comparePortOrder)

  return (
    <PortBox>
      <PortColumn>
        {inPorts.map((port, index) => (
          <PortWidget
            key={port.getName() + '_' + index}
            port={port}
            engine={engine}
          >
            <PortWidgetCircle alignment="left" />
          </PortWidget>
        ))}
      </PortColumn>
      <PortColumn>
        {outPorts.map((port, index) => (
          <PortWidget
            key={port.getName() + '_' + index}
            port={port}
            engine={engine}
          >
            <PortWidgetCircle alignment="right" />
          </PortWidget>
        ))}
      </PortColumn>
    </PortBox>
  )
}
