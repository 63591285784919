import { ErrorHandler } from '@apollo/client/link/error'

export const handleError: ErrorHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => console.error({ graphQLError }))
  }

  if (networkError) {
    console.error({ networkError })
  }
}
