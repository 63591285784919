import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/import'
import { applyClientConfig } from '../clientConfig'

applyClientConfig(OpenAPI, { baseUrl: GlobalConfig.coreService.REST_API_URL })

export { ImportService } from '../../generated/import'

export type ImportApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
