import { NodeModel, NodeModelGenerics } from '@projectstorm/react-diagrams'

export class KpiNodeModel extends NodeModel<NodeModelGenerics> {
  nodeName: string

  constructor(nodeName: string) {
    super({
      type: 'kpiNode',
    })

    this.nodeName = nodeName
  }
}
