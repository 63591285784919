import { TextField } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import messages from '../../utils/messages.res'
import { ApiKeyFormFieldProps } from './types'

export const ExpirationDateInput: React.FC<ApiKeyFormFieldProps> = ({
  isReadOnly = false,
}) => {
  const { control, formState } = useFormContext<{ expirationDate: string }>()
  const error = formState.errors.expirationDate

  return (
    <Controller
      control={control}
      defaultValue={formState.defaultValues?.expirationDate}
      name="expirationDate"
      rules={{
        required: messages.INPUT_CANNOT_BE_EMPTY('expiration date'),
      }}
      render={({ field }) => (
        <TextField
          {...field}
          error={Boolean(error)}
          label="Expiration date"
          fullWidth
          helperText={error?.message}
          disabled={isReadOnly}
          required
          autoComplete="off"
          variant="filled"
          type="date"
        />
      )}
    />
  )
}
