import { GlobalConfig } from '@simphera/shared/appconfig'
import { NavigationConfig } from '@simphera/shared/state'
import {
  ExperimentIcon,
  SidebarItemInterpolate,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { wrapComponentInProviders } from './client/wrapper'
import { captions_res } from './shared/resources'
import createBreadcrumbsFor from './shared/utils/createBreadcrumbsFor'
import createUrlFor from './shared/utils/createUrlFor'
import { PATHS } from './shared/utils/paths'

const ExperimentsPage = React.lazy(() => import('./components/ExperimentsPage'))
const ExperimentDetailPage = React.lazy(
  () => import('./components/ExperimentDetailPage')
)
const ExperimentEditPage = React.lazy(
  () => import('./components/ExperimentEditPage')
)

export const navigationConfig: NavigationConfig = {
  isEnabled: GlobalConfig.appConfig.EXPERIMENT_APP_ENABLED,
  sidebarEntries: [
    <SidebarItemInterpolate
      name={captions_res.EXPERIMENTS()}
      icon={<ExperimentIcon />}
      to={PATHS.EXPERIMENT_LIST}
    />,
  ],
  projectItemEntry: {
    url: (projectId: string) =>
      createUrlFor.project.withId(projectId).experiments.collection,
    icon: <ExperimentIcon />,
    label: captions_res.EXPERIMENTS(),
  },
  routes: [
    {
      path: PATHS.EXPERIMENT_LIST,
      element: wrapComponentInProviders(ExperimentsPage),
      breadcrumbs: createBreadcrumbsFor.EXPERIMENT_LIST,
    },
    {
      path: PATHS.EXPERIMENT_CREATE,
      element: wrapComponentInProviders(ExperimentEditPage),
      breadcrumbs: createBreadcrumbsFor.EXPERIMENT_CREATE,
    },
    {
      path: PATHS.EXPERIMENT_DETAIL,
      element: wrapComponentInProviders(ExperimentDetailPage),
      breadcrumbs: createBreadcrumbsFor.EXPERIMENT_LIST,
    },
    {
      path: PATHS.EXPERIMENT_EDIT,
      element: wrapComponentInProviders(ExperimentEditPage),
      breadcrumbs: createBreadcrumbsFor.EXPERIMENT_LIST,
    },
  ],
}
