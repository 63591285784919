import { Button, colors } from '@dspace-internal/ui-kit'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import { Box, Typography } from '@mui/material'
import React, { ReactElement } from 'react'

export interface NetworkErrorPageProps {
  errorMessage: string
  primaryErrorText?: string
  secondaryErrorText?: string
  supportErrorText?: string
  refreshButtonText?: string
  customErrorAction?: ReactElement
}

/* Shows an error page based on input properties.*/
export const NetworkErrorPage: React.FC<NetworkErrorPageProps> = ({
  errorMessage,
  primaryErrorText = 'Oh no, an error occurred.',
  secondaryErrorText = 'Could not load data. Refresh the page.',
  refreshButtonText = 'Refresh',
  supportErrorText,
  customErrorAction = null,
}) => {
  return (
    <Box component="div" textAlign="center" alignItems="center">
      <Box component="div" mt={10} />
      <Box component="div" style={{ width: '100%', height: '200px' }}>
        <ErrorIcon
          sx={{
            color: colors.gray_30,
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      <Box component="div" mt={2} />
      <Typography
        variant="h1"
        color="error"
        data-testid="network-error-page-primary-text"
      >
        {primaryErrorText}
      </Typography>
      <Typography
        variant="h6"
        color="error"
        data-testid="network-error-page-error-message"
      >
        {errorMessage}
      </Typography>
      <Box component="div" mt={3} />
      <Typography
        variant="h6"
        color="textSecondary"
        data-testid="network-error-page-secondary-text"
      >
        {secondaryErrorText}
      </Typography>
      {supportErrorText && (
        <>
          <Box component="div" mt={1} />
          <Typography
            variant="h6"
            color="textSecondary"
            data-testid="network-error-page-support-text"
          >
            {supportErrorText}
          </Typography>
        </>
      )}
      <Box component="div" mt={5} />
      {refreshButtonText ? (
        <Button
          data-testid="network-error-page-refresh-button"
          variant="contained"
          color="primary"
          size="large"
          onClick={() => window.location.reload()}
          label={refreshButtonText}
        />
      ) : (
        customErrorAction
      )}
    </Box>
  )
}
