import { LogViewer } from '@dspace-internal/log-viewer'
import { Dialog } from '@dspace-internal/ui-kit'
import { Box, Divider, Typography } from '@mui/material'
import { Operation } from '@simphera/shared/rest-clients'
import { useInterval } from '@simphera/shared/ui-simphera'
import { formatDistance } from 'date-fns'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { COLOR_BY_STATUS_MAP } from './NotificationListItem'
import { getNotification } from './api'
import { NOTIFICATION_ID_SEARCH_PARAM } from './constants'

const NOTIFICATION_DETAILS_POLL_MS = 2000

interface NotificationDialogProps {
  notification: Operation
}

function getDurationTime(notification: Operation): string {
  const durationEndOrNow = notification.finishedOn
    ? new Date(notification.finishedOn)
    : new Date()

  return formatDistance(new Date(notification.createdOn), durationEndOrNow, {
    includeSeconds: true,
  })
}

function shortenTitle(title: string): string {
  // for now we are shortening the title because they are too long
  // this is just a workaround which will be gone after further optimization of log entries and Notification Center
  return title.split(' from remote')[0]
}

export const NotificationDialog: React.FC<NotificationDialogProps> = ({
  notification: initialNotificationState,
}) => {
  const [searchParameters, setSearchParameters] = useSearchParams()
  const [notification, setNotification] = useState<Operation>(
    initialNotificationState
  )

  useInterval(() => {
    if (notification.finishedOn) {
      return
    }

    getNotification(notification.id).then(setNotification)
  }, NOTIFICATION_DETAILS_POLL_MS)

  const closeDialog = () => {
    // note: removing query parameter to close dialog
    searchParameters.delete(NOTIFICATION_ID_SEARCH_PARAM)
    setSearchParameters(searchParameters)
  }

  return (
    <Dialog
      open
      title={shortenTitle(notification.title)}
      onClose={closeDialog}
      maxWidth="xl"
      fullWidth
    >
      <Box
        component="div"
        display="flex"
        gap={6}
        alignItems="baseline"
        justifyContent="space-between"
        width="100%"
        marginBottom={4}
      >
        <Box component="div" width="100%">
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Created on:
            </Typography>
            <Typography variant="body2">
              {new Date(notification.createdOn).toLocaleString()}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Status:
            </Typography>
            <Typography
              variant="body2"
              fontWeight="500"
              color={COLOR_BY_STATUS_MAP[notification.status]}
            >
              {notification.status}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Completed:
            </Typography>
            <Typography variant="body2">
              {`${notification.percentComplete}%`}
              {` (Duration: ${getDurationTime(notification)})`}
            </Typography>
          </Box>

          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Description:
            </Typography>
            <Typography variant="body2" sx={{ overflowWrap: 'anywhere' }}>
              {notification.title}
            </Typography>
          </Box>
        </Box>
        <Box component="div" width="100%">
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Finished on:
            </Typography>
            <Typography variant="body2">
              {notification.finishedOn
                ? new Date(notification.finishedOn).toLocaleString()
                : '-'}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Created by:
            </Typography>
            <Typography variant="body2">
              {notification.createdByName}
            </Typography>
          </Box>
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Type:
            </Typography>
            <Typography variant="body2">{notification.type}</Typography>
          </Box>
          <Box component="div" display="flex">
            <Typography
              variant="body2"
              fontWeight="500"
              width={100}
              flexShrink={0}
            >
              Operation id:
            </Typography>
            <Typography variant="body2">{notification.id}</Typography>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: '15px' }} />
      <LogViewer
        height="500px"
        defaultSortModel={{
          field: 'timestamp',
          sort: 'desc',
        }}
        logs={notification.logEntries.map((log) => ({
          timestamp: log.timeStamp!,
          creatorId: 'Core Service',
          message: log.title!,
          severity: log.severity!.toUpperCase() as 'INFO' | 'WARNING' | 'ERROR',
          additionalData: log.title!,
        }))}
      />
    </Dialog>
  )
}
