import { GlobalConfig } from '@simphera/shared/appconfig'
import { ApiError, OpenAPI, RestApiError } from '../../generated/kpi'
import { applyClientConfig } from '../clientConfig'

applyClientConfig(OpenAPI, { baseUrl: GlobalConfig.kpi.REST_API_URL })

export {
  KpisService,
  type KpiDto,
  type KpiDtoCollectionResponse,
} from '../../generated/kpi'

export type KpisApiError = Omit<ApiError, 'body'> & {
  body: RestApiError
}
