import { SidebarItem } from '@dspace-internal/ui-kit'
import { mdiSourceBranch } from '@mdi/js'
import Icon from '@mdi/react'
import { NavigationConfig, useProjectInfo } from '@simphera/shared/state'
import {
  ProjectIcon,
  ProjectListIcon,
  SidebarItemInterpolate,
  interpolatePath,
} from '@simphera/shared/ui-simphera'
import React from 'react'
import { GitWorkspaceCreatePage } from './components/GitWorkspaces/GitWorkspaceCreatePage/GitWorkspaceCreatePage'
import GitWorkspaceDetails from './components/GitWorkspaces/GitWorkspaceDetails/GitWorkspaceDetails'
import { GitWorkspaceEditPage } from './components/GitWorkspaces/GitWorkspaceEditPage/GitWorkspaceEditPage'
import { loadGitWorkspace } from './components/GitWorkspaces/loaders'
import createBreadcrumbsFor from './createBreadcrumbsFor'
import { HasCreatePermissionsGuard } from './guards/HasCreatePermissionsGuard'
import { PATHS } from './paths'

const ProjectCreatePage = React.lazy(
  () => import('./components/ProjectCreatePage')
)
const ProjectDetailPage = React.lazy(
  () => import('./components/ProjectDetailPage')
)
const ProjectEditPage = React.lazy(() => import('./components/ProjectEditPage'))
const ProjectsPage = React.lazy(() => import('./components/ProjectsPage'))

const SidebarEntries = () => {
  const projectInfo = useProjectInfo()

  return projectInfo ? (
    <>
      {projectInfo.gitWorkspace && (
        <SidebarItem
          icon={<Icon path={mdiSourceBranch} size={0.85} />}
          name="Git Workspace"
          to={interpolatePath(PATHS.GIT_WORKSPACE_DETAIL, {
            gitWorkspaceId: projectInfo.gitWorkspace?.id,
          })}
          exact={true}
        />
      )}
      <SidebarItemInterpolate
        icon={<ProjectIcon />}
        name={projectInfo.name}
        to={PATHS.PROJECT_DETAILS}
        exact={true}
      />
    </>
  ) : (
    <SidebarItemInterpolate
      icon={<ProjectListIcon />}
      name="Projects"
      to={PATHS.PROJECT_LIST}
    />
  )
}

export const navigationConfig: NavigationConfig = {
  isEnabled: true,
  sidebarEntries: [<SidebarEntries />],
  routes: [
    {
      path: PATHS.GIT_WORKSPACE_NEW,
      element: (
        <HasCreatePermissionsGuard
          redirectTo="/projects"
          elementRenderFn={() => <GitWorkspaceCreatePage />}
        />
      ),
      breadcrumbs: createBreadcrumbsFor.GIT_WORKSPACE_NEW,
    },
    {
      path: PATHS.GIT_WORKSPACE_DETAIL,
      element: <GitWorkspaceDetails />,
    },
    {
      path: PATHS.GIT_WORKSPACE_EDIT,
      element: <GitWorkspaceEditPage />,
      loader: loadGitWorkspace,
    },
    {
      path: PATHS.PROJECT_LIST,
      element: <ProjectsPage />,
    },
    {
      path: PATHS.PROJECT_NEW,
      element: <ProjectCreatePage />,
      breadcrumbs: createBreadcrumbsFor.PROJECT_NEW,
    },
    {
      path: PATHS.PROJECT_EDIT,
      element: <ProjectEditPage />,
      breadcrumbs: createBreadcrumbsFor.PROJECT_EDIT,
    },
    {
      path: PATHS.PROJECT_DETAILS,
      element: <ProjectDetailPage />,
    },
  ],
}
