import * as React from 'react'

import { KpiGraphNode, KpiGraphNodeComponentProps } from '../../kpiGraphTypes'
import KpiNodeFrame, { KpiNodeTitle } from './KpiNodeFrame'

export const DefaultOutputKpiNode: React.FC<
  KpiGraphNodeComponentProps<KpiGraphNode>
> = ({ engine, node, model }) => {
  return (
    <KpiNodeFrame model={model} engine={engine} frameType="output">
      <KpiNodeTitle nodeName={model.nodeName} node={node} />
    </KpiNodeFrame>
  )
}
