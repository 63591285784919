import { PageHeader } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { setPageTitle } from './setPageTitle'

type SimpheraPageHeaderProps = React.ComponentProps<typeof PageHeader> & {
  titleEndAdornment?: React.ReactNode
}

const Flexbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

/**
 * wrapper around UI-Kit PageHeader
 * sets the document title based on the passed in title and subtitle props
 *
 * THIS COMPONENT WILL SOON BE MERGED WITH CustomPageHeader. WE NEED TO REFACTOR THE CODE BASE FIRST TO MAKE SURE CustomPageHeader IS USED IN ALL APPS
 * */
export const SimpheraPageHeader: React.FC<SimpheraPageHeaderProps> = ({
  title,
  titleEndAdornment,
  subtitle,
  primaryAction,
  secondaryAction,
}) => {
  useEffect(() => {
    setPageTitle(title, subtitle)
  }, [title, subtitle])

  // TODO add titleEndAdornment to ui-kit PageHeader and remove type-casting from here
  const titleElement = (
    <Flexbox>
      {title}
      {titleEndAdornment}
    </Flexbox>
  ) as unknown as string

  return (
    <PageHeader
      title={titleElement}
      subtitle={subtitle}
      primaryAction={primaryAction}
      secondaryAction={secondaryAction}
    />
  )
}

export default SimpheraPageHeader
