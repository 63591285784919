import React from 'react'

import { createProviderHook } from '@simphera/shared/ui-simphera'
import { getImplicitlyDisabledCachingNodes } from './graphUtils'
import {
  ProcessingObjectLink,
  SelectedProcessingObjectInput,
} from './processingGraphTypes'

interface ProcessingObjectSelectionDataInternal<TNodeData> {
  selectedProcessingObjects: SelectedProcessingObjectInput[]
  implicitlySelectedNodes: string[]
  implicitlyDisabledCachingNodes: string[]
  getNodeData: (nodeId: string) => TNodeData
}

const ProcessingObjectSelectionContext = React.createContext<
  ProcessingObjectSelectionDataInternal<any> | undefined
>(undefined)

const useProcessingObjectSelectionInternal = createProviderHook(
  ProcessingObjectSelectionContext,
  'ProcessingObjectSelectionProvider',
  'useProcessingObjectSelection'
)

export interface ProcessingObjectSelectionData<TNodeData> {
  isSelected: boolean
  isImplicitlySelected: boolean
  hasImplicitlyDisabledCaching: boolean
  data: TNodeData
}

export const useProcessingObjectSelection = <TNodeData,>(
  nodeId: string
): ProcessingObjectSelectionData<TNodeData> => {
  const {
    selectedProcessingObjects,
    implicitlySelectedNodes,
    implicitlyDisabledCachingNodes,
    getNodeData,
  } =
    useProcessingObjectSelectionInternal() as ProcessingObjectSelectionDataInternal<TNodeData>

  return {
    isSelected: selectedProcessingObjects.some(
      (node) => node.nodeId === nodeId
    ),
    isImplicitlySelected: implicitlySelectedNodes.includes(nodeId),
    hasImplicitlyDisabledCaching:
      implicitlyDisabledCachingNodes.includes(nodeId),
    data: getNodeData(nodeId),
  }
}

export interface ProcessingObjectSelectionProviderProps<TNodeData> {
  links: ProcessingObjectLink[]
  selectedProcessingObjects: SelectedProcessingObjectInput[]
  getNodeData: (nodeId: string) => TNodeData
}

const ProcessingObjectSelectionProvider = <TNodeData,>({
  links,
  selectedProcessingObjects,
  getNodeData,
  children,
}: React.PropsWithChildren<
  ProcessingObjectSelectionProviderProps<TNodeData>
>) => {
  const implicitlySelectedNodes = React.useMemo(
    () =>
      links
        .filter((link) =>
          selectedProcessingObjects.find((node) => node.nodeId === link.target)
        )
        .map((link) => link.source),
    [links, selectedProcessingObjects]
  )

  const implicitlyDisabledCachingNodes = React.useMemo(
    () => getImplicitlyDisabledCachingNodes(selectedProcessingObjects, links),
    [links, selectedProcessingObjects]
  )

  return (
    <ProcessingObjectSelectionContext.Provider
      value={{
        selectedProcessingObjects,
        implicitlySelectedNodes,
        implicitlyDisabledCachingNodes,
        getNodeData,
      }}
    >
      {children}
    </ProcessingObjectSelectionContext.Provider>
  )
}

export default ProcessingObjectSelectionProvider
