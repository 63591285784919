import { Paper } from '@dspace-internal/ui-kit'
import styled from '@emotion/styled'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { CheckoutFoldersInput } from './CheckoutFoldersInput'
import { DescriptionInput } from './DescriptionInput'
import { LfsPasswordInput } from './LfsPasswordInput'
import { LfsUsernameInput } from './LfsUsernameInput'
import { NameInput } from './NameInput'
import { PathToProjectYamlInput } from './ProjectYamlFilePath'
import { RepositoryPasswordInput } from './RepositoryPasswordInput'
import { RepositoryUrlInput } from './RepositoryUrlInput'
import { RepositoryUsernameInput } from './RepositoryUsernameInput'
import { IEditMode, IReadOnly } from './types'

const FullHeightPaper = styled(Paper)`
  height: 100%;
`

export const GitWorkspaceFormFields: React.FC<IReadOnly & IEditMode> = ({
  isReadOnly = false,
  isEditMode = false,
}) => (
  <Box component="div" display="flex" gap={4} flexDirection="column">
    <Paper>
      <Typography variant="h4">General</Typography>
      <Stack direction="column" gap={3} marginTop={4}>
        <NameInput isReadOnly={isReadOnly} />
        <DescriptionInput isReadOnly={isReadOnly} />
      </Stack>
    </Paper>
    <Paper>
      <Stack direction="column" gap={3}>
        <Typography variant="h4">Git Repository</Typography>
        <RepositoryUrlInput isReadOnly={isReadOnly} />
        <PathToProjectYamlInput isReadOnly={isReadOnly} />
        <CheckoutFoldersInput isReadOnly={isReadOnly} />
      </Stack>
    </Paper>
    <Grid container mb={3} spacing={4}>
      <Grid item xs={12} md={6}>
        <FullHeightPaper>
          <Typography variant="h4">Git Credentials</Typography>
          <Stack direction="column" gap={3} marginTop={4}>
            <RepositoryUsernameInput isReadOnly={isReadOnly} />
            <RepositoryPasswordInput
              isReadOnly={isReadOnly}
              isEditMode={isEditMode}
            />
          </Stack>
        </FullHeightPaper>
      </Grid>
      <Grid item xs={12} md={6}>
        <FullHeightPaper>
          <Typography variant="h4">Git LFS Credentials</Typography>
          <Stack direction="column" gap={3} marginTop={4}>
            <LfsUsernameInput isReadOnly={isReadOnly} />
            <LfsPasswordInput isReadOnly={isReadOnly} isEditMode={isEditMode} />
          </Stack>
        </FullHeightPaper>
      </Grid>
    </Grid>
  </Box>
)
