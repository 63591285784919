import { Params, generatePath } from 'react-router-dom'

/**
 * @example: interpolatePath('/projects/:projectId', { projectId: '1337' }) -> '/projects/1337'
 */
export function interpolatePath(
  path: string,
  params: Readonly<Params<string>>
): string {
  try {
    return generatePath(path, params)
  } catch {
    return path
  }
}
