import styled from '@emotion/styled'
import { CircularProgress } from '@mui/material'
import React from 'react'

const SPINNER_LOADING_DELAY_MS = 500

export const SpinnerRoot = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%);
`

export const DelayedSpinner: React.FC = () => {
  const [showSpinner, setShowSpinner] = React.useState(false)

  React.useEffect(() => {
    setTimeout(() => {
      setShowSpinner(true)
    }, SPINNER_LOADING_DELAY_MS)
  }, [])

  return showSpinner ? (
    <SpinnerRoot>
      <CircularProgress disableShrink />
    </SpinnerRoot>
  ) : null
}
