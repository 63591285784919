import { GlobalConfig } from '@simphera/shared/appconfig'

export const API_GATEWAY_HOST = () => GlobalConfig.experiment.API_GATEWAY_HOST
export const MEASUREMENT_ADDRESS = () =>
  GlobalConfig.experiment.MEASUREMENT_ADDRESS
export const SHOW_AURELION_FEATURES = (): boolean =>
  GlobalConfig.featureFlags.showAurelionFeatures
export const SHOW_ADVANCED_AURELION_FEATURES = (): boolean =>
  GlobalConfig.featureFlags.showAdvancedAurelionFeatures
export const SHOW_CALIBRATION_WIDGETS = (): boolean =>
  GlobalConfig.featureFlags.showExperimentAppCalibrationWidgets
export const SHOW_NEW_PLOTTER = (): boolean =>
  GlobalConfig.featureFlags.showNewPlotterWidget

export const ENABLE_WRITE_SCENARIO_PARAMETERS_DURING_RUNNING_SIMULATION =
  (): boolean =>
    GlobalConfig.featureFlags
      .enableWriteScenarioParametersDuringRunningSimulation
