import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

import { SpinnerRoot } from './Spinner.styles'

export const Spinner: React.FC<{ marginTop?: string }> = ({
  marginTop = '40vh',
  ...others
}) => {
  return (
    <SpinnerRoot marginTop={marginTop} {...others}>
      <CircularProgress disableShrink />
    </SpinnerRoot>
  )
}
